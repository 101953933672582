import { Flex, Heading } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import LoadingWheel from "./LoadingWheel";

export interface FormikWrapperProps {
  isLoading: boolean;
  headerText: string;
  updateMsg: string;
  initialData: any;
  onSubmit: ((
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => void | Promise<any>) &
    Function;
  children?: React.ReactNode | ((props: FormikProps<any>) => React.ReactNode);
}

const FormikWrapper: React.FC<FormikWrapperProps> = (props) => {
  const [showUpdateMsg, setShowUpdateMsg] = useState(true);

  useEffect(() => {
    setShowUpdateMsg(!!props.updateMsg);
  }, [props.updateMsg]);

  if (props.isLoading) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Flex mt="2em" direction="column" flexGrow={1}>
        <Heading>{props.headerText}</Heading>

        {!!props.updateMsg && showUpdateMsg && (
          <Alert status="success">
            <AlertIcon />
            <AlertDescription>{props.updateMsg}</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setShowUpdateMsg(false)}
            />
          </Alert>
        )}

        <Formik initialValues={props.initialData} onSubmit={props.onSubmit}>
          {props.children}
        </Formik>
      </Flex>
    </>
  );
};

export default FormikWrapper;
