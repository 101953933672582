import { Heading } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  Flex,
  Text,
} from "@chakra-ui/react";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Chamado,
  StatusChamado,
  useChamadosQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatStatusChamado } from "../../utils/formatStatusChamado";
import { formatTipoChamado } from "../../utils/formatTipoChamado";
import { MapaChamados } from "../../components/MapaChamados";

export interface ChamadosProps {}

const Chamados: React.FC<{}> = (props) => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [statusExibidos, setStatusExibidos] = useState([
    StatusChamado.Iniciado,
    StatusChamado.Aguardando,
    StatusChamado.Emandamento,
    StatusChamado.Material,
    StatusChamado.Aprovacao,
  ]);

  const [{ data, fetching, error, stale }] = useChamadosQuery({
    variables: {
      filter,
      page,
      status: statusExibidos,
    },
    requestPolicy: "cache-and-network",
  });

  if (fetching) {
    return <LoadingWheel />;
  }

  const toggleStatus = (status: StatusChamado) => {
    const indStatus = statusExibidos.indexOf(status);
    if (indStatus === -1) {
      setStatusExibidos([...statusExibidos, status]);
    } else {
      const statusNovo = [...statusExibidos];
      statusNovo.splice(indStatus);
      setStatusExibidos(statusNovo);
    }
  };

  const headers: TableHeaders<Chamado>[] = [
    {
      label: "#",
      render: (item) => `${item.id}`,
      wrapped: true,
    },
    {
      label: "Inicio",
      render: (item) => formatDate(item.inicio),
      wrapped: true,
    },
    {
      label: "Cliente",
      render: (item) =>
        item.cliente &&
        item.contrato && (
          <>
            <Text fontSize="1rem">{item.cliente.nome}</Text>
            <Text fontSize="0.7rem">
              Contrato: {item.contrato.id} - {item.contrato.usuario}
            </Text>
            <Text fontSize="0.7rem">
              Endereço: {item.contrato.logradouro}, {item.contrato.numero} -{" "}
              {item.contrato.bairro}
            </Text>
          </>
        ),
      wrapped: true,
    },
    {
      label: "Cidade",
      render: (item) => item.contrato && item.contrato.cidade,
      wrapped: true,
    },
    {
      label: "Descrição",
      render: (item) =>
        item.iteracoes &&
        item.iteracoes.length > 0 &&
        item.iteracoes[0].descricao,
    },
    {
      label: "Tipo",
      render: (item) => formatTipoChamado(item.tipo),
      wrapped: true,
    },
    {
      label: "Prioridade",
      render: (item) => item.prioridade,
      wrapped: true,
    },
    {
      label: "Status",
      render: (item) => formatStatusChamado(item.status),
      wrapped: true,
    },
    {
      label: " ",
      render: (item) => (
        <Link to={`/chamados/${item.id}`}>
          <Button colorScheme="green">
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </Link>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading>Chamados</Heading>

      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message} </AlertDescription>
        </Alert>
      )}
      {stale && (
        <Alert status="warning">
          <AlertIcon />
          <AlertDescription>
            O sistema esta buscando novas informações!
          </AlertDescription>
        </Alert>
      )}

      <Flex direction={["column", "row"]} mb="1rem">
        <Link to="/NovoChamado">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo chamado
          </Button>
        </Link>
        <Text ml="auto" mr={["auto", "2rem"]} mt={["1rem", "0"]}>
          Status Exibidos:
        </Text>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Iniciado) !== -1}
          onChange={() => toggleStatus(StatusChamado.Iniciado)}
        >
          Iniciado
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Aguardando) !== -1}
          onChange={() => toggleStatus(StatusChamado.Aguardando)}
        >
          Em Andamento
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Emandamento) !== -1}
          onChange={() => toggleStatus(StatusChamado.Emandamento)}
        >
          Aguardando cliente
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Material) !== -1}
          onChange={() => toggleStatus(StatusChamado.Material)}
        >
          Aguardando material
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Aprovacao) !== -1}
          onChange={() => toggleStatus(StatusChamado.Aprovacao)}
        >
          Aguardando aprovação
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Fechado) !== -1}
          onChange={() => toggleStatus(StatusChamado.Fechado)}
        >
          Fechado
        </Checkbox>
      </Flex>

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable headers={headers} data={data?.chamados.data} />

      <PaginationButtons
        totalPages={data?.chamados.totalPages}
        page={page}
        onPageChange={setPage}
      />

      {/* @ts-ignore */}
      <MapaChamados chamados={data?.chamados.data || []} />
    </>
  );
};

export default Chamados;
