import { GridItem, Heading } from "@chakra-ui/layout";
import { Button, Grid, useToast } from "@chakra-ui/react";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import {
  useGeraEEnviaNfMutation,
  useGeraEEnviaNfsMutation,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const EnviarNFMensal: React.FC<{}> = () => {
  useIsAuth();
  const toast = useToast();
  const [, enviarNFs] = useGeraEEnviaNfMutation();
  const [, enviarNFPrefeitura] = useGeraEEnviaNfsMutation();

  const valoresIniciais = {
    mes: startOfMonth(new Date()),
  };
  return (
    <>
      {" "}
      <Heading>Parametros para enviar Notas do mes</Heading>
      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          console.log("alterar", values);
          if (!values.mes || !(values.mes instanceof Date)) {
            setErrors({
              mes: "Mes deve ser preenchido",
            });
            return;
          }
          const { error } = await enviarNFs({
            mes: values.mes,
          });

          if (error) {
            toast({
              title: "Erro ao enviar Notas por email",
              description: `Erro: ${error.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          toast({
            title: "Notas enviadas com sucesso",
            description: "Notas enviadas com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 2]}>
                <DateSelector name="mes" label="Mês" monthOnly={true} />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
      <Heading>Parametros para enviar Notas de serviço do mes</Heading>
      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          console.log("alterar", values);
          if (!values.mes || !(values.mes instanceof Date)) {
            setErrors({
              mes: "Mes deve ser preenchido",
            });
            return;
          }
          const { error } = await enviarNFPrefeitura({
            mes: values.mes,
          });

          if (error) {
            toast({
              title: "Erro ao enviar Notas por email",
              description: `Erro: ${error.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          toast({
            title: "Notas enviadas com sucesso",
            description: "Notas enviadas com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 2]}>
                <DateSelector name="mes" label="Mês" monthOnly={true} />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EnviarNFMensal;
