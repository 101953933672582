import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading, Text } from "@chakra-ui/layout";
import { Checkbox, Grid, GridItem, useToast } from "@chakra-ui/react";
import {
  faBan,
  faCheckDouble,
  faExchangeAlt,
  faPen,
  faPlus,
  faTrash,
  faBarcode,
  faUpload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import DateSelector from "../../components/DateComponents/DateSelector";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Cobranca,
  DateRangeInput,
  useCancelarCobrancaMutation,
  useCobrancasQuery,
  useDeleteCobrancaMutation,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { statusCobranca } from "../../utils/statusCobranca";
import { useIsAuth } from "../../utils/useIsAuth";
import { BConsultaCobrancaWS } from "./ConsultaCobrancaWS";
import { BEmitirCobrancaWS } from "./EmitirCobrancaWS";

const Cobrancas: React.FC<{}> = () => {
  useIsAuth();
  const [cobrancasMarcadas, setCobrancasMarcadas] = React.useState(
    [] as number[]
  );
  const [isConferir, setIsConferir] = React.useState(false);
  const toast = useToast();

  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [vencimento, setVencimento] = React.useState({
    inicio: startOfMonth(new Date()),
    fim: new Date(),
  } as DateRangeInput);
  const [pagamento, setPagamento] = React.useState({} as DateRangeInput);
  const [pagoFilter, setPagoFilter] = React.useState(false);
  const [carteira, setCarteiraFilter] = React.useState(undefined);
  const [{ data, fetching, error }, query] = useCobrancasQuery({
    variables: {
      page: page,
      pageSize: isConferir ? -1 : 50,
      filter,
      carteira,
      isPago: pagoFilter,
      vencimento: vencimento.inicio && vencimento.fim && vencimento,
      pagamento: pagamento.inicio && pagamento.fim && pagamento,
    },
    requestPolicy: "cache-and-network",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteCobrancaMutation();
  const [{ fetching: cancelarLoading }, cancelarCobranca] =
    useCancelarCobrancaMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const onDeleteConfirm = async (cobranca: Cobranca) => {
    const { error } = await deleteData({ id: cobranca.id });
    if (error) {
      setErrorMessage(error.message.replace("[GraphQL]", ""));
      return;
    }
    setDeleteSuccessMsg(`Cobrança ${cobranca.id} foi removido com sucesso!`);
    query();
  };

  const onCancelarConfirm = async (cobranca: Cobranca) => {
    const { error } = await cancelarCobranca({ id: cobranca.id });
    if (error) {
      setErrorMessage(error.message.replace("[GraphQL]", ""));
      return;
    }
    setDeleteSuccessMsg(`Cobrança ${cobranca.id} foi cancelado com sucesso!`);
    query();
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const totalDin = data?.cobrancas?.data?.reduce(
    (p, c) => p + (c.pago_din || 0) * 1,
    0
  );
  const totalPix = data?.cobrancas?.data?.reduce(
    (p, c) => p + (c.pago_pix || 0) * 1,
    0
  );
  const totalCC = data?.cobrancas?.data?.reduce(
    (p, c) => p + (c.pago_cc || 0) * 1,
    0
  );
  const totalCD = data?.cobrancas?.data?.reduce(
    (p, c) => p + (c.pago_cd || 0) * 1,
    0
  );

  const headers: TableHeaders<Cobranca>[] = [
    {
      label: "#",
      render: (cobranca) =>
        isConferir ? (
          <Checkbox
            isChecked={
              cobrancasMarcadas.indexOf(
                cobranca.cobrancaImportada || cobranca.id
              ) !== -1
            }
          >
            {cobranca.cobrancaImportada || cobranca.id}
          </Checkbox>
        ) : (
          `${cobranca.cobrancaImportada || cobranca.id}`
        ),
      wrapped: true,
    },
    {
      label: "Cliente",
      render: (cobranca) => (cobranca.cliente ? cobranca.cliente.nome : ""),
      wrapped: true,
    },
    {
      label: "Contrato",
      render: (cobranca) =>
        cobranca.contrato
          ? `${cobranca.contrato.id} - ${cobranca.contrato.usuario}`
          : "",
      wrapped: true,
    },
    {
      label: "Banco/Carteira",
      render: (cobranca) =>
        `${cobranca.banco ? cobranca.banco.nome : ""}/${
          cobranca.codClienteBol || cobranca.banco?.codCliente
        }`,
      wrapped: true,
    },
    {
      label: "Valor",
      render: (cobranca) => formatNumber(cobranca.valor, 2, true),
      wrapped: true,
    },
    {
      label: "Vencimento",
      render: (cobranca) => formatDate(cobranca.vencimento),
      wrapped: true,
    },
    {
      label: "Valor pago",
      render: (cobranca) => formatNumber(cobranca.valor_pago),
      wrapped: true,
    },
    {
      label: "Data Pagamento",
      render: (cobranca) => formatDate(cobranca.data_pago),
      wrapped: true,
    },
    {
      label: "Status",
      render: (cobranca) => statusCobranca(cobranca),
      wrapped: true,
    },
    {
      label: " ",
      render: (cobranca) => (
        <>
          <Link to={`/cobrancas/${cobranca.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <Link to={`/impressao/boletoIndividual/${cobranca.id}`}>
            <Button colorScheme="cyan" type="button">
              <FontAwesomeIcon icon={faBarcode} />
            </Button>
          </Link>
          {!cobranca.isRegistrado && cobranca.codClienteBol === "06775" && (
            <BEmitirCobrancaWS cobranca={cobranca} colorScheme="blackAlpha">
              <FontAwesomeIcon icon={faUpload} />
            </BEmitirCobrancaWS>
          )}
          {cobranca.codClienteBol === "06775" && (
            <BConsultaCobrancaWS cobranca={cobranca} colorScheme="blackAlpha">
              <FontAwesomeIcon icon={faDownload} />
            </BConsultaCobrancaWS>
          )}
          <ConfirmButton
            isLoading={cancelarLoading}
            onClick={() => onCancelarConfirm(cobranca as Cobranca)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faBan} />
          </ConfirmButton>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(cobranca as Cobranca)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">
        {isConferir ? `Conferir baixas de ` : ""} Cobranças
      </Heading>

      {!isConferir && (
        <Flex direction="row" mb="1rem">
          <Link to="/cobrancas/novo">
            <Button colorScheme="blue" type="button">
              <FontAwesomeIcon icon={faPlus} /> Nova cobrança
            </Button>
          </Link>
          <Link to="/mudarBancoCobrancas">
            <Button colorScheme="cyan" type="button" ml="1rem">
              <FontAwesomeIcon icon={faExchangeAlt} /> Mudar banco de cobranças
            </Button>
          </Link>
          <Button
            colorScheme="green"
            onClick={() => {
              setIsConferir(true);
              setCobrancasMarcadas([]);
            }}
            type="button"
            ml="1rem"
          >
            <FontAwesomeIcon icon={faCheckDouble} /> Conferir cobranças
          </Button>
        </Flex>
      )}
      {isConferir && (
        <Button
          colorScheme="green"
          my="0.5rem"
          onClick={() => {
            const dif = _.differenceWith(
              data.cobrancas.data,
              cobrancasMarcadas,
              (a, b) => a.id === b || a.cobrancaImportada === b
            );
            if (dif.length > 0) {
              toast({
                title: `Tem cobranças que não foram marcadas!`,
                description: `As cobranças ${dif
                  .map((cob) => `${cob.cobrancaImportada || cob.id}`)
                  .join(",")} não foram encontradas`,
                status: "error",
                duration: 9000,
              });
              return;
            }
            setIsConferir(false);
            setCobrancasMarcadas([]);
          }}
          type="button"
          ml="1rem"
        >
          <FontAwesomeIcon icon={faCheckDouble} /> Finalizar conferencia
        </Button>
      )}

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      {!!errorMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{errorMessage}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMessage("")}
          />
        </Alert>
      )}

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}
      {isConferir && (
        <Filter
          value=""
          zeroOnEnter={true}
          onChange={(text) => {
            const cob = data.cobrancas.data.find(
              (cob) =>
                cob.id === parseInt(text) ||
                cob.cobrancaImportada === parseInt(text)
            );
            if (!cob) {
              toast({
                title: `Cobrança ${text} não foi encontrada!`,
                description: `Cobrança com o numero ${text} não foi encontrada!`,
                status: "error",
                duration: 3000,
              });
            } else {
              setCobrancasMarcadas([
                ...cobrancasMarcadas,
                cob.cobrancaImportada || cob.id,
              ]);
            }
          }}
        />
      )}

      {!isConferir && (
        <Flex direction={["column", "row"]}>
          <Button
            disabled={pagoFilter === false}
            onClick={() => setPagoFilter(false)}
          >
            Somente Não Pagos
          </Button>
          <Button
            disabled={pagoFilter === true}
            onClick={() => setPagoFilter(true)}
          >
            Somente Pagos
          </Button>
          <Button
            disabled={pagoFilter === undefined}
            onClick={() => setPagoFilter(undefined)}
          >
            Todos
          </Button>
        </Flex>
      )}

      {!isConferir && (
        <Formik
          initialValues={vencimento}
          onSubmit={(values) => {
            setVencimento(values);
          }}
        >
          {() => (
            <Form>
              <Grid
                gap={[2, 6]}
                templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
              >
                <GridItem colSpan={2}>
                  <DateSelector name="inicio" label="Inicio Vencimento" />
                </GridItem>
                <GridItem colSpan={2}>
                  <DateSelector name="fim" label="Fim Vencimento" />
                </GridItem>
                <GridItem>
                  <Flex
                    minH="100%"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Button type="submit" colorScheme="blue" mx="0.5rem">
                      Confirmar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => setVencimento({} as DateRangeInput)}
                      colorScheme="orange"
                      mx="0.5rem"
                    >
                      Zerar
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      {!isConferir && (
        <Formik
          initialValues={pagamento}
          onSubmit={(values) => {
            setPagamento(values);
          }}
        >
          {() => (
            <Form>
              <Grid
                gap={[2, 6]}
                mb="1rem"
                templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
              >
                <GridItem colSpan={2}>
                  <DateSelector name="inicio" label="Inicio Pagamento" />
                </GridItem>
                <GridItem colSpan={2}>
                  <DateSelector name="fim" label="Fim Pagamento" />
                </GridItem>
                <GridItem>
                  <Flex
                    minH="100%"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Button type="submit" colorScheme="blue" mx="0.5rem">
                      Confirmar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => setPagamento({} as DateRangeInput)}
                      colorScheme="orange"
                      mx="0.5rem"
                    >
                      Zerar
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      {!isConferir && (
        <Flex paddingBottom={2}>
          <Filter
            value={carteira}
            placeholder="Carteira"
            onChange={setCarteiraFilter}
          />
        </Flex>
      )}

      {!isConferir && <Filter value={filter} onChange={setFilter} />}

      <ResponsiveTable data={data?.cobrancas.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.cobrancas.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />

      {isConferir && (
        <>
          <Text size="xl">
            Valor pago em Dinheiro: {formatNumber(totalDin, 2, true)}
          </Text>
          <Text size="xl">
            Valor pago em PIX: {formatNumber(totalPix, 2, true)}
          </Text>
          <Text size="xl">
            Valor pago em Cartão de Crédito: {formatNumber(totalCC, 2, true)}
          </Text>
          <Text size="xl">
            Valor pago em Cartão de Débito: {formatNumber(totalCD, 2, true)}
          </Text>
        </>
      )}
    </>
  );
};

export default Cobrancas;
