import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Badge, Heading, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import ClienteSelectorField from "../../components/ClienteSelectorField";
import ConfirmButton from "../../components/ConfirmButton";
import DateSelector from "../../components/DateComponents/DateSelector";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import ItemNotaModal from "../../components/ItemNotaModal";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import SelectField from "../../components/SelectField";
import SerieSelectorField from "../../components/SerieSelectorField";
import {
  ItemNota,
  NotaInput,
  useCreateNotaMutation,
  useNotaQuery,
  useUpdateNotaMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import { formatNumber } from "../../utils/formatNumber";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novaNota: NotaInput = {
  numero: "",
  emissao: new Date(),
  itens: [],
  modelo: "21",
  cliente: undefined,
  serie: undefined,
};

const NotaForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const [{ fetching, data }] = useNotaQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [, createMutation] = useCreateNotaMutation();
  const [, updateMutation] = useUpdateNotaMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editItemIndex, setEditItemIndex] = useState(-1);

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.nota ? "Editar" : "Novo"} Nota`}
      updateMsg={updateMsg}
      initialData={data?.nota ? data.nota : novaNota}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating nota", values);
        const valuesToSave: NotaInput = {
          cliente: values.cliente.id,
          serie: values.serie.id,
          id: values.id,
          numero: values.numero,
          modelo: values.modelo,
          emissao: values.emissao,
          itens: values.itens,
        };
        if (data?.nota) {
          /// é alteracao
          const { error, data } = await updateMutation({
            nota: stripObjectToMutation(valuesToSave),
          });

          if (error) {
            setErrors({
              cliente: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateNota.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateNota.errors));
            return;
          }

          setUpdateMsg("Nota salva com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({
            nota: stripObjectToMutation(valuesToSave),
          });

          if (error) {
            setErrors({
              cliente: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createNota.errors?.length > 0) {
            setErrors(errorsToFormik(data.createNota.errors));
            return;
          }

          navigate(`/notas/${data.createNota.nota.id}`);
        }
      }}
    >
      {(props) => {
        const headerItens: TableHeaders<ItemNota>[] = [
          {
            label: "Produto",
            wrapped: true,
            render: (item) => item.produto && `${item.produto.nome}`,
          },
          {
            label: "Quantidade",
            wrapped: true,
            render: (item) => formatNumber(item.quantidade, 2, true),
          },
          {
            label: "Valor Unitário",
            wrapped: true,
            render: (item) => formatNumber(item.valor_unitario, 2, true),
          },
          {
            label: "Valor Total",
            wrapped: true,
            render: (item) =>
              formatNumber(item.quantidade * item.valor_unitario, 2, true),
          },
          {
            label: "",
            wrapped: true,
            render: (_, i) => (
              <>
                <Button
                  colorScheme="orange"
                  onClick={() => {
                    console.log("editing", i, props.values.itens[i]);
                    setEditItemIndex(i);
                    onOpen();
                  }}
                  type="button"
                >
                  <FontAwesomeIcon icon={faPen} />
                </Button>
                <ConfirmButton
                  isLoading={false}
                  onClick={() => {
                    const itens = [...props.values.itens];
                    itens.splice(i, 1);
                    props.setFieldValue("itens", itens);
                  }}
                  colorScheme="red"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ConfirmButton>
              </>
            ),
          },
        ];

        return (
          <Form>
            {props.values.itens[editItemIndex] && (
              <ItemNotaModal
                itens={props.values.itens}
                itemIndex={editItemIndex}
                isOpen={isOpen}
                onClose={onClose}
              />
            )}
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 1]}>
                <InputField
                  name="numero"
                  label="Numero"
                  placeholder="Numero"
                  mask="999999999"
                />
              </GridItem>
              <GridItem colSpan={[6, 1]}>
                <SelectField name="modelo" label="Modelo" placeholder="Modelo">
                  <option value="21">Nota fiscal de comunicação</option>
                  <option value="22">Nota fiscal de telecomunicação</option>
                </SelectField>
              </GridItem>
              <GridItem colSpan={[6, 1]}>
                <DateSelector
                  name="emissao"
                  label="Emissão"
                  placeholder="Emissão"
                />
              </GridItem>
              <GridItem colSpan={[6, 3]}>
                <SerieSelectorField
                  name="serie"
                  label="Série"
                  placeholder="Selecione um série"
                />
              </GridItem>
              <GridItem colSpan={6}>
                <ClienteSelectorField name="cliente" label="Cliente" />
              </GridItem>
              <GridItem colSpan={6}>
                <Heading> Itens da nota </Heading>

                <Button
                  onClick={() => {
                    console.log("cliente", props.values.cliente);
                    const itens: ItemNota[] = [
                      ...props.values.itens,
                      {
                        acrescimo: 0,
                        aliquotaicms: 0,
                        desconto: 0,
                        cfop:
                          props.values.cliente?.tipo === "JURIDICO"
                            ? "5303"
                            : "5307",
                        icms: 0,
                        quantidade: 0,
                        valor_total: 0,
                        valor_unitario: 0,
                        issTributavel: false,
                      },
                    ];
                    props.setFieldValue("itens", itens);
                    setEditItemIndex(itens.length - 1);
                    onOpen();
                  }}
                  type="button"
                  my="1em"
                  colorScheme="green"
                >
                  Incluir Item
                </Button>
              </GridItem>
              <GridItem colSpan={6}>
                <ResponsiveTable
                  headers={headerItens}
                  data={props.values.itens}
                  tfoot={
                    <Tr>
                      <Td display={["none", "table-cell"]} colSpan={3}>
                        Total Geral:
                      </Td>
                      <Td display={["block", "table-cell"]} colSpan={2}>
                        <Badge display={["inline", "none"]} colorScheme="teal">
                          Total Geral:
                        </Badge>
                        {formatNumber(
                          props.values.itens.reduce(
                            (a, b) => a + b.quantidade * b.valor_unitario,
                            0
                          ),
                          2,
                          true
                        )}
                      </Td>
                    </Tr>
                  }
                />
              </GridItem>
            </Grid>

            <Button
              type="submit"
              mt="1em"
              colorScheme="teal"
              isLoading={props.isSubmitting}
            >
              Salvar
            </Button>
          </Form>
        );
      }}
    </FormikWrapper>
  );
};

export default NotaForm;
