import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Grid, GridItem, Heading } from "@chakra-ui/layout";
import {
  faBan,
  faPen,
  faPlus,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import DateSelector from "../../components/DateComponents/DateSelector";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  DateRangeInput,
  Nota,
  useCancelaNFsMutation,
  useDeleteNotaMutation,
  useNotasQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { useIsAuth } from "../../utils/useIsAuth";

const Notas: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [emissao, setEmissao] = React.useState({
    inicio: startOfMonth(new Date()),
    fim: new Date(),
  } as DateRangeInput);
  const [{ data, fetching }, query] = useNotasQuery({
    variables: { page: page, filter, emissao },
    requestPolicy: "cache-and-network",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteNotaMutation();
  const [{ fetching: cancelaLoading }, cancelaNFS] = useCancelaNFsMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (nota: Nota) => {
    await deleteData({ id: nota.id });
    setDeleteSuccessMsg(`Nota ${nota.numero} foi removida com sucesso!`);
    query();
  };

  const onCancelaConfirm = async (nota: Nota) => {
    await cancelaNFS({ id: nota.id });
    setDeleteSuccessMsg(`Nota ${nota.numero} foi cancelada com sucesso!`);
    query();
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Nota>[] = [
    {
      label: "#",
      wrapped: true,
      render: (nota) => nota.numero,
    },
    {
      label: "Série",
      wrapped: true,
      render: (nota) => (nota.serie ? nota.serie.codigo : ""),
    },
    {
      label: "Cliente",
      wrapped: true,
      render: (nota) => (nota.cliente ? nota.cliente.nome : ""),
    },
    {
      label: "Emissão",
      wrapped: true,
      render: (nota) => formatDate(nota.emissao),
    },
    {
      label: "Valor Total",
      wrapped: true,
      render: (nota) => formatNumber(nota.valortotal, 2, true),
    },
    {
      label: " ",
      wrapped: true,
      render: (nota) => (
        <>
          <Link to={`/impressaoNota/${nota.id}`}>
            <Button colorScheme="green" type="button">
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          </Link>
          <Link to={`/notas/${nota.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(nota as Nota)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
          <ConfirmButton
            isLoading={cancelaLoading}
            onClick={() => onCancelaConfirm(nota as Nota)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faBan} />
          </ConfirmButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Heading mb="1em">Notas</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/notas/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Nova nota
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Formik
        initialValues={emissao}
        onSubmit={(values) => {
          setEmissao(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              marginBottom="0.5rem"
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Vencimento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Vencimento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Confirmar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setEmissao({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.notas.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.notas.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Notas;
