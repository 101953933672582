import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  faKey,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import LoadingWheel from "../../components/LoadingWheel";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  useDeleteUsuarioMutation,
  useUsuariosQuery,
  Usuario,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const Usuarios: React.FC<{}> = () => {
  useIsAuth();
  const [{ data, fetching }, queryUsuarios] = useUsuariosQuery({
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteUsuario] =
    useDeleteUsuarioMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (usuario: string) => {
    await deleteUsuario({ usuario });
    setDeleteSuccessMsg(`Usuario ${usuario} foi removido com sucesso!`);
    await queryUsuarios();
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Usuario>[] = [
    {
      label: "Usuario",
      render: (usuario) => usuario.usuario,
      wrapped: true,
    },
    {
      label: "Tipo",
      render: (usuario) => usuario.role,
      wrapped: true,
    },
    {
      label: "Status",
      render: (usuario) => (usuario.isActive ? "Ativo" : "Desativado"),
      wrapped: true,
    },
    {
      label: " ",
      render: (usuario) => (
        <>
          {usuario.usuario !== "admin" && (
            <>
              <Link to={`/usuarios/${usuario.usuario}`}>
                <Button colorScheme="orange">
                  <FontAwesomeIcon icon={faPen} />
                </Button>
              </Link>
              <Link to={`/resetPwd/${usuario.usuario}`}>
                <Button colorScheme="orange" title="Resetar senha do usuario">
                  <FontAwesomeIcon icon={faKey} />
                </Button>
              </Link>
              <ConfirmButton
                isLoading={deleteLoading}
                onClick={() => onDeleteConfirm(usuario.usuario)}
                colorScheme="red"
              >
                <FontAwesomeIcon icon={faTrash} />
              </ConfirmButton>
            </>
          )}
        </>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Usuarios</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/usuarios/novo">
          <Button colorScheme="blue">
            <FontAwesomeIcon icon={faPlus} /> Novo usuario
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <ResponsiveTable data={data?.usuarios} headers={headers} />
    </>
  );
};

export default Usuarios;
