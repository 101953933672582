export default function stripObjectToMutation<T>(obj: T) {
  if (typeof obj != "object") {
    return obj;
  }
  try {
    // __typename
    // @ts-ignore
    delete obj.__typename;
  } catch (e) {
    // Silient error
  }
  try {
    for (const key of Object.keys(obj)) {
      if (!!obj[key] && typeof obj[key] === "object") {
        try {
          obj[key] = stripObjectToMutation(obj[key]);
        } catch (e) {
          // Silent error
        }
      }
    }
  } catch (e) {
    // Silent error
  }
  return obj;
}
