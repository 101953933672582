import { StatusChamado } from "../generated/graphql";

export function formatStatusChamado(status: StatusChamado): string {
  switch (status) {
    case StatusChamado.Iniciado:
      return "Iniciado";
    case StatusChamado.Aguardando:
      return "Aguardando cliente";
    case StatusChamado.Emandamento:
      return "Em andamento";
    case StatusChamado.Material:
      return "Aguardando Material";
    case StatusChamado.Aprovacao:
      return "Aguardando aprovação";
    case StatusChamado.Fechado:
      return "Fechado";
    default:
      return "";
  }
}
