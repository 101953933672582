import {
  Badge,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

export type TableHeaders<T> = {
  label: string | React.ReactNode;
  render: (item: T, index: number) => string | React.ReactNode;
  wrapped?: boolean;
};

export interface ResponsiveTableProps {
  headers: TableHeaders<any>[];
  data: Record<string, any>[];
  tfoot?: React.ReactNode;
  thead?: React.ReactNode;
  isSmall?: boolean;
}

const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
  headers,
  data,
  tfoot,
  thead,
  isSmall,
}) => {
  return (
    <Table variant="striped" colorScheme="teal" size={isSmall ? "sm" : "md"}>
      <Thead display={["none", "table-header-group"]}>
        <Tr>
          {headers.map((header, i) => (
            <Th key={`${i}`}>{header.label}</Th>
          ))}
        </Tr>
        {thead}
      </Thead>
      <Tbody>
        {!!data &&
          data.map((item, i) => (
            <Tr key={`${i}`}>
              {headers.map((header, j) =>
                header.wrapped ? (
                  <Td
                    key={`${j}`}
                    whiteSpace="nowrap"
                    width={["100%", "1%"]}
                    display={["block", "table-cell"]}
                  >
                    <Badge display={["inline", "none"]} colorScheme="teal">
                      {header.label}
                    </Badge>
                    {header.render(item, i)}
                  </Td>
                ) : (
                  <Td
                    key={`${j}`}
                    width="100%"
                    display={["block", "table-cell"]}
                  >
                    <Badge display={["inline", "none"]} colorScheme="teal">
                      {header.label}
                    </Badge>
                    {header.render(item, i)}
                  </Td>
                )
              )}
            </Tr>
          ))}
      </Tbody>
      {!!tfoot && <Tfoot>{tfoot}</Tfoot>}
    </Table>
  );
};

export default ResponsiveTable;
