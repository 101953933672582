import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  faList,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Caixa,
  useCaixasQuery,
  useDeleteCaixaMutation,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const Caixas: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching }, query] = useCaixasQuery({
    variables: { page: page, filter },
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteCaixaMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  if (fetching) {
    return <LoadingWheel />;
  }
  const onDeleteConfirm = async (caixa: Caixa) => {
    await deleteData({ id: caixa.id });
    setDeleteSuccessMsg(`Caixa ${caixa.nome} foi removido com sucesso!`);
    query();
  };

  const headers: TableHeaders<Caixa>[] = [
    {
      label: "Nome",
      render: (caixa) => caixa.nome,
    },
    {
      label: " ",
      wrapped: true,
      render: (caixa) => (
        <>
          <Link to={`/caixas/${caixa.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <Link to={`/lancamentosCaixa/${caixa.id}`}>
            <Button colorScheme="green" type="button">
              <FontAwesomeIcon icon={faList} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(caixa as Caixa)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Heading mb="1em">Caixas</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/caixas/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo caixa
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.caixas.data} headers={headers} />

      {/* <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.caixas.data.map((caixa) => (
            <Tr key={caixa.id}>
              <Td>{caixa.nome}</Td>
              <Td whiteSpace="nowrap" width="1%">
                <Link to={`/caixas/${caixa.id}`}>
                  <Button colorScheme="orange" type="button">
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </Link>
                <Link to={`/lancamentosCaixa/${caixa.id}`}>
                  <Button colorScheme="green" type="button">
                    <FontAwesomeIcon icon={faList} />
                  </Button>
                </Link>
                <ConfirmButton
                  isLoading={deleteLoading}
                  onClick={() => onDeleteConfirm(caixa as Caixa)}
                  colorScheme="red"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ConfirmButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table> */}
      <PaginationButtons
        totalPages={data?.caixas.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Caixas;
