import React from "react";
import { Center } from "@chakra-ui/layout";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface LoadingWheelProps {}

const LoadingWheel: React.FC<LoadingWheelProps> = (props) => {
  return (
    <Center>
      <FontAwesomeIcon fontSize="2rem" icon={faSync} spin />
    </Center>
  );
};

export default LoadingWheel;
