import {
  Button,
  Text,
  Grid,
  GridItem,
  Heading,
  Checkbox,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import InputField from "../../components/InputField";
import { geracgnatPPP, geraCGNATVlan } from "../../utils/geraCGNATVlan";

const GeraCgnatScript: React.FC<{}> = (props) => {
  const initialData = {
    ips: [
      {
        ipValido: "",
        ipFaixaInicial: "",
      },
    ],
  };
  const [regras, setRegras] = useState([]);
  const [isPPP, setIsPPP] = useState(false);

  return (
    <>
      <Heading>Gerar scripts de CGNAT </Heading>
      <Checkbox isChecked={isPPP} onChange={() => setIsPPP(!isPPP)}>
        Gerar para PPP
      </Checkbox>

      <Formik
        initialValues={initialData}
        onSubmit={(values) => {
          console.log("values", values);
          let regrasFinais = [`/ip firewall nat`];
          let i = 1;
          for (const ipG of values.ips) {
            if (isPPP) {
              const regras = geracgnatPPP(ipG.ipValido, ipG.ipFaixaInicial, i);
              i += 4;
              regrasFinais = [...regrasFinais, ...regras];
            } else {
              const regras = geraCGNATVlan(ipG.ipValido, ipG.ipFaixaInicial, i);
              i += 4;
              regrasFinais = [...regrasFinais, ...regras];
            }
          }

          setRegras(regrasFinais);
        }}
      >
        {(props) => {
          return (
            <Form>
              <Button
                type="button"
                onClick={() => {
                  const ips = [
                    ...props.values.ips,
                    {
                      ipValido: "",
                      ipFaixaInicial: "",
                    },
                  ];
                  props.setValues({
                    ips,
                  });
                }}
              >
                Incluir mais ips
              </Button>
              <Grid gap={6} templateColumns="repeat(6, 1fr)">
                {props.values.ips.map((_, i) => (
                  <>
                    <GridItem colSpan={[6, 3]}>
                      <InputField
                        label="IP Valido"
                        name={`ips[${i}]ipValido`}
                      />
                    </GridItem>
                    <GridItem colSpan={[6, 3]}>
                      <InputField
                        label="IP Faixa inicial"
                        name={`ips[${i}]ipFaixaInicial`}
                      />
                    </GridItem>
                  </>
                ))}
              </Grid>
              <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
                Gerar Regras
              </Button>
            </Form>
          );
        }}
      </Formik>
      {regras.length > 1 && (
        <>
          <Heading>Regras Geradas</Heading>
          {regras.map((text, i) => (
            <Text key={`${i}`}>{text}</Text>
          ))}
        </>
      )}
    </>
  );
};

export default GeraCgnatScript;
