import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Form } from "formik";
import React, { useState } from "react";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import InputNumber from "../../components/InputNumber";
import {
  ProdutoInput,
  useCreateProdutoMutation,
  useProdutoQuery,
  useUpdateProdutoMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useNavigate } from "react-router";

const novoProduto: ProdutoInput = {
  nome: "",
  velocidade: "",
  valor: 0,
  classe: 2,
  grupo: "",
};

const ProdutoForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const [{ fetching, data }] = useProdutoQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [, createMutation] = useCreateProdutoMutation();
  const [, updateMutation] = useUpdateProdutoMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.produto ? "Editar" : "Novo"} Produto`}
      updateMsg={updateMsg}
      initialData={data?.produto ? data.produto : novoProduto}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating produto", values);
        if (data?.produto) {
          /// é alteracao
          const { error, data } = await updateMutation({
            produto: stripObjectToMutation(values),
          });

          if (error) {
            setErrors({
              classe: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateProduto.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateProduto.errors));
          }

          setUpdateMsg("Produto salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({
            produto: stripObjectToMutation(values),
          });

          if (error) {
            console.log("errors", error);
            setErrors({
              classe: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createProduto.errors?.length > 0) {
            console.log("errors", data.createProduto.errors);
            setErrors(errorsToFormik(data.createProduto.errors));
            return;
          }

          navigate(`/produtos/${data.createProduto.produto.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={6}>
              <InputField name="nome" label="Nome" placeholder="Nome" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="velocidade"
                label="Velocidade"
                placeholder="Velocidade"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber
                name="valor"
                label="Valor"
                placeholder="Valor"
                decimalPlaces={2}
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <SelectField
                name="classe"
                label="Classe"
                placeholder="Selecione uma classe"
              >
                <option value={1}>
                  Comunicacao de Dados (Circuito Interno)
                </option>
                <option value={2}>Provimento Internet</option>
                <option value={3}>Servico Multimedia</option>
                <option value={9}>Outros</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <SelectField
                name="grupo"
                label="Grupo"
                placeholder="Selecione um grupo"
              >
                <option value="0101">
                  Assinatura de serviços de telefonia
                </option>
                <option value="0102">
                  Assinatura de serviços de comunicação de dados
                </option>
                <option value="0103">
                  Assinatura de serviços de TV por Assinatura
                </option>
                <option value="0104">
                  Assinatura de serviços de provimento à internet
                </option>
                <option value="0105">
                  Assinatura de outros serviços de multimídia
                </option>
                <option value="0199">Assinatura de outros serviços</option>
                <option value="0201">
                  Habilitação de serviços de telefonia
                </option>
                <option value="0202">
                  Habilitação de serviços de comunicação de dados
                </option>
                <option value="0203">Habilitação de TV por Assinatura</option>
                <option value="0204">
                  Habilitação de serviços de provimento à internet
                </option>
                <option value="0205">
                  Habilitação de outros serviços multimídia
                </option>
                <option value="0299">Habilitação de outros serviços</option>
                <option value="0301">Serviço Medido - chamadas locais</option>
                <option value="0302">
                  Serviço Medido - chamadas interurbanas no Estado
                </option>
                <option value="0303">
                  Serviço Medido - chamadas interurbanas para fora do Estado
                </option>
                <option value="0304">
                  Serviço Medido - chamadas internacionais
                </option>
                <option value="0305">
                  Serviço Medido - Números Especiais (0300/0500/0600/0800/etc.)
                </option>
                <option value="0306">
                  Serviço Medido - comunicação de dados
                </option>
                <option value="0307">
                  Serviço Medido - chamadas originadas em Roaming
                </option>
                <option value="0308">
                  Serviço Medido - chamadas recebidas em Roaming
                </option>
                <option value="0309">
                  Serviço Medido - adicional de chamada
                </option>
                <option value="0310">
                  Serviço Medido - provimento de acesso à Internet
                </option>
                <option value="0311">
                  Serviço Medido - pay-per-view (programação TV)
                </option>
                <option value="0312">Serviço Medido - Mensagem SMS</option>
                <option value="0313">Serviço Medido - Mensagem MMS</option>
                <option value="0314">Serviço Medido - outros mensagens</option>
                <option value="0315">
                  Serviço Medido - serviço multimídia
                </option>
                <option value="0399">Serviço Medido - outros serviços</option>
                <option value="0401">Cartão Telefônico - Telefonia Fixa</option>
                <option value="0402">
                  Cartão Telefônico - Telefonia Móvel
                </option>
                <option value="0403">
                  Cartão de Provimento de acesso à internet
                </option>
                <option value="0404">Ficha Telefônica</option>
                <option value="0405">
                  Recarga de Créditos - Telefonia Fixa
                </option>
                <option value="0406">
                  Recarga de Créditos - Telefonia Móvel
                </option>
                <option value="0407">
                  Recarga de Créditos - Provimento de acesso à Internet
                </option>
                <option value="0499">
                  Outras cobranças realizadas de assinantes de plano serviço
                  pré-pago
                </option>
                <option value="0501">
                  Serviço Adicional (substituição de número, troca de aparelho,
                  emissão de 2a via de conta, conta detalhada, etc.)
                </option>
                <option value="0502">
                  Serviço Facilidades (identificador de chamadas, caixa postal,
                  transferência temporária, não-perturbe, etc.)
                </option>
                <option value="0599">Outros Serviços</option>
                <option value="0701">
                  Disponibilização de Aparelho Telefônico
                </option>
                <option value="0702">
                  Disponibilização de Aparelho Identificador de chamadas
                </option>
                <option value="0703">Disponibilização de Modem</option>
                <option value="0704">Disponibilização de Rack</option>
                <option value="0705">Disponibilização de Sala/Recinto</option>
                <option value="0706">Disponibilização de Roteador</option>
                <option value="0707">Disponibilização de Servidor</option>
                <option value="0708">Disponibilização de Multiplexador</option>
                <option value="0709">
                  Disponibilização de Decodificador/Conversor
                </option>
                <option value="0799">Outras disponibilizações</option>
                <option value="0801">Cobrança de Serviços de Terceiros</option>
                <option value="0802">Cobrança de Seguros</option>
                <option value="0803">
                  Cobrança de Financiamento de Aparelho/Serviços
                </option>
                <option value="0804">Cobrança de Juros de Mora</option>
                <option value="0805">Cobrança de Multa de Mora</option>
                <option value="0806">
                  Cobrança de Conta de meses anteriores
                </option>
                <option value="0807">
                  Cobrança de Taxa Iluminação Pública
                </option>
                <option value="0808">Retenção de ICMS-ST</option>
                <option value="0899">Outras Cobranças</option>
                <option value="1001">
                  Serviço não medido de serviços de telefonia
                </option>
                <option value="1002">
                  Serviço não medido de serviços de comunicação de dados
                </option>
                <option value="1003">
                  Serviço não medido de serviços de TV por Assinatura
                </option>
                <option value="1004">
                  Serviço não medido de serviços de provimento à internet
                </option>
                <option value="1005">
                  Serviço não medido de outros serviços de multimídia
                </option>
                <option value="1099">
                  Serviço não medido de outros serviços
                </option>
                <option value="1101">Interconexão: Detraf, SMS, MMS</option>
                <option value="1102">Detrat, Transmissão</option>
                <option value="1103">Roaming</option>
                <option value="1104">
                  Exploração Industrial de Linha Dedicada - EILD
                </option>
                <option value="1105">
                  Lançamento de ICMS proporcional às saídas isentas, não
                  tributadas ou com redução de base de cálculo (§ 1o, Cláusula
                  terceira, Convênio ICMS 17/2013)
                </option>
                <option value="1106">
                  Lançamento de ICMS proporcional às cessões de meio destinadas
                  a consumo próprio (§ 1o Cláusula terceira, Convênio ICMS
                  17/2013)
                </option>
                <option value="1107">
                  Lançamento de ICMS complementar, na condição de responsável
                  tributário (§ 2o, Cláusula terceira, Convênio 17/2013)
                </option>
                <option value="1199">Outras Cessões de Meios de Rede</option>
              </SelectField>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default ProdutoForm;
