import React from "react";
import { Image } from "@react-pdf/renderer";

export const LogoSicredi = () => (
  <Image
    src="/assets/logosicredi.jpg"
    cache
    style={{ width: "50px", height: "12px" }}
  />
);
