import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import NumberInput from "../../components/NumberInput";
import {
  SerieInput,
  useCreateSerieMutation,
  useSerieQuery,
  useUpdateSerieMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novoSerie: SerieInput = {
  codigo: "",
  descricao: "",
};

const SerieForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const [{ fetching, data }] = useSerieQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [numeracao, setNumeracao] = useState(0);
  const [, createMutation] = useCreateSerieMutation();
  const [, updateMutation] = useUpdateSerieMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  useEffect(() => {
    if (data && data.serie) {
      setNumeracao(data.serie.numeracao);
    }
  }, [data]);

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.serie ? "Editar" : "Novo"} Serie`}
      updateMsg={updateMsg}
      initialData={data?.serie ? data.serie.data : novoSerie}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating serie", values);
        const nAlterar =
          data &&
          data.serie &&
          data.serie.numeracao - numeracao !== 0 &&
          numeracao;
        if (data?.serie) {
          /// é alteracao
          const { error, data } = await updateMutation({
            serie: stripObjectToMutation(values),
            numeracao: !!nAlterar ? nAlterar : undefined,
          });

          if (error) {
            setErrors({
              descricao: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateSerie.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateSerie.errors));
          }

          setUpdateMsg("Serie salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({
            serie: stripObjectToMutation(values),
          });

          if (error) {
            setErrors({
              descricao: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createSerie.errors?.length > 0) {
            setErrors(errorsToFormik(data.createSerie.errors));
            return;
          }

          navigate(`/series/${data.createSerie.serie.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="codigo"
                label="Código"
                placeholder="Código"
                mask="999"
              />
            </GridItem>
            <GridItem colSpan={[6, 4]}>
              <InputField
                name="descricao"
                label="Descrição"
                placeholder="Descrição"
              />
            </GridItem>
            {data && data.serie && (
              <GridItem colSpan={[6, 3]}>
                <NumberInput
                  name="numeracao"
                  label="Próximo Numero da Série"
                  placeholder="Próximo numero da série"
                  decimalPlaces={0}
                  value={numeracao}
                  onChange={(v) => setNumeracao(v)}
                />
              </GridItem>
            )}
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default SerieForm;
