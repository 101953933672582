import { differenceInDays, parseISO } from "date-fns";
import { calcNossoNumero } from "./calcNossoNumero";
import { modulo11 } from "./modulo11";
import { formataNumero } from "./formataNumero";

export function linhaDigitavel(
  numeroDocumento: string,
  agencia: string,
  carteira: string,
  posto: string,
  codCliente: string,
  valor: number,
  vencimento: Date,
  codigoBanco: string,
  codigoModalidade: string,
  dataProcessamento: Date = new Date()
) {
  switch (codigoBanco) {
    case "748":
      return linhaDigitavel748(
        numeroDocumento,
        agencia,
        carteira,
        posto,
        codCliente,
        valor,
        vencimento,
        codigoBanco,
        dataProcessamento
      );
    case "756":
      return linhaDigitavel756(
        numeroDocumento,
        agencia,
        carteira,
        posto,
        codCliente,
        valor,
        vencimento,
        codigoBanco,
        codigoModalidade,
        dataProcessamento
      );
    default:
      return numeroDocumento;
  }
}

export function linhaDigitavel748(
  numeroDocumento: string,
  agencia: string,
  carteira: string,
  posto: string,
  codCliente: string,
  valor: number,
  vencimento: Date,
  codigoBanco: string,
  dataProcessamento: Date = new Date()
) {
  const nossoNumero = formataNumero(
    calcNossoNumero(
      numeroDocumento,
      agencia,
      posto,
      codCliente,
      codigoBanco,
      dataProcessamento
    ),
    9
  );
  const agenciaStr = formataNumero(agencia, 4);
  const carteiraStr = formataNumero(carteira, 2);
  const convenio = formataNumero(posto, 2) + formataNumero(codCliente, 5);
  const valorStr = formataNumero(valor, 10, 2, "R");
  const fatorVencimento =
    differenceInDays(
      typeof vencimento === "string" ? parseISO(vencimento) : vencimento,
      new Date(2000, 6, 3, 0, 0, 0)
    ) + 1000;

  const campolivre = carteiraStr + nossoNumero + agenciaStr + convenio + "10";

  const preDv = modulo11(campolivre, 9, true);
  const dv = preDv > 1 ? 11 - preDv : 0;

  const preDvGeral =
    11 -
    modulo11(
      "748" +
        "9" +
        "0" +
        `${fatorVencimento}` +
        valorStr +
        campolivre +
        `${dv}`,
      9,
      true
    );
  const dvGeral = preDvGeral > 9 || preDvGeral === 0 ? 1 : preDvGeral;

  return (
    "748" +
    "9" +
    `${dvGeral}` +
    `${fatorVencimento}` +
    valorStr +
    campolivre +
    `${dv}`
  );
}

export function linhaDigitavel756(
  numeroDocumento: string,
  agencia: string,
  carteira: string,
  posto: string,
  codCliente: string,
  valor: number,
  vencimento: Date,
  codigoBanco: string,
  codigoModalidade: string,
  dataProcessamento: Date = new Date()
) {
  const nossoNumero = formataNumero(
    calcNossoNumero(
      numeroDocumento,
      agencia,
      posto,
      codCliente,
      codigoBanco,
      dataProcessamento
    ),
    8
  );
  const agenciaStr = formataNumero(agencia, 4);
  const carteiraStr = formataNumero(carteira, 2);
  const convenio = formataNumero(codCliente, 7);
  const modalidade = formataNumero(codigoModalidade, 2);
  const valorStr = formataNumero(valor, 10, 2, "R");
  const fatorVencimento =
    differenceInDays(
      typeof vencimento === "string" ? parseISO(vencimento) : vencimento,
      new Date(2000, 6, 3, 0, 0, 0)
    ) + 1000;

  const campolivre = modalidade + convenio + nossoNumero + "001";

  const dv = modulo11(
    "7569" + fatorVencimento + valorStr + carteiraStr + agenciaStr + campolivre
  );

  return (
    "756" +
    "9" +
    `${dv}` +
    `${fatorVencimento}` +
    valorStr +
    carteiraStr +
    agenciaStr +
    campolivre
  );
}
