import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CheckField from "../../components/CheckField";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import NumberInput from "../../components/NumberInput";
import {
  BancoInput,
  useBancoQuery,
  useCadastraWebhookInterMutation,
  useCreateBancoMutation,
  useUpdateBancoMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novoBanco: BancoInput = {
  nome: "",
  codigo: "",
  agencia: "",
  agenciaDv: "",
  conta: "",
  contaDv: "",
  codCliente: "",
  codClienteDv: "",
  carteira: "",
  operacao: "",
  percentualCredito: 0,
  percentualDebito: 0,
  chavePix: "",
  isActive: true,
};

const BancoForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const [{ fetching, data }] = useBancoQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [numeracaoCNAB, setNumeracaoCNAB] = useState(0);
  const [, createMutation] = useCreateBancoMutation();
  const [, updateMutation] = useUpdateBancoMutation();
  const [, cadastraWebhookInterMutation] = useCadastraWebhookInterMutation();

  const [updateMsg, setUpdateMsg] = useState("");
  const toast = useToast();

  useEffect(() => {
    if (data && data.banco) {
      setNumeracaoCNAB(data.banco.numeracaoCNAB);
    }
  }, [data]);

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.banco ? "Editar" : "Novo"} Banco`}
      updateMsg={updateMsg}
      initialData={data?.banco ? data.banco.data : novoBanco}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating banco", values);
        const nAlterar =
          data &&
          data.banco &&
          data.banco.numeracaoCNAB - numeracaoCNAB !== 0 &&
          numeracaoCNAB;
        if (data?.banco) {
          /// é alteracao
          console.log("alterando banco", values, nAlterar);
          const { error, data } = await updateMutation({
            banco: stripObjectToMutation(values),
            numeracaoCNAB: !!nAlterar ? nAlterar : undefined,
          });

          if (error) {
            console.log("returned error", error);
            setErrors({
              percentualDebito: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateBanco.errors?.length > 0) {
            console.log("returned errors", data.updateBanco);
            setErrors(errorsToFormik(data.updateBanco.errors));
          }
          console.log("updated success");

          setUpdateMsg("Banco salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({ banco: values });

          if (error) {
            setErrors({
              percentualDebito: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createBanco.errors?.length > 0) {
            setErrors(errorsToFormik(data.createBanco.errors));
            return;
          }

          navigate(`/bancos/${data.createBanco.banco.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={[6, 1]}>
              <InputField
                autoFocus
                name="codigo"
                label="Codigo"
                placeholder="Codigo"
              />
            </GridItem>
            <GridItem colSpan={[6, 5]}>
              <InputField name="nome" label="Nome" placeholder="Nome" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="agencia"
                label="Agencia"
                placeholder="Agencia"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField
                name="agenciaDv"
                label="Agenciadv"
                placeholder="Agenciadv"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField name="conta" label="Conta" placeholder="Conta" />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField
                name="contaDv"
                label="Contadv"
                placeholder="Contadv"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="codCliente"
                label="Codcliente"
                placeholder="Codcliente"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField
                name="codClienteDv"
                label="Codclientedv"
                placeholder="Codclientedv"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="carteira"
                label="Carteira"
                placeholder="Carteira"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField
                name="operacao"
                label="Operação"
                placeholder="Código de operação"
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <InputNumber
                name="percentualDebito"
                label="Percentual Cartão de débito"
                placeholder="% a descontar do valor do cartão de débito"
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <InputNumber
                name="percentualCredito"
                label="Percentual Cartão de crédito"
                placeholder="% a descontar do valor do cartão de crédito"
              />
            </GridItem>
            <GridItem colSpan={6}>
              <InputField
                name="chavePix"
                label="Chave PIX"
                placeholder="Chave PIX Recebedor"
              />
            </GridItem>
            <GridItem colSpan={6}>
              <CheckField name="isActive" label="Ativado" />
            </GridItem>
            {data && data.banco && (
              <GridItem colSpan={[6, 3]}>
                <NumberInput
                  name="numeracaoCNAB"
                  label="Numeração de arquivo CNAB"
                  placeholder="% a descontar do valor do cartão de crédito"
                  decimalPlaces={0}
                  value={numeracaoCNAB}
                  onChange={(v) => setNumeracaoCNAB(v)}
                />
              </GridItem>
            )}
            {data && data.banco && data.banco.data.codigo === "077" && (
              <GridItem colSpan={[6, 3]}>
                <Button
                  type="button"
                  mt="1em"
                  colorScheme="orange"
                  onClick={async () => {
                    try {
                      const { error } = await cadastraWebhookInterMutation({
                        id: data.banco.data.id,
                      });
                      if (error) {
                        console.log("returned error", error);
                        toast({
                          title: "Erro ao cadastrar webhook!",
                          description: error.message.replace("[GraphQL]", ""),
                          colorScheme: "red",
                          status: "error",
                        });
                        return;
                      }

                      console.log("cadastrar webhook success");

                      setUpdateMsg("Webhook cadastrado com sucesso!");
                    } catch (err) {
                      console.log("returned error", err);
                      toast({
                        title: "Erro ao cadastrar webhook!",
                        description: err.message.replace("[GraphQL]", ""),
                        colorScheme: "red",
                        status: "error",
                      });
                    }
                  }}
                >
                  Cadastrar Webhook PIX Inter
                </Button>
              </GridItem>
            )}
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default BancoForm;
