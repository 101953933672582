import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Select } from "@chakra-ui/select";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { useContratosQuery } from "../generated/graphql";

export type ContratoSelectorFieldProps =
  InputHTMLAttributes<HTMLSelectElement> & {
    name: string;
    label: string;
    clienteid?: number;
  };

const ContratoSelectorField: React.FC<ContratoSelectorFieldProps> = ({
  label,
  size: _,
  clienteid,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  const [{ fetching, data }] = useContratosQuery({
    variables: {
      page: 1,
      clienteid,
    },
    requestPolicy: "network-only",
  });
  if (!clienteid) {
    return null;
  }
  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        name={field.name}
        value={field.value && field.value.id}
        onBlur={field.onBlur}
        onChange={(e) => {
          setValue(
            data.contratos.data.find(
              (pln) => pln.id === parseInt(e.target.value),
              false
            )
          );
        }}
        {...props}
        id={field.name}
        placeholder={props.placeholder}
      >
        {!fetching &&
          !!data &&
          data.contratos.data.map((contrato) => (
            <option key={contrato.id} value={contrato.id}>
              {contrato.id} - {contrato.logradouro}, {contrato.numero} -{" "}
              {contrato.usuario}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};

export default ContratoSelectorField;
