import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Heading } from "@chakra-ui/layout";
import { Grid, GridItem, Table, Tr, Th, Tbody, Td } from "@chakra-ui/react";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import { useDreMutation } from "../../generated/graphql";
import { formatNumber } from "../../utils/formatNumber";
import { useIsAdmin } from "../../utils/useIsAdmin";

const Dre: React.FC<{}> = () => {
  useIsAdmin();

  const [{ data, error }, buscaDre] = useDreMutation();
  const valoresIniciais = {
    mes: startOfMonth(new Date()),
  };

  return (
    <>
      <Heading mb="1em">DRE financeiro</Heading>

      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          console.log("alterar", values);
          if (!values.mes || !(values.mes instanceof Date)) {
            setErrors({
              mes: "Mes deve ser preenchido",
            });
            return;
          }
          const { error, data } = await buscaDre({
            mes: values.mes,
          });
          console.log("Erro no dre", error, data);
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 2]}>
                <DateSelector name="mes" label="Mês" monthOnly={true} />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      <Table>
        <Tbody>
          <Tr>
            <Th>Receitas operacionais</Th>
            <Td>
              R${" "}
              {formatNumber(
                data?.dre?.receitas?.reduce((p, c) => p + c.valor, 0),
                2,
                true
              )}
            </Td>
          </Tr>
          <Tr>
            <Th>Deduções das receitas operacionais</Th>
            <Td>
              R${" "}
              {formatNumber(
                data?.dre?.receitas?.reduce(
                  (p, c) => p + c.valor - c.valor_pago,
                  0
                ),
                2,
                true
              )}
            </Td>
          </Tr>
          {data?.dre?.despesas?.map((despesa) => (
            <Tr key={`${despesa.classificacao_despesa}`}>
              <Th>{despesa.classificacao_despesa}</Th>
              <Td>R$ {formatNumber(despesa.total_pago, 2, true)}</Td>
            </Tr>
          ))}
          <Tr>
            <Th>Resultado Liquido</Th>
            <Td>
              R${" "}
              {formatNumber(
                data?.dre?.receitas?.reduce((p, c) => p + c.valor_pago * 1, 0) -
                  data?.dre?.despesas?.reduce(
                    (p, c) => p + c.total_pago * 1,
                    0
                  ),
                2,
                true
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default Dre;
