const reHex = /[0-9A-F]/;
const macMask = [
  reHex,
  reHex,
  ":",
  reHex,
  reHex,
  ":",
  reHex,
  reHex,
  ":",
  reHex,
  reHex,
  ":",
  reHex,
  reHex,
  ":",
  reHex,
  reHex,
];

export default macMask;
