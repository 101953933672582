import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  Contrato,
  MotivoCancelamento,
  useCancelarContratoMutation,
} from "../../generated/graphql";

export type CancelaContratoModalProps = {
  contrato: Contrato;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const CancelaContratoModal: React.FC<CancelaContratoModalProps> = ({
  contrato,
  ...props
}) => {
  const [motivoCancelamento, setMotivoCancelamento] = useState(
    MotivoCancelamento.Semviabilidade
  );
  const [outroMotivoCancelamento, setOutroMotivoCancelamento] = useState("");
  const toast = useToast();

  const [, cancelarContrato] = useCancelarContratoMutation();

  const onCancelarContrato = async () => {
    const { error } = await cancelarContrato({
      id: contrato.id,
      motivoCancelamento,
      outroMotivoCancelamento,
    });

    if (error) {
      toast({
        title: "Erro ao cancelar contrato",
        description: error.message.replace("[GraphQL]", ""),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    props.onClose();
    typeof props.onSuccess === "function" && props.onSuccess();
  };

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancelar Contrato</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt="0.5em">
            <FormLabel htmlFor="motivoCancelamento">
              Motivo do Cancelamento
            </FormLabel>
            <Select
              name="motivoCancelamento"
              onChange={(e) =>
                setMotivoCancelamento(e.target.value as MotivoCancelamento)
              }
              value={motivoCancelamento}
              id="motivoCancelamento"
            >
              <option value={MotivoCancelamento.Alteracao}>
                Alteração de plano
              </option>
              <option value={MotivoCancelamento.Transferencia}>
                Transferencia de titularidade
              </option>
              <option value={MotivoCancelamento.Insatisfacao}>
                Insatisfação
              </option>
              <option value={MotivoCancelamento.Faltaatendimento}>
                Falta de atendimento
              </option>
              <option value={MotivoCancelamento.Naoespecificado}>
                Não especificado
              </option>
              <option value={MotivoCancelamento.Inadimplencia}>
                Inadimplencia
              </option>
              <option value={MotivoCancelamento.Semviabilidade}>
                Sem viabilidade
              </option>
              <option value={MotivoCancelamento.Usoimpossibilitado}>
                Uso impossibilitado
              </option>
              <option value={MotivoCancelamento.Outros}>Outros</option>
            </Select>
          </FormControl>
          {motivoCancelamento === MotivoCancelamento.Outros && (
            <FormControl my="0.5rem">
              <FormLabel htmlFor="outroMotivoCancelamento">
                Especifique o outro motivo
              </FormLabel>
              <Input
                name="outroMotivoCancelamento"
                value={outroMotivoCancelamento}
                onChange={(e) => setOutroMotivoCancelamento(e.target.value)}
                id="outroMotivoCancelamento"
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onCancelarContrato}>
            Concluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelaContratoModal;
