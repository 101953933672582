import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useContratosLocalizacaoQuery } from "../generated/graphql";

const formatPosition = (coordenadas: string) => {
  const arrCord = JSON.parse(coordenadas);
  return {
    lat: arrCord[1],
    lng: arrCord[0],
  };
};

export const MapaClientes: React.FC<{}> = () => {
  const [{ data }] = useContratosLocalizacaoQuery({
    variables: {
      page: 1,
    },
  });

  const containerStyle = {
    width: "100%",
    height: "80vh",
  };
  const center = {
    lat: -23.0594251,
    lng: -51.0335505,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAW6dbq5LPMeJ98D3GPARX1D7bbZnCvB90">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
        {data?.contratos?.data?.map((contrato) => (
          <Marker
            position={formatPosition(contrato.coordenadas)}
            title={contrato.cliente.nome}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};
