import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export default function formatDate(
  dateToFormat: Date,
  formatString = "dd/MM/yyyy"
) {
  if (dateToFormat instanceof Date) {
    try {
      return format(dateToFormat, formatString, {
        locale: ptBR,
      });
    } catch (error) {
      console.log("error trying to format", dateToFormat);
      return "";
    }
  }
  if (typeof dateToFormat === "string") {
    try {
      return formatDate(parseISO(dateToFormat), formatString);
    } catch (error) {
      console.log("error trying to format", dateToFormat);
      return "";
    }
  }
  return "";
}
