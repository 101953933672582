import { Heading } from "@chakra-ui/react";
import { Document, Page, PDFViewer } from "@react-pdf/renderer";
import React from "react";
import LoadingWheel from "../../components/LoadingWheel";
import { useNotaQuery } from "../../generated/graphql";
import Nota21 from "../../pdfComponents/Nota21";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const ImpressaoNota: React.FC<{}> = () => {
  useIsAuth();
  const [{ fetching, data }] = useNotaQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });

  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Heading>Impressão da NF {data?.nota.numero}</Heading>

      <PDFViewer height="100%" style={{ minHeight: "90vh", minWidth: "100%" }}>
        <Document title="nota" author="Gabrimar Telecom">
          <Page size="A4" style={{ flexDirection: "column" }}>
            {data && data.nota && <Nota21 nota={data.nota} />}
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default ImpressaoNota;
