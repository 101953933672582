import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useFecharBancoMutation } from "../generated/graphql";
import NumberInput from "./NumberInput";

export type FecharBancoModalProps = {
  banco: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const FecharBancoModal: React.FC<FecharBancoModalProps> = ({
  banco,
  ...props
}) => {
  const [saldo, setSaldo] = useState(0);
  const [, fecharBanco] = useFecharBancoMutation();
  const toast = useToast();

  const onFecharBanco = async () => {
    const { error } = await fecharBanco({ bancoid: banco, saldo });

    if (error) {
      toast({
        title: "Erro ao fechar banco",
        description: error.message.replace("[GraphQL]", ""),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setSaldo(0);

    props.onClose();
    typeof props.onSuccess === "function" && props.onSuccess();
  };

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Fechar Banco</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Informe o valor de saldo no extrato do banco.</Text>
          <NumberInput
            name="saldo"
            value={saldo}
            onChange={(saldo) => setSaldo(saldo)}
            placeholder="Saldo em banco"
            label="Saldo ($)"
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onFecharBanco}>
            Concluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FecharBancoModal;
