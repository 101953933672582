import { Flex, Grid, GridItem, Heading } from "@chakra-ui/layout";
import { Button, useToast } from "@chakra-ui/react";
import { subDays } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  ItemResultadoProcessoWs,
  useVerificarEBaixaCobrancasMutation,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { useIsAuth } from "../../utils/useIsAuth";

const VerificarBaixasWS: React.FC<{}> = () => {
  useIsAuth();
  const [, verificarCobrancas] = useVerificarEBaixaCobrancasMutation();
  const toast = useToast();
  const [resultadoLeitura, setResultadoLeitura] = useState(
    [] as ItemResultadoProcessoWs[]
  );
  const [isConsultado, setIsConsultado] = useState(false);

  const handleConsultarBaixa = async (data_inicial: Date, data_final: Date) => {
    setIsConsultado(true);
    try {
      const { error, data } = await verificarCobrancas({
        data_inicial,
        data_final,
      });
      if (error) {
        console.log("error ocurred", error);
        throw error;
      }
      setResultadoLeitura(
        data.verificarEBaixarCobrancas.resultadoProcessamento
      );
      toast({
        title: `Consulta realizada com sucesso!`,
        description: `Consulta realizada da data ${formatDate(
          data.verificarEBaixarCobrancas.dataInicioConsulta
        )} a ${formatDate(
          data.verificarEBaixarCobrancas.dataFimConsulta
        )}, com ${
          data.verificarEBaixarCobrancas.resultadoProcessamento.length
        } processados!`,
        status: "success",
        duration: 9000,
      });
    } catch (error) {
      let errMsg = JSON.stringify(error);
      if (error.message) {
        errMsg = error.message;
      }

      toast({
        title: `Erro ao consultar`,
        description: `Ocorreu o seguinte erro ao consultar: ${errMsg}`,
        status: "error",
        duration: 9000,
      });
    }
    setIsConsultado(false);
  };

  const headersResultado: TableHeaders<ItemResultadoProcessoWs>[] = [
    {
      label: "#",
      wrapped: true,
      render: (resultado) => resultado.seuNumero || "S/N",
    },
    {
      label: "Nosso Numero",
      wrapped: true,
      render: (resultado) => resultado.nossoNumero || "S/N",
    },
    {
      label: "Cobrança",
      wrapped: true,
      render: (resultado) =>
        resultado.cobranca ? resultado.cobranca : "Não localizada",
    },
    {
      label: "Movimento",
      wrapped: true,
      render: (resultado) => resultado.mensagem,
    },
    {
      label: "Linha",
      wrapped: true,
      render: (resultado) => resultado.jsonBanco,
    },
  ];

  return (
    <>
      <Heading>Consultar Baixas da ultima semana</Heading>

      <Formik
        initialValues={{
          data_inicial: subDays(new Date(), 7),
          data_final: new Date(),
        }}
        onSubmit={(values) => {
          handleConsultarBaixa(values.data_inicial, values.data_final);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="data_inicial" label="Inicio Pagamento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="data_final" label="Fim Pagamento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button
                    type="submit"
                    colorScheme="blue"
                    mx="0.5rem"
                    isLoading={isConsultado}
                  >
                    Consultar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      {resultadoLeitura.length > 0 && (
        <>
          <Heading>Resultado da consulta</Heading>
          <ResponsiveTable headers={headersResultado} data={resultadoLeitura} />
        </>
      )}
    </>
  );
};

export default VerificarBaixasWS;
