import React from "react";
import { Image } from "@react-pdf/renderer";

export const LogoSicoob = () => (
  <Image
    src="/assets/logosicoob.jpg"
    cache
    style={{ width: "50px", height: "12px" }}
  />
);
