import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Heading } from "@chakra-ui/layout";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import { Contrato, useContratosByEstacaoQuery } from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

interface ContratoProps {}

const ContratosByEstacao: React.FC<ContratoProps> = (props) => {
  useIsAuth();
  const id = useGetQueryParam("id") as string;
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching, error }] = useContratosByEstacaoQuery({
    variables: { page: page, filter, estacaoid: parseInt(id), soAtivos: true },
    requestPolicy: "network-only",
  });

  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Contrato>[] = [
    {
      label: "Cliente",
      render: (contrato) => contrato.cliente?.nome,
      wrapped: true,
    },
    {
      label: "Endereço",
      render: (contrato) => `${contrato.logradouro}, ${contrato.numero}`,
      wrapped: true,
    },
    {
      label: "Cidade",
      render: (contrato) => `${contrato.cidade}-${contrato.uf}`,
      wrapped: true,
    },
    {
      label: "Telefone",
      render: (contrato) => contrato.telefone1,
      wrapped: true,
    },
    {
      label: "MAC/Vlan",
      render: (contrato) =>
        `${contrato.tipoAcesso}:${contrato.mac}/${contrato.vlan}`,
      wrapped: true,
    },
    {
      label: "Usuario",
      render: (contrato) =>
        `${contrato.usuario}${contrato.estacao && `/${contrato.estacao.nome}`}`,
      wrapped: true,
    },
    {
      label: "Data ativação",
      render: (contrato) =>
        contrato.dataAtivacao && `${formatDate(contrato.dataAtivacao)}`,
      wrapped: true,
    },
    {
      label: "Status",
      render: (contrato) =>
        contrato.isCanceled
          ? "Cancelado"
          : contrato.isBlocked
          ? "Bloqueado"
          : contrato.isAtivado
          ? "Ativado"
          : "Disponivel para ativação",
      wrapped: true,
    },
    {
      label: " ",
      render: (contrato) => (
        <>
          <Link to={`/contratos/${contrato.cliente.id}/${contrato.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
        </>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Contratos na Estação</Heading>

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {error.message.replace(/\[.*\]/, "")}
          </AlertDescription>
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.contratos.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.contratos.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default ContratosByEstacao;
