import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/alert";

const AdminOnly: React.FC<{}> = () => (
  <>
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="15ch"
    >
      <AlertIcon boxSize="40px" />
      <AlertTitle fontSize="lg" mt="1em" mb="0.5em">
        Acesso não permitido!
      </AlertTitle>
      <AlertDescription maxW="sm">
        Esse usuario não possui permissão para acessar esta função.
      </AlertDescription>
    </Alert>
  </>
);

export default AdminOnly;
