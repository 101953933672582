import { Button, Tooltip, useToast } from "@chakra-ui/react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  CheckUsuarioResponse,
  Contrato,
  useCheckContratoMutation,
} from "../../generated/graphql";
import CheckContratoModal from "./CheckContratoModal";

export interface CheckContratoButtonProps {
  contrato: Contrato;
}

const CheckContratoButton: React.FC<CheckContratoButtonProps> = ({
  contrato,
}) => {
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [checkRes, setCheckRes] = useState<CheckUsuarioResponse>(null);
  const [{ fetching }, checkContrato] = useCheckContratoMutation();
  const toast = useToast();

  const onCheckModalRequest = async () => {
    const { error, data } = await checkContrato({ id: contrato.id });

    if (error) {
      toast({
        title: "Erro ao buscar o status do contrato",
        description: error.message.replace("[GraphQL]", ""),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setCheckRes(data.checkContrato);

    setIsCheckModalOpen(true);
  };

  return (
    <>
      <CheckContratoModal
        contrato={contrato}
        checkRes={checkRes}
        isOpen={isCheckModalOpen}
        onClose={() => setIsCheckModalOpen(false)}
      />
      <Tooltip label="Status do usuario">
        <Button
          onClick={() => onCheckModalRequest()}
          isLoading={fetching}
          colorScheme="purple"
          type="button"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
      </Tooltip>
    </>
  );
};

export default CheckContratoButton;
