import { useMeQuery } from "../generated/graphql";
import { useEffect } from "react";
import { useNavigate } from "react-router";

export const useIsAdmin = () => {
  const navigate = useNavigate();
  const [{ data, fetching }] = useMeQuery();
  useEffect(() => {
    if (!fetching && !data?.me) {
      navigate("/login?next=" + window.location.pathname);
      return;
    }
    if (!fetching && data?.me?.role !== "ADMIN") {
      navigate("/admin-only");
    }
  }, [fetching, data, navigate]);
};
