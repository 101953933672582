import { cacheExchange } from "@urql/exchange-graphcache";
import { ClientOptions, dedupExchange, Exchange, fetchExchange } from "urql";
import { pipe, tap } from "wonka";
import {
  LoginMutation,
  LogoutMutation,
  MeDocument,
  MeQuery,
} from "../generated/graphql";
import { betterUpdateQuery } from "./betterUpdateQuery";
import getEnv from "./getEnv";

const errorExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    return pipe(
      forward(ops$),
      tap(({ error }) => {
        // const navigate = useNavigate();
        if (error?.message.includes("não autenticado")) {
          console.log(
            "resultado graphql nao autenticado",
            error,
            window.location.pathname
          );
          if (/^[/]pix.*/.test(window.location.pathname)) {
            return;
          }
          // navigate("/login?next=" + window.location.pathname);
          // window.location.href = "/login?next=" + window.location.pathname;
        }
        if (error?.message.includes("[GraphQL]")) {
          error.message = error.message.replace("[GraphQL]", "");
          return error;
        }
      })
    );
  };
const hashCode = (s: any) =>
  s.split("").reduce((a: any, b: any) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const createUrqlClient = (): ClientOptions => ({
  url: `${getEnv("REACT_APP_API_HOST") || "http://localhost:4000"}/graphql`,
  fetchOptions: {
    credentials: "include",
    cache: "no-cache",
  },
  exchanges: [
    dedupExchange,
    // @ts-ignore
    cacheExchange({
      keys: {
        Usuario: (data: any) => data.usuario,
        QueryCaixaResponse: (data: any) => hashCode(JSON.stringify(data)),
        QueryBancoResponse: (data: any) => hashCode(JSON.stringify(data)),
      },
      updates: {
        Mutation: {
          logout: (result, args, cache, info) => {
            console.log("updating logout");
            betterUpdateQuery<LogoutMutation, MeQuery>(
              cache,
              { query: MeDocument },
              result,
              () => ({ me: null })
            );
          },
          login: (result, args, cache, info) => {
            betterUpdateQuery<LoginMutation, MeQuery>(
              cache,
              { query: MeDocument },
              result,
              (res, query) => {
                console.log("logging in", res);
                if (!res.login.usuario) {
                  return query;
                } else {
                  return {
                    me: res.login,
                  };
                }
              }
            );
          },
        },
      },
    }),
    errorExchange,
    fetchExchange,
  ],
});
