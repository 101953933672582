import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useFecharCaixaMutation } from "../generated/graphql";
import NumberInput from "./NumberInput";

export type FecharCaixaModalProps = {
  caixa: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const FecharCaixaModal: React.FC<FecharCaixaModalProps> = ({
  caixa,
  ...props
}) => {
  const [saldo, setSaldo] = useState(0);
  const [, fecharCaixa] = useFecharCaixaMutation();
  const toast = useToast();

  const onFecharCaixa = async () => {
    const { error } = await fecharCaixa({ caixaid: caixa, saldo });

    if (error) {
      toast({
        title: "Erro ao fechar caixa",
        description: error.message.replace("[GraphQL]", ""),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setSaldo(0);

    props.onClose();
    typeof props.onSuccess === "function" && props.onSuccess();
  };

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Fechar Caixa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Informe o valor de dinheiro em caixa.</Text>
          <NumberInput
            name="saldo"
            value={saldo}
            onChange={(saldo) => setSaldo(saldo)}
            placeholder="Saldo em caixa"
            label="Saldo ($)"
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onFecharCaixa}>
            Concluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FecharCaixaModal;
