import { Heading, Image, Text, useToast } from "@chakra-ui/react";
import { faCopy, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "@reach/router";
import React, { useCallback, useEffect, useState } from "react";
import { useCriarPixImediatoMutation } from "../generated/graphql";
import { useGetQueryParam } from "../utils/useGetQueryParam";
import qrcode from "qrcode";

function b64toBlob(b64Data, contentType = null, sliceSize = null) {
  contentType = contentType || "image/png";
  sliceSize = sliceSize || 512;
  let byteCharacters = atob(b64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const MostraPix: React.FC<RouteComponentProps> = () => {
  const id = useGetQueryParam("id") as string;
  const [, criarPixImediato] = useCriarPixImediatoMutation();
  const [fetching, setFetching] = useState(false);
  const [qrCodeB64, setQrCodeB64] = useState("");
  const [textCopy, setTextCopy] = useState("");
  const toast = useToast();

  const handleImgCopyToClipboard = async () => {
    try {
      if ("clipboard" in navigator && qrCodeB64) {
        const blob = b64toBlob(qrCodeB64, "image/png");
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);
        toast({
          title: "QRCode copiado",
          description: `QRCode copiado`,
          status: "success",
        });
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  const handleTxtCopyToClipboard = async () => {
    try {
      if ("clipboard" in navigator && textCopy) {
        await navigator.clipboard.writeText(textCopy);
        toast({
          title: "Copia-e-cola copiado",
          description: `Copia-e-cola copiado`,
          status: "success",
        });
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  const handleEmitirPix = useCallback(
    async (id) => {
      setFetching(true);
      try {
        const { error, data } = await criarPixImediato({ id: parseInt(id) });
        if (error) {
          console.log("ocorrido erro", error);
          throw error;
        }

        const b64qrcode = await qrcode.toDataURL(
          data.criarPixImediato.copiaECola
        );
        setQrCodeB64(b64qrcode);
        setTextCopy(data.criarPixImediato.copiaECola);
      } catch (error) {
        console.log("error ocurred", error);
        let errMsg = JSON.stringify(error);
        if (error.message) {
          errMsg = error.message;
        }
        toast({
          title: "Erro ao registrar pix",
          description: `Cobranca [${id}]:${errMsg}`,
          status: "error",
        });
      }
      setFetching(false);
    },
    [criarPixImediato, toast]
  );

  useEffect(() => {
    handleEmitirPix(id);
  }, [id, handleEmitirPix]);

  if (fetching) {
    return (
      <>
        <Text>Seu QRCode esta sendo preparado</Text>
        <FontAwesomeIcon icon={faSync} spin />
      </>
    );
  }

  return (
    <>
      <Heading>QrCode do PIX</Heading>
      <Text>Leia no app do seu banco o QRCode abaixo</Text>
      <FontAwesomeIcon
        icon={faCopy}
        style={{ display: "inline" }}
        onClick={() => handleImgCopyToClipboard()}
      />
      <Image display="inline" src={`data:image/png;base64,${qrCodeB64}`} />
      <Text>
        <FontAwesomeIcon
          icon={faCopy}
          onClick={() => handleTxtCopyToClipboard()}
        />{" "}
        URL Copia-e-cola: {textCopy}
      </Text>
    </>
  );
};
