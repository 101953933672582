import { Button } from "@chakra-ui/button";
import { Flex, Grid, GridItem, Heading } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
} from "@chakra-ui/react";
import { subYears } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CheckField from "../../components/CheckField";
import DateSelector from "../../components/DateComponents/DateSelector";
import InputField from "../../components/InputField";
import LoadingWheel from "../../components/LoadingWheel";
import SelectField from "../../components/SelectField";
import {
  ClienteInput,
  useClienteQuery,
  useCreateClienteMutation,
  useUpdateClienteMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";
import ContratosByCliente from "../../components/ContratosByCliente";
import UfSelectorField from "../../components/UfSelectorField";
import CitySelectorField from "../../components/CitySelectorField";
import CobrancasByCliente from "../../components/CobrancasByCliente";
import ChamadosByCliente from "../../components/ChamadosByCliente";
import { useNavigate } from "react-router";

const novoCliente: ClienteInput = {
  nome: "",
  tipo: "FISICO",
  isncNacional: "",
  isncEstadual: "",
  dn: subYears(new Date(), 18),
  email: "",
  logradouro: "",
  numero: "",
  bairro: "",
  complemento: "",
  cep: "",
  cidade: "",
  uf: "",
  cmun: "",
  telefone1: "",
  telefone2: "",
  telefone3: "",
  obs: "",
  isActive: true,
};

const ClienteForm: React.FC<{}> = (props) => {
  useIsAuth();
  const id = parseInt(useGetQueryParam("id") as string);
  const [{ fetching, data }] = useClienteQuery({
    variables: { id: Number.isInteger(id) ? id : -1 },
  });
  const [, createMutation] = useCreateClienteMutation();
  const [, updateMutation] = useUpdateClienteMutation();
  const navigate = useNavigate();

  const [updateMsg, setUpdateMsg] = useState("");

  console.log("data", data);
  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Flex mt="2em" direction="column" flexGrow={1}>
        <Heading>{data?.cliente ? "Editar" : "Novo"} Cliente</Heading>

        {!!updateMsg && (
          <Alert status="success">
            <AlertIcon />
            <AlertDescription>{updateMsg}</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setUpdateMsg("")}
            />
          </Alert>
        )}

        <Formik
          initialValues={data?.cliente ? data.cliente : novoCliente}
          onSubmit={async (values, { setErrors }) => {
            console.log("creating cliente", values);
            if (data?.cliente) {
              /// é alteracao
              const updtData = { ...values };
              // @ts-ignore
              delete updtData.__typename;

              const { error, data } = await updateMutation({
                cliente: updtData,
              });

              if (error) {
                setErrors({
                  isActive: error.message.replace("[GraphQL]", ""),
                });
                return;
              }

              if (data.updateCliente.errors?.length > 0) {
                setErrors(errorsToFormik(data.updateCliente.errors));
              }

              setUpdateMsg("Cliente salvo com sucesso!");
            } else {
              // é inclusão
              const { error, data } = await createMutation({ cliente: values });

              if (error) {
                setErrors({
                  isActive: error.message.replace("[GraphQL]", ""),
                });
                return;
              }

              if (data.createCliente.errors?.length > 0) {
                setErrors(errorsToFormik(data.createCliente.errors));
                return;
              }

              navigate(`/clientes/${data.createCliente.cliente.id}`);
            }
          }}
        >
          {(props) => (
            <Form>
              <Grid gap={6} templateColumns="repeat(6, 1fr)">
                <GridItem colSpan={6}>
                  <SelectField
                    name="tipo"
                    label="Tipo"
                    placeholder="Selecione um tipo"
                  >
                    <option value="FISICO">Pessoa Fisica</option>
                    <option value="JURIDICO">Pessoa Juridica</option>
                  </SelectField>
                </GridItem>

                <GridItem colSpan={6}>
                  <InputField
                    name="nome"
                    label="Nome Completo"
                    placeholder="Nome Completo"
                  />
                </GridItem>
                <GridItem colSpan={[6, 3]}>
                  <InputField
                    name="isncNacional"
                    label={props.values.tipo === "FISICO" ? "CPF" : "CNPJ"}
                    mask={
                      props.values.tipo === "FISICO"
                        ? "999.999.999-99"
                        : "99.999.999/9999-99"
                    }
                  />
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <InputField
                    name="isncEstadual"
                    label={props.values.tipo === "FISICO" ? "RG" : "IE"}
                    mask="99999999999999999"
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <DateSelector name="dn" label="Data de Nascimento" />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <UfSelectorField
                    name="uf"
                    label="UF"
                    placeholder="Selecione"
                  />
                </GridItem>
                <GridItem colSpan={[6, 4]}>
                  <CitySelectorField
                    name="cidade"
                    UF={props.values.uf}
                    label="Cidade"
                    onCitySelect={(city) =>
                      props.setFieldValue("cmun", `${city.IBGE7}`)
                    }
                    placeholder="Cidade"
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <InputField
                    name="cmun"
                    label="Cód IBGE Município"
                    mask="9999999"
                    placeholder="000000"
                  />
                </GridItem>
                <GridItem colSpan={[6, 5]}>
                  <InputField name="logradouro" label="Logradouro" />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <InputField name="numero" label="Numero" />
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <InputField name="bairro" label="Bairro" />
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <InputField name="complemento" label="Complemento" />
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <InputField name="cep" mask="99999-999" label="CEP" />
                </GridItem>
                <GridItem colSpan={6}>
                  <InputField name="email" label="email" />
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <InputField
                    name="telefone1"
                    mask="(99)99999-9999"
                    label="Telefone 1"
                  />
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <InputField
                    name="telefone2"
                    mask="(99)99999-9999"
                    label="Telefone 2"
                  />
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <InputField
                    name="telefone3"
                    mask="(99)99999-9999"
                    label="Telefone 3"
                  />
                </GridItem>
                <GridItem colSpan={6}>
                  <InputField name="obs" textarea label="Observações" />
                </GridItem>
                <GridItem colSpan={6}>
                  <CheckField name="isActive" label="Ativo" />
                </GridItem>
              </Grid>

              <Button
                type="submit"
                mt="1em"
                colorScheme="teal"
                isLoading={props.isSubmitting}
              >
                Salvar
              </Button>
            </Form>
          )}
        </Formik>

        {data && data.cliente && (
          <>
            <ContratosByCliente clienteid={data?.cliente.id} />
            <CobrancasByCliente clienteid={data?.cliente.id} />
            <ChamadosByCliente clienteid={data?.cliente.id} />
          </>
        )}
      </Flex>
    </>
  );
};

export default ClienteForm;
