import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  faList,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Banco,
  useBancosQuery,
  useDeleteBancoMutation,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";
import { Link } from "react-router-dom";

const Bancos: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching }, query] = useBancosQuery({
    variables: { page: page, filter },
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteBancoMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (banco: Banco) => {
    await deleteData({ id: banco.id });
    setDeleteSuccessMsg(`Banco ${banco.nome} foi removido com sucesso!`);
    query();
  };
  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Banco>[] = [
    {
      label: "Nome",
      render: (banco) => banco.nome,
      wrapped: true,
    },
    {
      label: "Código",
      render: (banco) => banco.codigo,
      wrapped: true,
    },
    {
      label: "Agencia",
      render: (banco) => banco.agencia,
      wrapped: true,
    },
    {
      label: "Conta",
      render: (banco) => banco.conta,
      wrapped: true,
    },
    {
      label: " ",
      render: (banco) => (
        <>
          <Link to={`/bancos/${banco.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <Link to={`/lancamentosBanco/${banco.id}`}>
            <Button colorScheme="green" type="button">
              <FontAwesomeIcon icon={faList} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(banco as Banco)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Bancos</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/bancos/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo banco
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.bancos.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.bancos.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Bancos;
