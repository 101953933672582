import {
  Text,
  Image,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Link,
} from "@chakra-ui/react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import {
  Cobranca,
  useCobrancaQuery,
  useCriarPixImediatoMutation,
} from "../../generated/graphql";
import qrcode from "qrcode";

function b64toBlob(b64Data, contentType = null, sliceSize = null) {
  contentType = contentType || "image/png";
  sliceSize = sliceSize || 512;
  let byteCharacters = atob(b64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

type BCriarPixImediatoProps = {
  cobranca: Cobranca;
} & ButtonProps;

export const BCriarPixImediato: React.FC<BCriarPixImediatoProps> = ({
  onClick,
  cobranca,
  children,
  ...props
}) => {
  const [, criarPixImediato] = useCriarPixImediatoMutation();
  const [fetching, setFetching] = useState(false);
  const [isQrCodeOpen, setQrCodeOpen] = useState(false);
  const [qrCodeB64, setQrCodeB64] = useState("");
  const [textCopy, setTextCopy] = useState("");
  const toast = useToast();

  const handleImgCopyToClipboard = async () => {
    try {
      if ("clipboard" in navigator && qrCodeB64) {
        const blob = b64toBlob(qrCodeB64, "image/png");
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);
        toast({
          title: "QRCode copiado",
          description: `QRCode copiado`,
          status: "success",
        });
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  const handleTxtCopyToClipboard = async () => {
    try {
      if ("clipboard" in navigator && textCopy) {
        await navigator.clipboard.writeText(textCopy);
        toast({
          title: "Copia-e-cola copiado",
          description: `Copia-e-cola copiado`,
          status: "success",
        });
      }
    } catch (error) {
      console.error("error on copy", error);
    }
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [{ data }, requery] = useCobrancaQuery({
    variables: {
      id: cobranca.id,
    },
  });

  const tentaConfirmaPagamento = async () => {
    try {
      await sleep(10000);
      for (const _i of [1, 2, 3, 4, 5]) {
        console.log("Tentativa de ver pagamento", _i);
        requery({
          requestPolicy: "network-only",
        });
        await sleep(500);
        console.log("data", data.cobranca);
        if (data.cobranca?.isPago) {
          // Pago agora
          setQrCodeOpen(false);
          toast({
            title: "PIX pago",
            description: `Pagamento do PIX foi confirmado`,
            status: "success",
            duration: 5000,
          });
          break;
        }
        await sleep(5000);
      }
    } catch (error) {
      console.error("erro ao tentar confirmar pagamento", error);
    }
  };

  const handleEmitirPix = async () => {
    setFetching(true);
    try {
      const { error, data } = await criarPixImediato({ id: cobranca.id });
      if (error) {
        console.log("ocorrido erro", error);
        throw error;
      }
      const b64qrcode = await qrcode.toDataURL(
        data.criarPixImediato.copiaECola
      );

      setQrCodeB64(b64qrcode);
      console.log(data.criarPixImediato);
      setTextCopy(data.criarPixImediato.copiaECola);
      setQrCodeOpen(true);
      tentaConfirmaPagamento();
    } catch (error) {
      console.log("error ocurred", error);
      let errMsg = JSON.stringify(error);
      if (error.message) {
        errMsg = error.message;
      }
      toast({
        title: "Erro ao registrar pix",
        description: `Cobranca [${cobranca.id}]:${errMsg}`,
        status: "error",
      });
    }
    setFetching(false);
  };

  return (
    <>
      <Modal isOpen={isQrCodeOpen} onClose={() => setQrCodeOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>QrCode do PIX</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Leia no app do seu banco o QRCode abaixo</Text>
            <Image src={`${qrCodeB64}`} />
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() => handleImgCopyToClipboard()}
            />
            <Text>
              <FontAwesomeIcon
                icon={faCopy}
                onClick={() => handleTxtCopyToClipboard()}
              />{" "}
              URL Copia-e-cola: {textCopy}
            </Text>
            <Link
              href={`whatsapp://send?text=Abra o link para seu código PIX: https://bug.gabrimar.com.br/pix/${cobranca.id}`}
            >
              <FaWhatsapp style={{ display: "inline" }} /> Compartilhar via
              whatsapp
            </Link>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button {...props} isLoading={fetching} onClick={handleEmitirPix}>
        {children}
      </Button>
    </>
  );
};
