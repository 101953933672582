import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  faPen,
  faPlus,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Estacao,
  TipoEstacao,
  useDeleteEstacaoMutation,
  useEstacaosQuery,
  useSyncEstacaoMutation,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const Estacaos: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching }, query] = useEstacaosQuery({
    variables: { page: page, filter },
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteEstacaoMutation();
  const [{ fetching: syncLoading }, syncEstacao] = useSyncEstacaoMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  if (fetching) {
    return <LoadingWheel />;
  }

  const onDeleteConfirm = async (estacao: Estacao) => {
    await deleteData({ id: estacao.id });
    setDeleteSuccessMsg(`Estação ${estacao.nome} foi removido com sucesso!`);
    query();
  };

  const headers: TableHeaders<Estacao>[] = [
    {
      label: "Nome",
      render: (estacao) => estacao.nome,
      wrapped: true,
    },
    {
      label: "IP",
      render: (estacao) => estacao.ip,
      wrapped: true,
    },
    {
      label: "Usuario",
      render: (estacao) => estacao.usuario,
      wrapped: true,
    },
    {
      label: "Tipo",
      render: (estacao) =>
        estacao.tipo === TipoEstacao.Mikrotik ? "Mikrotik" : "Ubiquiti",
      wrapped: true,
    },
    {
      label: " ",
      render: (estacao) => (
        <>
          <Link to={`/estacoes/${estacao.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(estacao as Estacao)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
          <ConfirmButton
            isLoading={syncLoading}
            onClick={() =>
              syncEstacao({
                id: estacao.id,
              })
            }
            colorScheme="purple"
            title="Adicionar todos os usuarios no ponto"
          >
            <FontAwesomeIcon icon={faSync} />
          </ConfirmButton>
        </>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Estações</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/estacoes/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Nova estação
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.estacaos.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.estacaos.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Estacaos;
