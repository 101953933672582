import React from "react";
import { Button } from "@chakra-ui/button";
import { Flex, Grid, GridItem } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { useCreateLancamentoBancoMutation } from "../generated/graphql";
import errorsToFormik from "../utils/errorsToFormik";
import InputField from "./InputField";
import InputNumber from "./InputNumber";
import SelectField from "./SelectField";
import BancoSelectorField from "./BancoSelectorField";

export interface CreateLancamentoBancoProps {
  banco: number;
  onAddSuccess?: () => void;
}

const novoLancamento = (banco) => ({
  descricao: "",
  valor: 0,
  tipo: 1,
  data: new Date(),
  banco,
  bancoDestino: null,
});

const CreateLancamentoBanco: React.FC<CreateLancamentoBancoProps> = ({
  banco,
  onAddSuccess,
}) => {
  const [, createLancamento] = useCreateLancamentoBancoMutation();
  const toast = useToast();

  return (
    <Formik
      initialValues={novoLancamento(banco)}
      onSubmit={async (values, { setErrors, resetForm }) => {
        const { error, data } = await createLancamento({
          lancamentoBanco: {
            descricao: values.descricao,
            valor:
              values.tipo * 1 === 2
                ? values.valor * -1
                : values.valor * values.tipo,
            data: new Date(),
            banco: values.banco,
            isTransferencia: values.tipo * 1 === 2,
            bancoDestino: values.bancoDestino && values.bancoDestino.id,
          },
        });

        if (error) {
          setErrors({
            descricao: error.message.replace("[GraphQL]", ""),
          });
          return;
        }

        if (data.createLancamentobanco.errors?.length > 0) {
          setErrors(errorsToFormik(data.createLancamentobanco.errors));
          return;
        }

        typeof onAddSuccess === "function" && onAddSuccess();

        toast({
          title: "Lançamento Feito",
          description: "Lançamento criado com sucesso!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        resetForm();
      }}
    >
      {(props) => (
        <Form>
          <Grid
            gap={[3, 6]}
            templateColumns={["repeat(3, 1fr)", "repeat(6, 1fr)"]}
          >
            <GridItem colSpan={[3, props.values.tipo * 1 === 2 ? 2 : 3]}>
              <InputField
                label="Descrição"
                name="descricao"
                autoFocus
                placeholder="Descrição"
              />
            </GridItem>
            <GridItem colSpan={[3, 1]}>
              <SelectField
                label="Tipo"
                placeholder="Selecione o Tipo"
                name="tipo"
              >
                <option value={1}>Entrada</option>
                <option value={-1}>Saída</option>
                <option value={2}>Transferencia</option>
              </SelectField>
            </GridItem>
            {props.values.tipo * 1 === 2 && (
              <GridItem colSpan={[3, 1]}>
                <BancoSelectorField
                  label="Banco de destino"
                  placeholder="Selecione um banco"
                  name="bancoDestino"
                />
              </GridItem>
            )}
            <GridItem colSpan={[3, 1]}>
              <InputNumber
                label="Valor"
                name="valor"
                placeholder="Valor"
                decimalPlaces={2}
              />
            </GridItem>
            <GridItem colSpan={[3, 1]}>
              <Flex
                minH="100%"
                alignItems="flex-end"
                justifyContent="flex-start"
              >
                <Button colorScheme="teal" type="submit">
                  Incluir
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateLancamentoBanco;
