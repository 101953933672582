import { View, Text, Font, StyleSheet } from "@react-pdf/renderer";
import { LogoSicredi } from "./LogoSicredi";
import { linhaDigitavel } from "../utils/linhaDigitavel";
import { SvgCodebar } from "./SvgCodebar";
import { calcNossoNumero } from "../utils/calcNossoNumero";
import { formatNumber } from "../utils/formatNumber";
import formatDate from "../utils/formatDate";
import modulo10 from "../utils/modulo10";
import { LogoSicoob } from "./LogoSicoob";
import React from "react";

const codBancoSuportado = ["748", "756"];

const isBancoSuportado = (banco: string) =>
  codBancoSuportado.indexOf(banco) !== -1;

Font.register({
  family: "Source Sans Pro",
  fontStyle: "normal",
  src: "/fonts/SourceSansPro-Regular.ttf",
});
Font.register({
  family: "Source Sans Pro",
  fontStyle: "bold",
  src: "/fonts/SourceSansPro-Bold.ttf",
});

const boletoStyles = StyleSheet.create({
  boxRecibo: {
    flexDirection: "column",
    fontSize: "7pt",
    fontWeight: "bold",
    borderStyle: "solid",
    borderColor: "#000",
    paddingHorizontal: "2mm",
    paddingTop: "1pt",
    paddingBottom: "1pt",
  },
  borderT: {
    borderTopWidth: 1,
  },
  borderL: {
    borderLeftWidth: 1,
  },
  borderR: {
    borderRightWidth: 1,
  },
  borderB: {
    borderBottomWidth: 1,
  },
  borderRTL: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  borderTL: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
  },
  textHeading: {
    fontSize: "7pt",
  },
  textValue: {
    fontStyle: "bold",
    fontSize: "7pt",
  },
  textValueR: {
    fontStyle: "bold",
    fontSize: "7pt",
    textAlign: "right",
  },
});

function montaLinha(linhaDigitavel: string) {
  let p1 = linhaDigitavel.substr(0, 4);
  let p2 = linhaDigitavel.substr(19, 5);
  let p3 = `${modulo10(`${p1}${p2}`)}`;
  let p4 = `${p1}${p2}${p3}`;
  let p5 = p4.substr(0, 5);
  let p6 = p4.substr(5);
  const campo1 = `${p5}.${p6}`;

  p1 = linhaDigitavel.substr(24, 10);
  p2 = `${modulo10(p1)}`;
  p3 = `${p1}${p2}`;
  p4 = p3.substr(0, 5);
  p5 = p3.substr(5);
  const campo2 = `${p4}.${p5}`;

  p1 = linhaDigitavel.substr(34, 10);
  p2 = `${modulo10(p1)}`;
  p3 = `${p1}${p2}`;
  p4 = p3.substr(0, 5);
  p5 = p3.substr(5);
  const campo3 = `${p4}.${p5}`;

  const campo4 = linhaDigitavel.substr(4, 1);

  const campo5 = linhaDigitavel.substr(5, 14);

  return `${campo1} ${campo2} ${campo3} ${campo4} ${campo5}`;
}

export interface BoletoProps {
  nomeEmpresa: string;
  razaoEmpresa: string;
  codigoBanco: string;
  agenciaBanco: string;
  codigoModalidade: string;
  carteiraBanco: string;
  dvAgenciaBanco: string;
  codigoClienteBanco: string;
  dvCodigoClienteBanco: string;
  numeroDocumento: string;
  valorDocumento: number;
  vencimento: Date;
  dataProcessamento: Date;
  nomePagador: string;
  tipoPagador: string;
  cpfcnpjPagador: string;
  enderecoPagador: string;
  cidadeUfPagador: string;
  cepPagador: string;
  instrucoes1?: string;
  instrucoes2?: string;
  instrucoes3?: string;
  instrucoes4?: string;
  referente?: string;
  borderTop?: boolean;
}

function formataNossoNumero(nossoNumero: string, codigoBanco: string) {
  if (["748", "756"].indexOf(codigoBanco) === -1) {
    return nossoNumero;
  }
  return `${nossoNumero.substr(0, 2)}/${nossoNumero.substr(
    2,
    nossoNumero.length - 3
  )}-${nossoNumero.substr(-1)}`;
}

export const Boleto: React.FC<BoletoProps> = ({
  nomeEmpresa,
  razaoEmpresa,
  codigoBanco,
  agenciaBanco,
  codigoModalidade,
  carteiraBanco,
  dvAgenciaBanco,
  codigoClienteBanco,
  dvCodigoClienteBanco,
  numeroDocumento,
  valorDocumento,
  vencimento,
  dataProcessamento,
  nomePagador,
  tipoPagador,
  cpfcnpjPagador,
  enderecoPagador,
  cidadeUfPagador,
  cepPagador,
  instrucoes1,
  instrucoes2,
  instrucoes3,
  instrucoes4,
  referente,
  borderTop,
}) => {
  const barras = linhaDigitavel(
    numeroDocumento,
    agenciaBanco,
    carteiraBanco,
    dvCodigoClienteBanco,
    codigoClienteBanco,
    valorDocumento,
    vencimento,
    codigoBanco,
    codigoModalidade,
    dataProcessamento
  );
  const linha = montaLinha(barras);

  const nossoNumero = calcNossoNumero(
    numeroDocumento,
    agenciaBanco,
    dvCodigoClienteBanco,
    codigoClienteBanco,
    codigoBanco,
    dataProcessamento
  );

  const bancoSuportado = isBancoSuportado(codigoBanco);
  console.log("criando boleto", nossoNumero);

  return (
    <View
      wrap={false}
      style={{
        paddingTop: "3mm",
        paddingLeft: "1cm",
        paddingRight: "0.4cm",
        flexDirection: "row",
        width: "100%",
        paddingBottom: "3mm",
        borderStyle: "dashed",
        borderBottomWidth: "1px",
        borderColor: "#000",
        borderTopWidth: borderTop ? 1 : 0,
        marginTop: borderTop ? "5mm" : 0,
      }}
    >
      <View style={{ flexDirection: "column", width: "7cm" }}>
        <View
          style={{
            flexDirection: "row",
            fontSize: "8pt",
            fontStyle: "bold",
            fontFamily: "Source Sans Pro",
            justifyContent: "space-between",
            marginRight: "2mm",
            marginBottom: "3pt",
          }}
        >
          <Text>Recibo do Pagador</Text>
          <Text>{nomeEmpresa}</Text>
        </View>
        <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
          <Text style={boletoStyles.textHeading}>Beneficiário</Text>
          <Text style={boletoStyles.textValue}>{razaoEmpresa}</Text>
        </View>
        {bancoSuportado && (
          <View
            style={{
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
              <Text style={boletoStyles.textHeading}>
                Agencia/Cód. do Beneficiário
              </Text>
              <Text style={boletoStyles.textValue}>
                {agenciaBanco}.{dvCodigoClienteBanco}.{codigoClienteBanco}
              </Text>
            </View>
            <View
              style={[
                boletoStyles.boxRecibo,
                boletoStyles.borderT,
                boletoStyles.borderL,
                { flexGrow: 3 },
              ]}
            >
              <Text style={boletoStyles.textHeading}>Espécie</Text>
              <Text style={boletoStyles.textValue}>R$</Text>
            </View>
          </View>
        )}
        {bancoSuportado && (
          <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
            <Text style={boletoStyles.textHeading}>Nosso numero</Text>
            <Text style={boletoStyles.textValue}>
              {formataNossoNumero(nossoNumero, codigoBanco)}
            </Text>
          </View>
        )}
        <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
          <Text style={boletoStyles.textHeading}>Numero do documento</Text>
          <Text style={boletoStyles.textValue}>{numeroDocumento}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
          }}
        >
          <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
            <Text style={boletoStyles.textHeading}>Valor do documento</Text>
            <Text style={boletoStyles.textValue}>
              {formatNumber(valorDocumento, 2, true)}
            </Text>
          </View>
          <View
            style={{
              ...boletoStyles.boxRecibo,
              ...boletoStyles.borderTL,
              flexGrow: 3,
            }}
          >
            <Text style={boletoStyles.textHeading}>Vencimento</Text>
            <Text style={boletoStyles.textValue}>{formatDate(vencimento)}</Text>
          </View>
        </View>
        <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
          <Text style={boletoStyles.textHeading}>Descontos (-)</Text>
          <Text style={boletoStyles.textValue}> </Text>
        </View>
        <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
          <Text style={boletoStyles.textHeading}>Mora/Multa (+)</Text>
          <Text style={boletoStyles.textValue}> </Text>
        </View>
        <View style={[boletoStyles.boxRecibo, boletoStyles.borderTL]}>
          <Text style={boletoStyles.textHeading}>Valor Cobrado (=)</Text>
          <Text style={boletoStyles.textValue}> </Text>
        </View>
        <View
          style={[
            boletoStyles.boxRecibo,
            boletoStyles.borderTL,
            boletoStyles.borderB,
          ]}
        >
          <Text style={boletoStyles.textHeading}>Pagador</Text>
          <Text style={boletoStyles.textValue}>{nomePagador}</Text>
        </View>
        {bancoSuportado && (
          <View
            style={[
              {
                borderStyle: "solid",
                borderColor: "#000",
                borderTopWidth: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                margin: "2pt",
                alignItems: "center",
              },
              boletoStyles.textValue,
            ]}
          >
            <Text>Autenticação Mecanica</Text>
          </View>
        )}
      </View>

      <View
        style={{
          flexDirection: "column",
          borderStyle: "solid",
          borderLeftWidth: "1px",
          borderLeftColor: "#000",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {bancoSuportado && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "0.2cm",
              fontSize: "10pt",
              width: "100%",
            }}
          >
            {codigoBanco === "748" && <LogoSicredi />}
            {codigoBanco === "756" && <LogoSicoob />}
            <Text
              style={{
                borderStyle: "solid",
                borderColor: "#000",
                borderLeftWidth: 1,
                borderRightWidth: 1,
                paddingHorizontal: "5pt",
              }}
            >
              {codigoBanco === "748" && "748-X"}
              {codigoBanco === "756" && "756"}
            </Text>
            <Text>{linha}</Text>
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            width: "100%",
          }}
        >
          <View style={{ flexDirection: "column", flexGrow: 10 }}>
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderT]}>
              <Text style={boletoStyles.textHeading}>Local de pagamento</Text>
              <Text style={boletoStyles.textValue}>
                {bancoSuportado
                  ? `Pagável em qualquer banco, exceto em casas lotericas.`
                  : `Pagável somente na ${nomeEmpresa}`}
              </Text>
            </View>
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderT]}>
              <Text style={boletoStyles.textHeading}>Beneficiário</Text>
              <Text style={boletoStyles.textValue}>{razaoEmpresa}</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={[
                  boletoStyles.boxRecibo,
                  boletoStyles.borderT,
                  { flexGrow: 1 },
                ]}
              >
                <Text style={boletoStyles.textHeading}>Data do Documento</Text>
                <Text style={boletoStyles.textValue}>
                  {formatDate(dataProcessamento)}
                </Text>
              </View>
              <View
                style={[
                  boletoStyles.boxRecibo,
                  boletoStyles.borderTL,
                  { flexGrow: 1 },
                ]}
              >
                <Text style={boletoStyles.textHeading}>Nº do Documento</Text>
                <Text style={boletoStyles.textValue}>{numeroDocumento}</Text>
              </View>
              {bancoSuportado && (
                <View
                  style={[
                    boletoStyles.boxRecibo,
                    boletoStyles.borderTL,
                    { flexGrow: 1 },
                  ]}
                >
                  <Text style={boletoStyles.textHeading}>Espécie Doc.</Text>
                  <Text style={boletoStyles.textValue}>DSI</Text>
                </View>
              )}
            </View>
            {bancoSuportado && (
              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={[
                    boletoStyles.boxRecibo,
                    boletoStyles.borderT,
                    { flexGrow: 1 },
                  ]}
                >
                  <Text style={boletoStyles.textHeading}>Uso do Banco</Text>
                  <Text style={boletoStyles.textValue}> </Text>
                </View>
                <View
                  style={[
                    boletoStyles.boxRecibo,
                    boletoStyles.borderTL,
                    { flexGrow: 1 },
                  ]}
                >
                  <Text style={boletoStyles.textHeading}>Qtd. Moeda</Text>
                  <Text style={boletoStyles.textValue}> </Text>
                </View>
                <View
                  style={[
                    boletoStyles.boxRecibo,
                    boletoStyles.borderTL,
                    { flexGrow: 1 },
                  ]}
                >
                  <Text style={boletoStyles.textHeading}>Espécie</Text>
                  <Text style={boletoStyles.textValue}>REAL</Text>
                </View>
              </View>
            )}
            <View
              style={[
                boletoStyles.boxRecibo,
                boletoStyles.borderT,
                { flexGrow: 1 },
              ]}
            >
              <Text style={boletoStyles.textHeading}>
                Instruções (Texto de responsabilidade do cedente):
              </Text>
              <Text style={boletoStyles.textValue}>
                {instrucoes1 ? instrucoes1 : " "}
              </Text>
              <Text style={boletoStyles.textValue}>
                {instrucoes2 ? instrucoes2 : " "}
              </Text>
              {bancoSuportado && (
                <Text style={boletoStyles.textValue}>
                  {instrucoes3 ? instrucoes3 : " "}
                </Text>
              )}
              {bancoSuportado && (
                <Text style={boletoStyles.textValue}>
                  {instrucoes4 ? instrucoes4 : " "}
                </Text>
              )}
              <View
                style={{ flexDirection: "row", flexShrink: 1, width: "9cm" }}
              >
                <Text style={boletoStyles.textHeading}>
                  Referente: {referente ? referente : " "}
                </Text>
              </View>
            </View>
            <View
              style={[
                boletoStyles.boxRecibo,
                boletoStyles.borderT,
                { flexGrow: 1 },
              ]}
            >
              <Text style={boletoStyles.textHeading}>Pagador</Text>
              <Text style={boletoStyles.textValue}>
                {nomePagador} - {tipoPagador === "JURIDICO" ? `CNPJ` : "CPF"}:{" "}
                {cpfcnpjPagador}
              </Text>
              <Text style={boletoStyles.textValue}>{enderecoPagador}</Text>
              <Text style={boletoStyles.textValue}>
                {cidadeUfPagador} - CEP: {cepPagador}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              width: "5cm",
              height: "100%",
            }}
          >
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
              <Text style={boletoStyles.textHeading}>Vencimento</Text>
              <Text style={boletoStyles.textValueR}>
                {formatDate(vencimento)}
              </Text>
            </View>
            {bancoSuportado && (
              <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
                <Text style={boletoStyles.textHeading}>
                  Agencia/Código do Beneficiário
                </Text>
                <Text style={boletoStyles.textValueR}>
                  {agenciaBanco}.{dvCodigoClienteBanco}.{codigoClienteBanco}
                </Text>
              </View>
            )}
            {bancoSuportado && (
              <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
                <Text style={boletoStyles.textHeading}>Nosso numero</Text>
                <Text style={boletoStyles.textValue}>
                  {formataNossoNumero(nossoNumero, codigoBanco)}
                </Text>
              </View>
            )}
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
              <Text style={boletoStyles.textHeading}>Valor do Documento</Text>
              <Text style={boletoStyles.textValueR}>
                {formatNumber(valorDocumento, 2, true)}
              </Text>
            </View>
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
              <Text style={boletoStyles.textHeading}>Descontos (-)</Text>
              <Text style={boletoStyles.textValueR}> </Text>
            </View>
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
              <Text style={boletoStyles.textHeading}>Mora/Multa (+)</Text>
              <Text style={boletoStyles.textValueR}> </Text>
            </View>
            <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
              <Text style={boletoStyles.textHeading}>Valor Cobrado (=)</Text>
              <Text style={boletoStyles.textValueR}> </Text>
              {!bancoSuportado && (
                <>
                  <Text style={boletoStyles.textValueR}> </Text>
                  <Text style={boletoStyles.textValueR}> </Text>
                  <Text style={boletoStyles.textValueR}> </Text>
                  <Text style={boletoStyles.textValueR}> </Text>
                  <Text style={boletoStyles.textValueR}> </Text>
                </>
              )}
            </View>
            {bancoSuportado && (
              <View style={[boletoStyles.boxRecibo, boletoStyles.borderRTL]}>
                <Text style={boletoStyles.textHeading}>
                  Autenticação Mecânica
                </Text>
                <Text style={boletoStyles.textValueR}> </Text>
                <Text style={boletoStyles.textValueR}> </Text>
                <Text style={boletoStyles.textValueR}> </Text>
              </View>
            )}
          </View>
        </View>
        <View
          style={[
            boletoStyles.boxRecibo,
            boletoStyles.borderT,
            { width: "100%" },
          ]}
        >
          <SvgCodebar codigo={barras} />
        </View>
      </View>
    </View>
  );
};
