import React from "react";
import { Button, ButtonGroup } from "@chakra-ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface PaginationProps {
  totalPages: number;
  page: number;
  onPageChange: Function;
}

const PaginationButtons: React.FC<PaginationProps> = (props) => {
  if (props.totalPages <= 1 || Number.isNaN(props.totalPages)) {
    return null;
  }

  let pagesArray = new Array(props.totalPages);
  pagesArray = pagesArray.fill(0, 0, props.totalPages).map((_, i) => i + 1);
  if (props.totalPages > 15) {
    if (props.page < 11) {
      pagesArray = new Array(15);
      pagesArray = pagesArray.fill(0, 0, 15).map((_, i) => i + 1);
      let lastPages = props.totalPages - 2;
      for (let i = 12; i < 15; i++) {
        pagesArray[i] = lastPages;
        lastPages++;
      }
      pagesArray[11] = "...";
    }
    if (props.page > props.totalPages - 10) {
      pagesArray = new Array(15);
      pagesArray = pagesArray
        .fill(0, 0, 15)
        .map((_, i) => props.totalPages + i - 14);
      for (let i = 0; i < 3; i++) {
        pagesArray[i] = i + 1;
      }
      pagesArray[3] = "...";
    }
    if (props.page > 10 && props.page < props.totalPages - 9) {
      pagesArray = new Array(15);
      for (let i = 0; i < 3; i++) {
        pagesArray[i] = i + 1;
      }
      pagesArray[3] = "...";
      let lastPages = props.totalPages - 2;
      for (let i = 12; i < 15; i++) {
        pagesArray[i] = lastPages;
        lastPages++;
      }
      pagesArray[11] = "...";
      let pageRange = props.page - 3;
      for (let i = 4; i < 11; i++) {
        pagesArray[i] = pageRange;
        pageRange++;
      }
    }
  }

  return (
    <ButtonGroup my="1em">
      <Button
        onClick={() => props.onPageChange(props.page - 1)}
        isDisabled={props.page === 1}
        colorScheme="cyan"
        type="button"
        size="sm"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      {pagesArray.map((p, i) => (
        <Button
          type="button"
          key={`${i}`}
          size="sm"
          colorScheme="cyan"
          onClick={() => props.onPageChange(p)}
          isDisabled={p === props.page || p === "..."}
        >
          {p}
        </Button>
      ))}
      <Button
        isDisabled={props.page === props.totalPages}
        type="button"
        colorScheme="cyan"
        onClick={() => props.onPageChange(props.page + 1)}
        size="sm"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </ButtonGroup>
  );
};

export default PaginationButtons;
