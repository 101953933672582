import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export interface FilterProps {
  value: string;
  zeroOnEnter?: boolean;
  onChange: Function;
  placeholder?: string;
}

const Filter: React.FC<FilterProps> = (props) => {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(props.value);
  }, [props.value]);
  const onSubmitText = (e) => {
    e.preventDefault();
    props.onChange(text);
    props.zeroOnEnter && setText("");
  };

  return (
    <form onSubmit={onSubmitText}>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          fontSize="1.2em"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          autoFocus
          name="filterText"
          type="text"
          placeholder={props.placeholder ? props.placeholder : "Pesquisar"}
          autoComplete="off"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </InputGroup>
    </form>
  );
};

export default Filter;
