import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { getDay, parseISO, subYears } from "date-fns";
import { Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import LoadingWheel from "../../components/LoadingWheel";
import {
  DateRangeInput,
  useEstatisticaClientesQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { useIsAuth } from "../../utils/useIsAuth";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const EstatisticaClientes: React.FC<{}> = () => {
  useIsAuth();

  const [periodo, setPeriodo] = React.useState({
    inicio: subYears(new Date(), 1),
    fim: new Date(),
  } as DateRangeInput);

  const [{ data, fetching, error }] = useEstatisticaClientesQuery({
    variables: {
      data: periodo,
    },
  });

  const planos = _.uniq(data?.estatisticas_clientes?.map((e) => e.plano));
  const filteredByMonth = _.groupBy(data?.estatisticas_clientes, (e) =>
    formatDate(e.dataInformacao, "MM/yyyy")
  );
  const chartLabels = Object.keys(filteredByMonth);

  const chartData = {
    labels: chartLabels,
    datasets: [
      ...planos.map((plano) => ({
        label: plano,
        data: chartLabels?.map(
          (mes) =>
            data?.estatisticas_clientes?.find(
              (e) =>
                e.plano === plano &&
                formatDate(e.dataInformacao, "MM/yyyy") === mes
            )?.qtdUsuarios || 0
        ),
      })),
      {
        label: "Total",
        data: chartLabels?.map((mes) =>
          planos
            .map(
              (plano) =>
                data?.estatisticas_clientes?.find(
                  (e) =>
                    e.plano === plano &&
                    formatDate(e.dataInformacao, "MM/yyyy") === mes
                )?.qtdUsuarios || 0
            )
            .reduce((p, c) => p + c, 0)
        ),
        backgroundColor: "#999999",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Clientes ativos mensalmente",
      },
    },
  };

  return (
    <>
      <Heading>Periodo</Heading>

      <Formik
        initialValues={periodo}
        onSubmit={(values) => {
          setPeriodo(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Vencimento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Vencimento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Confirmar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setPeriodo({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      {fetching && <LoadingWheel />}

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      <Line options={chartOptions} data={chartData} />
    </>
  );
};
