import { Font, Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { Nota } from "../generated/graphql";
import formatDate from "../utils/formatDate";
import { formatNumber } from "../utils/formatNumber";
import { LogoGabrimar } from "./LogoGabrimar";

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

export interface Nota21Props {
  nota: Nota;
}

const Nota21: React.FC<Nota21Props> = ({ nota }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        marginTop: "1cm",
        marginLeft: "1cm",
        marginRight: "1cm",
        width: "19cm",
        fontFamily: "Arial",
        height: "90%",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5mm",
          borderStyle: "solid",
          borderColor: "#000",
          borderWidth: 1,
        }}
      >
        <LogoGabrimar />
        <View
          style={{
            flexDirection: "column",
            paddingTop: "10pt",
          }}
        >
          <Text style={{ fontSize: "12pt", fontWeight: "bold" }}>
            N. G. B. PIRES &amp; CIA. LTDA. - ME
          </Text>
          <Text style={{ fontSize: "10pt" }}>CNPJ: 10.487.701/0001-50</Text>
          <Text style={{ fontSize: "10pt" }}>
            AV. DR. VACYR GONCALVEZ PEREIRA, 750-C
          </Text>
          <Text style={{ fontSize: "10pt" }}>CENTRO</Text>
          <Text style={{ fontSize: "10pt" }}>SERTANOPOLIS/PR</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            paddingTop: "22pt",
          }}
        >
          <Text style={{ fontSize: "10pt" }}>IE: 904.75452-82</Text>
          <Text style={{ fontSize: "10pt" }}>FONE: (43) 3232 1627</Text>
          <Text style={{ fontSize: "10pt" }}>CEP: 86170-000</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3mm",
          borderStyle: "solid",
          borderColor: "#000",
          borderWidth: 1,
          marginTop: "5mm",
        }}
      >
        <Text style={{ fontSize: "10pt" }}>Nota Fiscal de Comunicação</Text>
        <Text style={{ fontSize: "10pt" }}>Modelo {nota.modelo}</Text>
        <Text style={{ fontSize: "10pt" }}>
          Série: {nota.serie && nota.serie.codigo}
        </Text>
        <Text style={{ fontSize: "10pt" }}>
          Emissão: {formatDate(nota.emissao)}
        </Text>
        <Text style={{ fontSize: "10pt" }}>
          Ref.: {formatDate(nota.emissao, "MM/yy")}
        </Text>
        <Text style={{ fontSize: "10pt", fontWeight: "bold" }}>
          Número:{" "}
          {`${nota.numero}`
            .padStart(9, "0")
            .match(/.{1,3}/g)
            .join(".")}
        </Text>
      </View>
      <Text style={{ fontSize: "7pt", marginTop: "5mm" }}>
        Tomador de Serviços/Destinátario das Mercadorias
      </Text>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flexGrow: 7,
            justifyContent: "space-between",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>NOME/RAZÃO SOCIAL</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.nome}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 3,
            justifyContent: "space-between",
            marginLeft: -1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>CNPJ/CPF</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.isncNacional}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flexGrow: 5,
            justifyContent: "space-between",
            borderStyle: "solid",
            borderColor: "#000",
            marginTop: -1,
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>ENDEREÇO</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente &&
              `${nota.cliente.logradouro}, ${nota.cliente.numero}`}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 5,
            justifyContent: "space-between",
            marginLeft: -1,
            marginTop: -1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>BAIRRO</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.bairro}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "space-between",
            marginLeft: -1,
            marginTop: -1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>CEP</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.cep}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flexGrow: 3,
            justifyContent: "space-between",
            marginTop: -1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>MUNICIPIO</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.cidade}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 2,
            justifyContent: "space-between",
            marginLeft: -1,
            marginTop: -1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>FONE/FAX</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.telefone1}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "space-between",
            marginLeft: -1,
            marginTop: -1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>UF</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.uf}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 4,
            justifyContent: "space-between",
            marginLeft: -1,
            marginTop: -1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "8pt" }}>INSCRIÇÃO ESTADUAL</Text>
          <Text style={{ fontSize: "10pt" }}>
            {nota.cliente && nota.cliente.tipo === "FISICO"
              ? "ISENTO"
              : nota.cliente.isncEstadual}
          </Text>
        </View>
      </View>

      <Text style={{ fontSize: "7pt", marginTop: "5mm" }}>
        DADOS DO PRODUTO/SERVIÇO
      </Text>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            width: "1.5cm",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>SEQ.</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 4,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>DESCRIÇÃO</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            width: "1.5cm",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>QTD.</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            width: "3cm",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>VALOR TOTAL</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            width: "4cm",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>BASE DE CALCULO ICMS</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            width: "3cm",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>ALÍCOTA ICMS</Text>
        </View>
      </View>

      {nota.itens.map((item, i) => (
        <View
          key={`${i}`}
          style={{
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              width: "1.5cm",
              borderStyle: "solid",
              borderColor: "#000",
              borderWidth: 1,
              marginTop: -1,
              paddingTop: 1,
              paddingLeft: 3,
              paddingBottom: 3,
            }}
          >
            <Text style={{ fontSize: "9pt" }}>
              {`${i + 1}`.padStart(3, "0")}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              flexGrow: 4,
              borderStyle: "solid",
              borderColor: "#000",
              borderWidth: 1,
              marginTop: -1,
              marginLeft: -1,
              paddingTop: 1,
              paddingLeft: 3,
              paddingBottom: 3,
            }}
          >
            <Text style={{ fontSize: "9pt" }}>
              {item.produto && item.produto.nome}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              width: "1.5cm",
              borderStyle: "solid",
              borderColor: "#000",
              borderWidth: 1,
              marginTop: -1,
              marginLeft: -1,
              paddingTop: 1,
              paddingLeft: 3,
              paddingBottom: 3,
            }}
          >
            <Text style={{ fontSize: "9pt" }}>
              {formatNumber(item.quantidade, 0, true)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              width: "3cm",
              borderStyle: "solid",
              borderColor: "#000",
              borderWidth: 1,
              marginTop: -1,
              marginLeft: -1,
              paddingTop: 1,
              paddingLeft: 3,
              paddingBottom: 3,
            }}
          >
            <Text style={{ fontSize: "9pt" }}>
              {formatNumber(item.quantidade * item.valor_unitario, 2, true)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              width: "4cm",
              borderStyle: "solid",
              borderColor: "#000",
              borderWidth: 1,
              marginTop: -1,
              marginLeft: -1,
              paddingTop: 1,
              paddingLeft: 3,
              paddingBottom: 3,
            }}
          >
            <Text style={{ fontSize: "9pt" }}>
              {formatNumber(item.quantidade * item.valor_unitario, 2, true)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              width: "3cm",
              borderStyle: "solid",
              borderColor: "#000",
              borderWidth: 1,
              marginTop: -1,
              marginLeft: -1,
              paddingTop: 1,
              paddingLeft: 3,
              paddingBottom: 3,
            }}
          >
            <Text style={{ fontSize: "9pt" }}>{formatNumber(0, 2, true)}</Text>
          </View>
        </View>
      ))}
      <View
        style={{
          flexGrow: 10,
        }}
      >
        <Text> </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "6pt" }}>Base de Calculo ICMS</Text>
          <Text style={{ fontSize: "9pt" }}>
            {formatNumber(nota.valortotal, 2, true)}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "6pt" }}>Valor do ICMS</Text>
          <Text style={{ fontSize: "9pt" }}>{formatNumber(0, 2, true)}</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "6pt" }}>Isentas e Não tributadas</Text>
          <Text style={{ fontSize: "9pt" }}>{formatNumber(0, 2, true)}</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginLeft: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "6pt" }}>Outros</Text>
          <Text style={{ fontSize: "9pt" }}>{formatNumber(0, 2, true)}</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            borderStyle: "solid",
            borderColor: "#000",
            marginLeft: -1,
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "6pt" }}>Valor Total</Text>
          <Text style={{ fontSize: "9pt" }}>
            {formatNumber(nota.valortotal, 2, true)}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            borderStyle: "solid",
            borderColor: "#000",
            marginTop: -1,
            borderWidth: 1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "6pt" }}>CHAVE DE CODIFICAÇÃO DIGITAL</Text>
          <Text style={{ fontSize: "9pt", textAlign: "center" }}>
            {nota.autenticacao.match(/.{1,4}/g).join(".")}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginTop: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>
            Contribuição para o FUST e FUNTTEL não repassadas às tarifas.
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            alignItems: "center",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginTop: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>
            Não incluso na lista de serviços anexa a lei complementar nº 116/03.
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flexGrow: 1,
            alignItems: "center",
            borderStyle: "solid",
            borderColor: "#000",
            borderWidth: 1,
            marginTop: -1,
            paddingTop: 1,
            paddingLeft: 3,
            paddingBottom: 3,
          }}
        >
          <Text style={{ fontSize: "9pt" }}>
            Documento emitido por ME ou EPP optante pelo Simples Nacional.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Nota21;
