import { Box, Flex, Input, List, ListItem } from "@chakra-ui/react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCombobox } from "downshift";
import React, { OlHTMLAttributes, useEffect, useState } from "react";

const ComboboxInput = React.forwardRef<HTMLInputElement>(
  ({ ...props }, ref) => {
    return <Input {...props} autoComplete="no" ref={ref} />;
  }
);

type ComboboxInputProps = OlHTMLAttributes<HTMLUListElement> & {
  isOpen: boolean;
};

const ComboboxList = React.forwardRef<HTMLUListElement, ComboboxInputProps>(
  ({ isOpen, ...props }, ref) => {
    return (
      <List
        display={isOpen ? "block" : "none"}
        position="absolute"
        bgColor="blue.800"
        color="lightgray"
        top="0"
        py={2}
        minW="100%"
        {...props}
        ref={ref}
      />
    );
  }
);

type ComboboxItemProps = OlHTMLAttributes<HTMLLIElement> & {
  itemIndex: number;
  highlightedIndex: number;
};

const ComboboxItem = React.forwardRef<HTMLLIElement, ComboboxItemProps>(
  ({ itemIndex, highlightedIndex, ...props }, ref) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bgColor={isActive ? "blue.600" : undefined}
        px={4}
        py={2}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  }
);

export type AutocompleteProps = {
  items: any[];
  renderItem: (item: any) => string | React.Component;
  filter: (text: string, item: any) => boolean;
  itemLabel: (item: any) => string;
  placeholder?: string;
  onSelectItem: (item: any) => void;
  onFilterFetch?: (item: string) => void;
  isLoading?: boolean;
  value: string;
  CustomInput?: typeof ComboboxInput;
  ref: HTMLInputElement;
};

const Autocomplete = React.forwardRef<HTMLInputElement, AutocompleteProps>(
  (
    {
      items,
      filter,
      renderItem,
      itemLabel,
      placeholder,
      onSelectItem,
      onFilterFetch,
      value,
      isLoading,
      CustomInput,
    },
    ref
  ) => {
    const [inputItems, setInputItems] = useState(items.slice(0, 20));
    const {
      isOpen,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
      setInputValue,
    } = useCombobox({
      items: inputItems,
      onInputValueChange: async ({ inputValue }) => {
        if (!inputValue) {
          return;
        }
        typeof onFilterFetch === "function" &&
          (await onFilterFetch(inputValue));
        console.log("Filtering results");
        const results = items
          .filter((item) => filter(inputValue, item))
          .slice(0, 20);
        setInputItems(results);
      },
      onSelectedItemChange: (changes) => {
        onSelectItem(changes.selectedItem);
        setInputValue(itemLabel(changes.selectedItem));
      },
    });
    useEffect(() => {
      !!value && setInputValue(value);
    }, [value, setInputValue]);

    let CInput = ComboboxInput;
    if (CustomInput) {
      CInput = CustomInput;
    }

    const inputProps = getInputProps({
      ref: ref,
    });

    return (
      <Flex {...getComboboxProps()} direction="column" flex="1 1 auto">
        <Flex direction="row" alignItems="baseline">
          <CInput {...inputProps} placeholder={placeholder} flex="0 0 auto" />
        </Flex>
        <Box position="relative" height="0.2rem">
          <ComboboxList
            isOpen={isOpen}
            {...getMenuProps()}
            flex={1}
            overflowY="auto"
            zIndex="999999"
            mt={0}
          >
            {isLoading && (
              <ComboboxItem itemIndex={-1} highlightedIndex={highlightedIndex}>
                <FontAwesomeIcon icon={faSync} spin />
              </ComboboxItem>
            )}
            {inputItems.map((item, index) => (
              <ComboboxItem
                {...getItemProps({ item: itemLabel(item), index })}
                itemIndex={index}
                highlightedIndex={highlightedIndex}
                key={index}
              >
                {renderItem(item)}
              </ComboboxItem>
            ))}
          </ComboboxList>
        </Box>
      </Flex>
    );
  }
);

export default Autocomplete;
