import { Heading } from "@chakra-ui/layout";
import { Button, Flex, Grid, GridItem, Link } from "@chakra-ui/react";
import { endOfMonth, startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import LoadingWheel from "../../components/LoadingWheel";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Cobranca,
  TipoConvenio,
  useBuscaConvenioQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";

const ConvenioTrocaServico: React.FC<{}> = (props) => {
  const [data_inicial, setDataInicial] = React.useState(
    startOfMonth(new Date())
  );
  const [data_final, setDataFinal] = React.useState(endOfMonth(new Date()));

  const [{ data, fetching }] = useBuscaConvenioQuery({
    variables: {
      tipoConvenio: TipoConvenio.Trocaservicos,
      data_inicial,
      data_final,
    },
  });
  if (fetching) {
    return <LoadingWheel />;
  }

  const headersConvenio: TableHeaders<Cobranca>[] = [
    {
      label: "#",
      wrapped: true,
      render: (cobranca) => `${cobranca.id}`,
    },
    {
      label: "Cliente",
      wrapped: true,
      render: (cobranca) => `${cobranca.cliente.nome}`,
    },
    {
      label: "Contrato",
      wrapped: true,
      render: (cobranca) =>
        `${cobranca.contrato.id}/${cobranca.contrato.usuario}`,
    },
    {
      label: "Vencimento",
      wrapped: true,
      render: (cobranca) => formatDate(cobranca.vencimento),
    },
    {
      label: "Valor",
      wrapped: true,
      render: (cobranca) => formatNumber(cobranca.valor, 2, true),
    },
    {
      label: "Status",
      wrapped: true,
      render: (cobranca) =>
        cobranca.isPago
          ? "Baixada"
          : cobranca.isCanceled
          ? "Cancelada"
          : "Pendente",
    },
  ];

  return (
    <>
      <Heading>Relatório do convenio de Troca de serviços</Heading>

      <Formik
        initialValues={{
          data_inicial,
          data_final,
        }}
        onSubmit={(values) => {
          setDataInicial(values.data_inicial);
          setDataFinal(values.data_final);
        }}
      >
        {() => (
          <Form>
            <Grid
              marginBottom="2rem"
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="data_inicial" label="Inicio" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="data_final" label="Fim" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Confirmar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>
      {data && data.buscaConvenio && (
        <Flex direction="row" marginBottom="2rem" justifyContent="flex-end">
          <Link
            href={`/baixarCobrancas/${data.buscaConvenio
              .map((cob) => cob.id)
              .join(",")}`}
          >
            <Button type="button" colorScheme="green">
              Baixar cobranças
            </Button>
          </Link>
        </Flex>
      )}

      {data && data.buscaConvenio && (
        <ResponsiveTable data={data.buscaConvenio} headers={headersConvenio} />
      )}
    </>
  );
};

export default ConvenioTrocaServico;
