export default function modulo10(numero: string): number {
  let numtotal10 = 0;
  let fator = 2;

  for (let i = numero.length - 1; i >= 0; i--) {
    const mult = `${parseInt(numero.substr(i, 1)) * fator}`;
    numtotal10 += mult
      .split("")
      .map((v) => parseInt(v))
      .reduce((a, p) => a + p, 0);
    fator = fator === 2 ? 1 : 2;
  }
  const resto = numtotal10 % 10;
  if (resto === 0) {
    return 0;
  }
  return 10 - resto;
}

/*
7   4  8 9 1 1 2 1 2
2   1  2 1 2 1 2 1 2
14  4 16 9 2 1 4 1 4 = 37 -> 7 -> 10 -7 = 3
*/
