import { GridItem, Heading } from "@chakra-ui/layout";
import { Button, Grid, useToast } from "@chakra-ui/react";
import { isBefore } from "date-fns";
import { endOfMonth, startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import BancoSelectorField from "../../components/BancoSelectorField";
import DateSelector from "../../components/DateComponents/DateSelector";
import { useMudarBancoCobrancasMutation } from "../../generated/graphql";

const MudarBancoCobrancas: React.FC<{}> = () => {
  const [, mudarBanco] = useMudarBancoCobrancasMutation();
  const toast = useToast();

  const valoresIniciais = {
    data_inicial: startOfMonth(new Date()),
    data_final: endOfMonth(new Date()),
    banco_origem: null,
    banco_destino: null,
  };
  return (
    <>
      <Heading>Parametros para mudar cobranças de banco</Heading>

      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          console.log("alterar", values);
          if (!values.banco_origem) {
            setErrors({
              banco_origem: "Banco de origem deve ser preenchido",
            });
            return;
          }
          if (!values.banco_destino) {
            setErrors({
              banco_destino: "Banco de destino deve ser preenchido",
            });
            return;
          }
          if (!values.data_inicial || !(values.data_inicial instanceof Date)) {
            setErrors({
              data_inicial: "Data inicial deve ser preenchido",
            });
            return;
          }
          if (!values.data_final || !(values.data_final instanceof Date)) {
            setErrors({
              data_final: "Data final deve ser preenchido",
            });
            return;
          }
          if (isBefore(values.data_final, values.data_inicial)) {
            setErrors({
              data_final: "Data final deve ser maior que a data inicial",
            });
            return;
          }
          const { data, error } = await mudarBanco({
            banco_origem: values.banco_origem.id,
            banco_destino: values.banco_destino.id,
            data_inicial: values.data_inicial,
            data_final: values.data_final,
          });

          if (error) {
            toast({
              title: "Erro ao alterar banco de cobranças",
              description: `Erro: ${error.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          toast({
            title: "Alterado banco de cobranças com sucesso",
            description: `Alterado banco de ${data.mudarBancoCobrancas} cobrancas!`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 3]}>
                <BancoSelectorField
                  label="Banco de origem"
                  name="banco_origem"
                  placeholder="Preecha um banco de origem"
                />
              </GridItem>
              <GridItem colSpan={[6, 3]}>
                <BancoSelectorField
                  label="Banco de destino"
                  name="banco_destino"
                  placeholder="Preecha um banco de destino"
                />
              </GridItem>
              <GridItem colSpan={[6, 3]}>
                <DateSelector name="data_inicial" label="Inicio" />
              </GridItem>
              <GridItem colSpan={[6, 3]}>
                <DateSelector name="data_final" label="Fim" />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MudarBancoCobrancas;
