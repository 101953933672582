import { Button, Tooltip } from "@chakra-ui/react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Contrato } from "../../generated/graphql";
import TestarPlanoModal from "./TestarPlanoModal";

export interface TestarPlanoButtonProps {
  contrato: Contrato;
  onSuccess?: () => void;
}

const TestarPlanoButton: React.FC<TestarPlanoButtonProps> = ({
  contrato,
  onSuccess,
}) => {
  const [isTestarPlanoModel, setIsTestarPlanoModel] = useState(false);
  return (
    <>
      <TestarPlanoModal
        contrato={contrato}
        isOpen={isTestarPlanoModel}
        onClose={() => setIsTestarPlanoModel(false)}
        onSuccess={onSuccess}
      />
      <Tooltip label="Testar plano">
        <Button
          onClick={() => setIsTestarPlanoModel(true)}
          colorScheme="purple"
          type="button"
        >
          <FontAwesomeIcon icon={faUpload} />
        </Button>
      </Tooltip>
    </>
  );
};

export default TestarPlanoButton;
