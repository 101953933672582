import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import PaginationButtons from "../../components/PaginationButtons";
import {
  Produto,
  useDeleteProdutoMutation,
  useProdutosQuery,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";
import { formatNumber } from "../../utils/formatNumber";
import { Link } from "react-router-dom";
import LoadingWheel from "../../components/LoadingWheel";

const Produtos: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching }, query] = useProdutosQuery({
    variables: { page: page, filter },
    requestPolicy: "cache-and-network",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteProdutoMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (produto: Produto) => {
    await deleteData({ id: produto.id });
    setDeleteSuccessMsg(`Produto ${produto.nome} foi removido com sucesso!`);
    query();
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Heading mb="1em">Produtos</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/produtos/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo produto
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Velocidade</Th>
            <Th>Valor</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.produtos.data.map((produto) => (
            <Tr key={produto.id}>
              <Td>{produto.nome}</Td>
              <Td>{produto.velocidade}</Td>
              <Td>{formatNumber(produto.valor)}</Td>
              <Td>
                <Link to={`/produtos/${produto.id}`}>
                  <Button colorScheme="orange" type="button">
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </Link>
                <ConfirmButton
                  isLoading={deleteLoading}
                  onClick={() => onDeleteConfirm(produto as Produto)}
                  colorScheme="red"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ConfirmButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <PaginationButtons
        totalPages={data?.produtos.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Produtos;
