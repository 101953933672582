import { View, Text } from "@react-pdf/renderer";
import { getMonth, getYear } from "date-fns";
import _ from "lodash";
import * as React from "react";
import { Cobranca } from "../generated/graphql";
import formatDate from "../utils/formatDate";
import { formatNumber } from "../utils/formatNumber";

export interface RelacaoMoinhoProps {
  cobrancas: Cobranca[];
}

const RelacaoMoinho: React.FC<RelacaoMoinhoProps> = ({ cobrancas }) => {
  const cobrancasPorValor = _.groupBy(cobrancas, (cob) =>
    formatNumber(cob.valor, 2, true)
  );
  const vencimento = new Date(getYear(new Date()), getMonth(new Date()), 5);
  return (
    <View
      style={{
        flexDirection: "column",
        marginTop: "1cm",
        marginLeft: "1cm",
        marginRight: "1cm",
        width: "19cm",
      }}
    >
      {_.map(cobrancasPorValor, (cobrancas, key) =>
        cobrancas.map((cobranca, i) => (
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              borderStyle: "solid",
              borderColor: "#000",
              marginTop: -1,
              borderWidth: 1,
            }}
            key={`${key}${i}`}
          >
            <Text
              style={{
                padding: "1mm",
                fontSize: "10pt",
              }}
            >
              {i + 1} - {cobranca.cliente.nome} - {cobranca.contrato.usuario}(
              {formatNumber(cobranca.valor, 2, true)})
            </Text>
          </View>
        ))
      )}
      <Text style={{ marginTop: "1cm", fontSize: "16pt" }}>
        Relação de usuários internet - Gabrimar
      </Text>
      <Text style={{ marginTop: "5mm", fontSize: "11pt" }}>
        Emissão {formatDate(new Date())}
      </Text>
      <Text style={{ marginTop: "5mm", fontSize: "11pt" }}>
        Vencimento {formatDate(vencimento)}
      </Text>
      {_.map(cobrancasPorValor, (cobrancas, key) => (
        <Text style={{ marginTop: "1mm", fontSize: "11pt" }}>
          {formatNumber(cobrancas.length, 0, false)} x {key} = R${" "}
          {formatNumber(
            cobrancas.reduce((a, p) => a + p.valor, 0),
            2,
            true
          )}
        </Text>
      ))}
      <Text style={{ marginTop: "5mm", fontSize: "11pt" }}>
        Total {cobrancas.length}.....R${" "}
        {formatNumber(
          cobrancas.reduce((a, p) => a + p.valor * 1, 0),
          2,
          true
        )}
      </Text>
    </View>
  );
};

export default RelacaoMoinho;
