import { Button, ButtonProps, useToast } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import {
  Cobranca,
  useConsultaCobrancaOnlineMutation,
} from "../../generated/graphql";

type BConsultaCobrancaWSProps = {
  cobranca: Cobranca;
} & ButtonProps;

export const BConsultaCobrancaWS: React.FC<BConsultaCobrancaWSProps> = ({
  onClick,
  cobranca,
  children,
  ...props
}) => {
  const [, consultarCobranca] = useConsultaCobrancaOnlineMutation();
  const [fetching, setFetching] = useState(false);
  const toast = useToast();

  const handleConsultaCobranca = async () => {
    setFetching(true);
    try {
      const { error, data } = await consultarCobranca({ id: cobranca.id });
      if (error) {
        console.log("ocorrido erro", error);
        throw error;
      }
      toast({
        title: "Consultada com sucesso",
        description: `Cobranca [${cobranca.id}]: ${data.consultaCobrancaOnline}`,
        duration: 9000,
        status: "success",
      });
    } catch (error) {
      console.log("error ocurred", error);
      let errMsg = JSON.stringify(error);
      if (error.message) {
        errMsg = error.message;
      }
      toast({
        title: "Erro ao registrar cobrança online",
        description: `Cobranca [${cobranca.id}]:${errMsg}`,
        status: "error",
      });
    }
    setFetching(false);
  };

  return (
    <Button {...props} isLoading={fetching} onClick={handleConsultaCobranca}>
      {children}
    </Button>
  );
};
