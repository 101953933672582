import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import { Form, Formik } from "formik";
import React from "react";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import LoadingWheel from "../../components/LoadingWheel";
import SelectField from "../../components/SelectField";
import {
  AddIteracao,
  Contrato,
  FechamentoChamado,
  StatusChamado,
  useAddIteracaoMutation,
  useChamadoQuery,
  useFechaChamadoMutation,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatStatusChamado } from "../../utils/formatStatusChamado";
import { formatTipoChamado } from "../../utils/formatTipoChamado";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";
import useGeolocation from "react-hook-geolocation";
import CheckField from "../../components/CheckField";
import AlteraAcessoButton from "../contratos/AlteraAcessoButton";

const ChamadoForm: React.FC<{}> = () => {
  useIsAuth();
  const [{ fetching, data, error }, query] = useChamadoQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
    requestPolicy: "network-only",
  });

  const [, addIteracao] = useAddIteracaoMutation();
  const [, fechaChamado] = useFechaChamadoMutation();
  const coordenadas = useGeolocation();

  if (fetching) {
    return <LoadingWheel />;
  }

  const novaIteracao = (): AddIteracao &
    FechamentoChamado & { salvaLocalizacao: boolean } => ({
      chamado: data?.chamado.id,
      descricao: "",
      novoStatus: data?.chamado.status,
      valor: 0,
      novaPrioridade: data?.chamado.prioridade,
      nota: 1,
      salvaLocalizacao: false,
    });

  return (
    <>
      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      {!!data && !!data.chamado && !!data.chamado.id && (
        <>
          <Flex flexDirection={["column", "row"]}>
            <Heading size="lg">
              Chamado #{data?.chamado.id} - {formatDate(data?.chamado.inicio)}
            </Heading>
            <Heading
              ml="auto"
              mr="1rem"
              size="md"
              px="1rem"
              py="0.5rem"
              backgroundColor="green.800"
              textColor="green.100"
              borderRadius="5px"
            >
              {formatTipoChamado(data?.chamado.tipo)}
            </Heading>
            <Heading
              mr="1rem"
              ml={["auto", "0.2rem"]}
              size="md"
              px="1rem"
              py="0.5rem"
              backgroundColor="green.800"
              textColor="green.100"
              borderRadius="5px"
            >
              {formatStatusChamado(data?.chamado.status)}
            </Heading>
          </Flex>

          {data?.chamado.cliente && (
            <Heading mt="1rem" size="md">
              Cliente: {data?.chamado.cliente.nome}
            </Heading>
          )}
          {data?.chamado.contrato && (
            <>
              <Heading mb="1rem" size="md">
                Contrato: {data?.chamado.contrato.id} -{" "}
                {data?.chamado.contrato.usuario}{" "}
                {data?.chamado.contrato?.estacao?.nome}
                <AlteraAcessoButton contrato={data.chamado.contrato as Contrato} />
              </Heading>
              <Text>
                Endereço: {data.chamado.contrato.logradouro},{" "}
                {data.chamado.contrato.numero}
              </Text>
              <Text>Bairro: {data.chamado.contrato.bairro}</Text>
              <Text>Complemento: {data.chamado.contrato.complemento}</Text>
              <Text>
                Cidade: {data.chamado.contrato.cidade}-
                {data.chamado.contrato.uf}
              </Text>
            </>
          )}
          <Heading mt="1rem" mb="0.5rem" size="md">
            Iterações
          </Heading>
          {data?.chamado.iteracoes &&
            data?.chamado.iteracoes.length > 0 &&
            data?.chamado.iteracoes.map((iteracao) => (
              <Flex
                flexDirection="column"
                p="1rem"
                my="1rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="grey"
                borderRadius="5px"
              >
                <Text>{iteracao.descricao}</Text>
                <Divider />
                <Flex
                  flexDirection={["column", "row"]}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text>{formatDate(iteracao.data)}</Text>
                  <Text>Usuario: {iteracao.usuario.usuario}</Text>
                  {iteracao.status_anterior && (
                    <Text>
                      Status anterior:{" "}
                      {formatStatusChamado(iteracao.status_anterior)}
                    </Text>
                  )}
                  <Text>
                    Status posterior:{" "}
                    {formatStatusChamado(iteracao.status_posterior)}
                  </Text>
                  <Text>{iteracao.coordenadas}</Text>
                </Flex>
              </Flex>
            ))}
          {data?.chamado?.status !== StatusChamado.Fechado && (
            <>
              <Heading mt="1rem" mb="0.5rem" size="md">
                Incluir iteração
              </Heading>
              <Formik
                initialValues={novaIteracao()}
                onSubmit={async (values, { setErrors, resetForm }) => {
                  console.log("coordenadas", coordenadas);
                  if (values.novoStatus === StatusChamado.Fechado) {
                    const { error } = await fechaChamado({
                      iteracao: {
                        chamado: values.chamado,
                        descricao: values.descricao,
                        valor: values.valor,
                        nota: values.nota * 1,
                      },
                    });

                    if (error) {
                      setErrors({
                        novoStatus: error.message.replace(/\[.*\]/, ""),
                      });
                      return;
                    }
                  } else {
                    const { error } = await addIteracao({
                      iteracao: {
                        chamado: values.chamado,
                        descricao: values.descricao,
                        valor: values.valor,
                        novaPrioridade: values.novaPrioridade * 1,
                        novoStatus: values.novoStatus,
                        coordenadas:
                          values.salvaLocalizacao &&
                            !coordenadas.error &&
                            coordenadas.accuracy < 50
                            ? JSON.stringify([
                              coordenadas.longitude,
                              coordenadas.latitude,
                            ])
                            : null,
                      },
                    });

                    if (error) {
                      setErrors({
                        novoStatus: error.message.replace(/\[.*\]/, ""),
                      });
                      return;
                    }
                  }

                  query();
                  resetForm();
                }}
              >
                {({ values }) => (
                  <Form>
                    <Flex flexDirection="column">
                      <InputField
                        label="Descrição"
                        name="descricao"
                        textarea
                        placeholder="Descreva a iteração"
                      />
                      <Flex
                        flexDirection={["column", "row"]}
                        mb="1rem"
                        justifyContent="space-between"
                        alignItems="flex-end"
                      >
                        <SelectField label="Novo Status" name="novoStatus">
                          <option value={StatusChamado.Iniciado}>
                            Iniciado
                          </option>
                          <option value={StatusChamado.Emandamento}>
                            Em andamento
                          </option>
                          <option value={StatusChamado.Aguardando}>
                            Aguardando Cliente
                          </option>
                          <option value={StatusChamado.Material}>
                            Aguardando material
                          </option>
                          <option value={StatusChamado.Aprovacao}>
                            Aguardando aprovação
                          </option>
                          <option value={StatusChamado.Fechado}>Fechado</option>
                        </SelectField>
                        {values.novoStatus !== StatusChamado.Fechado && (
                          <SelectField
                            name="novaPrioridade"
                            label="Prioridade"
                            placeholder="Selecione a prioridade"
                          >
                            <option value={1}>1 (Baixa)</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={5}>5</option>
                            <option value={8}>8</option>
                            <option value={13}>13</option>
                            <option value={21}>21 (Alta)</option>
                          </SelectField>
                        )}
                        {values.novoStatus === StatusChamado.Fechado && (
                          <SelectField
                            name="nota"
                            label="Nota"
                            placeholder="Selecione a nota"
                          >
                            <option value={1}>1 (Insatisfeito)</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5 (Muito Satisfeito)</option>
                          </SelectField>
                        )}
                        <CheckField
                          name="salvaLocalizacao"
                          label="Salvar Localização"
                        />
                        {data?.chamado.tipo !== "INTERNO" && (
                          <InputNumber
                            label="Valor a pagar"
                            mx="1rem"
                            name="valor"
                          />
                        )}
                        <Button
                          ml="1rem"
                          type="submit"
                          minW="fit-content"
                          colorScheme="teal"
                        >
                          Incluir Iteração
                        </Button>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ChamadoForm;
