import React from "react";
import SelectField, { SelectFieldProps } from "./SelectField";

export type UfSelectorFieldProps = SelectFieldProps;

const UfSelectorField: React.FC<UfSelectorFieldProps> = (props) => {
  return (
    <SelectField {...props}>
      <option>AC</option>
      <option>AL</option>
      <option>AP</option>
      <option>AM</option>
      <option>BA</option>
      <option>CE</option>
      <option>DF</option>
      <option>ES</option>
      <option>GO</option>
      <option>MA</option>
      <option>MT</option>
      <option>MS</option>
      <option>MG</option>
      <option>PA</option>
      <option>PB</option>
      <option>PR</option>
      <option>PE</option>
      <option>PI</option>
      <option>RJ</option>
      <option>RN</option>
      <option>RS</option>
      <option>RO</option>
      <option>RR</option>
      <option>SC</option>
      <option>SP</option>
      <option>SE</option>
      <option>TO</option>
    </SelectField>
  );
};

export default UfSelectorField;
