import React from "react";
import { Stack } from "@chakra-ui/layout";
import { useCaixasQuery, useMeQuery } from "../generated/graphql";
import SidebarLink from "./SidebarLink";
import { useEffect } from "react";

export interface SideMenuProps {
  isInsideToggle?: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ isInsideToggle }) => {
  const [{ data, fetching }] = useMeQuery();
  const [{ data: resCaixas }, reQueryCaixas] = useCaixasQuery({
    variables: {
      page: 1,
    },
    requestPolicy: "cache-and-network",
  });
  useEffect(() => {
    console.log("changed me query", data);
    reQueryCaixas();
  }, [data, reQueryCaixas]);

  if (fetching || !data?.me) {
    return null;
  }

  return (
    <Stack
      direction="column"
      minW="30ch"
      bgColor="blue.700"
      pl="2ch"
      pt="1ch"
      pr="2ch"
      display={!isInsideToggle ? ["none", "flex"] : "flex"}
      clipPath="polygon(0 0, 100% 0, 95% 100%, 0 100%)"
    >
      <SidebarLink to="/clientes">Clientes</SidebarLink>
      <SidebarLink to="/planos">Planos</SidebarLink>
      <SidebarLink to="/bancos">Bancos</SidebarLink>
      <SidebarLink to="/caixas">Caixas</SidebarLink>
      {resCaixas &&
        resCaixas.caixas &&
        resCaixas.caixas.data.length > 0 &&
        resCaixas.caixas.data.map((cx) => (
          <SidebarLink to={`/lancamentosCaixa/${cx.id}`} key={`${cx.id}`}>
            Caixa {cx.nome}
          </SidebarLink>
        ))}
      <SidebarLink to="/cobrancas">Cobranças</SidebarLink>
      <SidebarLink to="/impressao/boletosAimprimir">
        Cobranças a imprimir
      </SidebarLink>
      <SidebarLink to="/geraArquivo">Gerar Arquivo CNAB</SidebarLink>
      <SidebarLink to="/leArquivo">Ler Arquivo CNAB</SidebarLink>
      <SidebarLink to="/chamados">Chamados</SidebarLink>
      <SidebarLink to="/estacoes">Estações</SidebarLink>
      <SidebarLink to="/velocidades">Velocidades</SidebarLink>
      <SidebarLink to="/impressao/convenioMoinhoGlobo">
        Relação do convenio MG
      </SidebarLink>
      <SidebarLink to="/series">Séries</SidebarLink>
      <SidebarLink to="/produtos">Produtos</SidebarLink>
      <SidebarLink to="/notas">Notas Fiscais</SidebarLink>
      <SidebarLink to="/geraArquivoNF21">Gera arquivo NF21</SidebarLink>
      {data?.me?.role === "ADMIN" && (
        <>
          <SidebarLink to="/relatorio_cobrancas">
            Relatório de Cobranças
          </SidebarLink>
          <SidebarLink to="/relatorio_pagar">
            Relatório de Pagamentos
          </SidebarLink>
          <SidebarLink to="/estatistica_clientes">
            Clientes Ativos por Mes
          </SidebarLink>
          <SidebarLink to="/convenioTrocaServicos">
            Convernio troca de Serviços
          </SidebarLink>
          <SidebarLink to="/enviarNFMensal">Enviar Notas Mensais</SidebarLink>
          <SidebarLink to="/dre">DRE</SidebarLink>
          <SidebarLink to="/gerarNotas">Gerar Notas</SidebarLink>
          <SidebarLink to="/geraCgnat">Gerar Scripts CGNAT</SidebarLink>
          <SidebarLink to="/olts">Olts</SidebarLink>
        </>
      )}
      <SidebarLink to="/verificaBaixaWS">Verificar Baixas Online</SidebarLink>
      <SidebarLink to="/pagar">Contas a Pagar</SidebarLink>
      <SidebarLink to="/usuarios">Usuarios</SidebarLink>
    </Stack>
  );
};

export default SideMenu;
