import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Form } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import {
  CaixaInput,
  useCaixaQuery,
  useCreateCaixaMutation,
  useUpdateCaixaMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novoCaixa: CaixaInput = {
  nome: "",
};

const CaixaForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const [{ fetching, data }] = useCaixaQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [, createMutation] = useCreateCaixaMutation();
  const [, updateMutation] = useUpdateCaixaMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.caixa ? "Editar" : "Novo"} Caixa`}
      updateMsg={updateMsg}
      initialData={data?.caixa ? data.caixa : novoCaixa}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating caixa", values);
        if (data?.caixa) {
          /// é alteracao
          const { error, data } = await updateMutation({
            caixa: stripObjectToMutation(values),
          });

          if (error) {
            setErrors({
              status: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateCaixa.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateCaixa.errors));
          }

          setUpdateMsg("Caixa salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({ caixa: values });

          if (error) {
            setErrors({
              status: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createCaixa.errors?.length > 0) {
            setErrors(errorsToFormik(data.createCaixa.errors));
            return;
          }

          navigate(`/caixas/${data.createCaixa.caixa.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={6}>
              <InputField name="nome" label="Nome" placeholder="Nome" />
            </GridItem>
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default CaixaForm;
