import { Button } from "@chakra-ui/button";
import { Form, Formik } from "formik";
import * as React from "react";
import { useNavigate } from "react-router";
import InputField from "../components/InputField";
import { useChangePwdMutation } from "../generated/graphql";
import { useIsAuth } from "../utils/useIsAuth";

const ChangePwd: React.FC<{}> = () => {
  useIsAuth();
  const [, changePwd] = useChangePwdMutation();
  const navigate = useNavigate();

  return (
    <>
      <Formik
        initialValues={{ senhaAtual: "", novaSenha: "", confirmacao: "" }}
        onSubmit={async (values, { setErrors }) => {
          const res = await changePwd({
            senhaAtual: values.senhaAtual,
            novaSenha: values.novaSenha,
          });
          if (res.error) {
            return setErrors({
              confirmacao: res.error.message.replace("[GraphQL]", ""),
            });
          }
          navigate("/");
        }}
      >
        {(props) => (
          <Form>
            <InputField
              name="senhaAtual"
              placeholder="Senha Atual"
              label="Senha Atual"
              type="password"
            />
            <InputField
              name="novaSenha"
              placeholder="Nova Senha"
              label="Nova Senha"
              type="password"
            />
            <InputField
              name="confirmacao"
              placeholder="Confirmação da nova senha"
              label="Confirme"
              type="password"
            />

            <Button
              mt={4}
              colorScheme="teal"
              disabled={
                props.values.senhaAtual.length < 3 ||
                props.values.novaSenha.length < 6 ||
                props.values.confirmacao !== props.values.novaSenha
              }
              isLoading={props.isSubmitting}
              type="submit"
            >
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePwd;
