import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { Button, Link } from "@chakra-ui/react";
export type LinkButtonProps = {
  to: string;
  size: string;
  isExternal?: boolean;
  children?: React.ReactNode;
};

const LinkButton: React.FC<LinkButtonProps> = ({
  size,
  isExternal,
  ...props
}) => {
  if (isExternal) {
    return (
      <Button
        as={Link}
        href={props.to}
        target="_blank"
        type="button"
        size={size}
      >
        {props.children}
      </Button>
    );
  }
  return (
    <ReactLink {...props}>
      <Button type="button" size={size}>
        {props.children}
      </Button>
    </ReactLink>
  );
};

export default LinkButton;
