import React from "react";
import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "formik";
import { InputHTMLAttributes, useState } from "react";
import { Cliente, useClientesQuery } from "../generated/graphql";
import removeAcentos from "../utils/removeAcentos";
import Autocomplete from "./Autocomplete";
import LinkButton from "./LinkButton";

export type ClienteSelectorFieldProps =
  InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    label: string;
  };

const ClienteSelectorField: React.FC<ClienteSelectorFieldProps> = ({
  label,
  size: _,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  const [filter, setFilter] = useState("");

  const [{ fetching, data }] = useClientesQuery({
    variables: {
      page: 1,
      filter,
    },
  });

  const filterCliente = (val, opt: Cliente) => {
    if (typeof val !== "string") {
      return false;
    }
    return removeAcentos(opt.nome).toLowerCase().startsWith(val.toLowerCase());
  };

  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      {!!field.value && (
        <InputGroup>
          <Input
            readOnly
            pr="4.5rem"
            value={`${field.value.nome}`}
            type="text"
          />
          <InputRightElement width="4.5rem">
            <Button size="sm" onClick={() => setValue(null)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <LinkButton size="sm" to={`/clientes/${field.value.id}`} isExternal>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </LinkButton>
          </InputRightElement>
        </InputGroup>
      )}
      {!field.value && (
        <Autocomplete
          items={(data && data.clientes.data) || []}
          filter={filterCliente}
          isLoading={fetching}
          onFilterFetch={(filterString) => setFilter(filterString)}
          itemLabel={(item) => `${item.nome}`}
          renderItem={(item) => `${item.nome}`}
          onSelectItem={(item) => setValue(item, false)}
          value={field.value}
          placeholder="Selecione um Cliente..."
        />
      )}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default ClienteSelectorField;
