import { Button } from "@chakra-ui/button";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import CheckField from "../../components/CheckField";
import InputField from "../../components/InputField";
import LoadingWheel from "../../components/LoadingWheel";
import SelectField from "../../components/SelectField";
import {
  useCreateUsuarioMutation,
  useUpdateUsuarioMutation,
  Usuario,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import { useGetUsuario } from "../../utils/useGetUsuario";
import { useIsAuth } from "../../utils/useIsAuth";

const novoUsuario: Usuario & { senha: string } = {
  usuario: "",
  senha: "",
  isActive: true,
  role: "USER",
};

const UsuarioForm: React.FC<{}> = (props) => {
  useIsAuth();
  const navigate = useNavigate();
  const [{ fetching, data }] = useGetUsuario();
  const [, createUsuario] = useCreateUsuarioMutation();
  const [, updateUsuario] = useUpdateUsuarioMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Flex mt="2em" direction="column" flexGrow={1}>
        <Heading>{data.usuario ? "Editar" : "Novo"} Usuario</Heading>

        {!!updateMsg && (
          <Alert status="success">
            <AlertIcon />
            <AlertDescription>{updateMsg}</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setUpdateMsg("")}
            />
          </Alert>
        )}

        <Formik
          initialValues={data.usuario ? data.usuario : novoUsuario}
          onSubmit={async (values, { setErrors }) => {
            console.log("creating usuario", values);
            if (data.usuario) {
              /// é alteracao
              const { error } = await updateUsuario(values);

              if (error) {
                setErrors({
                  isActive: error.message.replace("[GraphQL]", ""),
                });
                return;
              }

              setUpdateMsg("Usuario salvo com sucesso!");
            } else {
              // é inclusão
              const { error, data } = await createUsuario({
                usuario: values.usuario,
                // @ts-ignore
                senha: values.senha,
                isActive: values.isActive,
                role: values.role,
              });

              if (error) {
                setErrors({
                  isActive: error.message.replace("[GraphQL]", ""),
                });
                return;
              }

              if (data.createUsuario.errors?.length > 0) {
                setErrors(errorsToFormik(data.createUsuario.errors));
                return;
              }

              navigate(`/usuarios/${data.createUsuario.usuario.usuario}`);
            }
          }}
        >
          {(props) => (
            <Form>
              <InputField
                name="usuario"
                readOnly={!!data.usuario}
                label="Usuario"
                placeholder="Usuario"
              />
              {!data.usuario && (
                <InputField
                  name="senha"
                  label="Senha Inicial"
                  placeholder="Senha Inicial"
                  type="password"
                />
              )}
              <SelectField
                name="role"
                label="Tipo"
                disabled={props.values.usuario === "admin"}
                placeholder="Selecione um tipo"
              >
                <option value="USER">Usuario</option>
                <option value="TECNICO">Tecnico</option>
                <option value="ADMIN">Administrador</option>
              </SelectField>
              <CheckField
                name="isActive"
                label="Ativo"
                isDisabled={props.values.usuario === "admin"}
              />
              <Button
                type="submit"
                mt="1em"
                colorScheme="teal"
                isLoading={props.isSubmitting}
              >
                Salvar
              </Button>
            </Form>
          )}
        </Formik>
      </Flex>
    </>
  );
};

export default UsuarioForm;
