import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { Grid, GridItem, Text } from "@chakra-ui/react";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  DateRangeInput,
  Pagar,
  useRelatorioPagarMutation,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { useIsAuth } from "../../utils/useIsAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { PDFViewer, Document, Page } from "@react-pdf/renderer";
import PdfPagar from "../../pdfComponents/PdfPagar";
import _ from "lodash";

const RelatorioPagar: React.FC<{}> = () => {
  useIsAuth();

  const [filter, setFilter] = React.useState("");
  const [pdfAberto, setPdfAberto] = React.useState(false);
  const [vencimento, setVencimento] = React.useState({
    inicio: startOfMonth(new Date()),
    fim: new Date(),
  } as DateRangeInput);
  const [pagamento, setPagamento] = React.useState({} as DateRangeInput);
  const [pagoFilter, setPagoFilter] = React.useState(false);
  const [{ data, fetching, error }, query] = useRelatorioPagarMutation();
  const [errorMessage, setErrorMessage] = React.useState("");

  const onQuery = async () => {
    await query({
      isPago: pagoFilter,
      pagamento: pagamento.inicio && pagamento.fim && pagamento,
      vencimento: vencimento.inicio && vencimento.fim && vencimento,
    });
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const totalValor =
    data?.relatorio_pagar?.reduce((a, p) => a + p.valor, 0) || 0;
  const totalValorPago =
    data?.relatorio_pagar?.reduce((a, p) => a + p.valor_pago, 0) || 0;
  const qtdPago =
    data?.relatorio_pagar?.filter((cob) => cob.isPago).length || 0;

  const headers: TableHeaders<Pagar>[] = [
    {
      label: "#",
      render: (pagar) => `${pagar.id}`,
      wrapped: true,
    },
    {
      label: "Descrição",
      render: (pagar) => pagar.descricao,
      wrapped: true,
    },
    {
      label: "Valor",
      render: (pagar) => formatNumber(pagar.valor, 2, true),
      wrapped: true,
    },
    {
      label: "Vencimento",
      render: (pagar) => formatDate(pagar.vencimento),
      wrapped: true,
    },
    {
      label: "Valor pago",
      render: (pagar) => formatNumber(pagar.valor_pago, 2, true),
      wrapped: true,
    },
    {
      label: "Data Pagamento",
      render: (pagar) => formatDate(pagar.data_pago),
      wrapped: true,
    },
    {
      label: "Status",
      render: (pagar) => (pagar.isPago ? "Pago" : "Devedor"),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Relatório de Pagamentos</Heading>

      {!!errorMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{errorMessage}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMessage("")}
          />
        </Alert>
      )}

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      <Flex direction={["column", "row"]}>
        <Button
          disabled={pagoFilter === false}
          onClick={() => setPagoFilter(false)}
        >
          Somente Não Pagos
        </Button>
        <Button
          disabled={pagoFilter === true}
          onClick={() => setPagoFilter(true)}
        >
          Somente Pagos
        </Button>
        <Button
          disabled={pagoFilter === undefined}
          onClick={() => setPagoFilter(undefined)}
        >
          Todos
        </Button>
      </Flex>

      <Formik
        initialValues={vencimento}
        onSubmit={(values) => {
          setVencimento(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Vencimento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Vencimento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Aplicar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setVencimento({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={pagamento}
        onSubmit={(values) => {
          setPagamento(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              mb="1rem"
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Pagamento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Pagamento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Aplicar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setPagamento({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      <Flex direction="row" my="0.5rem">
        <Button
          type="button"
          onClick={() => onQuery()}
          colorScheme="green"
          mx="0.5rem"
        >
          Concluir
        </Button>
      </Flex>
      <Filter value={filter} onChange={setFilter} />

      {data?.relatorio_pagar && (
        <Flex direction="row" my="0.5rem" justifyContent="end">
          <Button
            type="button"
            colorScheme="cyan"
            mx="0.5rem"
            onClick={() => setPdfAberto(!pdfAberto)}
          >
            <FontAwesomeIcon icon={faFilePdf} />
          </Button>
        </Flex>
      )}
      {pdfAberto && data?.relatorio_pagar && (
        <PDFViewer height="80vh" style={{ height: "80vh" }}>
          <Document title="relacao_pagar" author="Gabrimar Telecom">
            {_.chunk(data.relatorio_pagar, 35).map((pagarArr, i) => (
              <Page key={`${i}`} size="A4" style={{ flexDirection: "column" }}>
                <PdfPagar pagars={pagarArr} />
              </Page>
            ))}
          </Document>
        </PDFViewer>
      )}

      <ResponsiveTable data={data?.relatorio_pagar} headers={headers} />

      <Text fontSize="2xl" fontWeight="bold" my="1rem">
        Total de pagamentos ({data?.relatorio_pagar?.length}):{" "}
        {formatNumber(totalValor, 2, true)}
      </Text>
      <Text fontSize="2xl" fontWeight="bold" my="1rem">
        Total pago ({qtdPago}): {formatNumber(totalValorPago, 2, true)}
      </Text>
      <Text fontSize="2xl" fontWeight="bold" my="1rem">
        Total a pagar ({data?.relatorio_pagar?.length - qtdPago}):{" "}
        {formatNumber(totalValor - totalValorPago, 2, true)}
      </Text>
    </>
  );
};

export default RelatorioPagar;
