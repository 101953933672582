import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import React from "react";
import { ItemNota } from "../generated/graphql";
import { formatNumber } from "../utils/formatNumber";
import InputField from "./InputField";
import InputNumber from "./InputNumber";
import ProdutoSelectorField from "./ProdutoSelectorField";

export type ItemNotaModalProps = {
  itemIndex: number;
  itens: ItemNota[];
  // onChange: (ItemNota) => void;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const ItemNotaModal: React.FC<ItemNotaModalProps> = ({
  itemIndex,
  itens,
  ...props
}) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent px="0.25rem" py="0.25rem" minW="90%">
        <ModalHeader>Editar Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody></ModalBody>
        <Grid gap={6} templateColumns="repeat(6, 1fr)">
          <GridItem colSpan={6}>
            <ProdutoSelectorField
              name={`itens[${itemIndex}]produto`}
              label="Produto"
              placeholder="Selecione um produto"
            />
          </GridItem>
          <GridItem colSpan={[6, 2]}>
            <InputField
              label="CFOP"
              name={`itens[${itemIndex}]cfop`}
              placeholder="CFOP"
              mask="9999"
            />
          </GridItem>
          <GridItem colSpan={[6, 2]}>
            <InputNumber
              label="Quantidade"
              name={`itens[${itemIndex}]quantidade`}
              placeholder="Quantidade"
              decimalPlaces={0}
            />
          </GridItem>
          <GridItem colSpan={[6, 2]}>
            <InputNumber
              label="Valor Unitário"
              name={`itens[${itemIndex}]valor_unitario`}
              placeholder="Valor Unitário"
              decimalPlaces={2}
            />
          </GridItem>
          <GridItem colSpan={[6, 2]}>
            <Stat>
              <StatLabel>Valor Total</StatLabel>
              <StatNumber>
                {formatNumber(
                  itens[itemIndex].valor_unitario * itens[itemIndex].quantidade,
                  2,
                  true
                )}
              </StatNumber>
            </Stat>
          </GridItem>
        </Grid>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={props.onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ItemNotaModal;
