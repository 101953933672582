import { GridItem, Heading } from "@chakra-ui/layout";
import { Button, Grid, Link, useToast } from "@chakra-ui/react";
import { isBefore } from "date-fns";
import { endOfMonth, startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import BancoSelectorField from "../../components/BancoSelectorField";
import DateSelector from "../../components/DateComponents/DateSelector";
import { useGeraArquivoCnabMutation } from "../../generated/graphql";
import { useState } from "react";
import { useIsAuth } from "../../utils/useIsAuth";

const GerarArquivoCNAB: React.FC<{}> = () => {
  useIsAuth();
  const [, geraArquivo] = useGeraArquivoCnabMutation();
  const toast = useToast();
  const [strArquivo, setStrArquivo] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState("");

  const valoresIniciais = {
    data_inicial: startOfMonth(new Date()),
    data_final: endOfMonth(new Date()),
    banco: null,
  };
  return (
    <>
      <Heading>Parametros para gerar arquivo CNAB</Heading>

      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          console.log("alterar", values);
          if (!values.banco) {
            setErrors({
              banco: "Banco deve ser preenchido",
            });
            return;
          }
          if (!values.data_inicial || !(values.data_inicial instanceof Date)) {
            setErrors({
              data_inicial: "Data inicial deve ser preenchido",
            });
            return;
          }
          if (!values.data_final || !(values.data_final instanceof Date)) {
            setErrors({
              data_final: "Data final deve ser preenchido",
            });
            return;
          }
          if (isBefore(values.data_final, values.data_inicial)) {
            setErrors({
              data_final: "Data final deve ser maior que a data inicial",
            });
            return;
          }
          const { data, error } = await geraArquivo({
            bancoid: values.banco.id,
            data_inicial: values.data_inicial,
            data_final: values.data_final,
          });

          if (error) {
            toast({
              title: "Erro ao gerar arquivo CNAB de cobranças",
              description: `Erro: ${error.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          setStrArquivo(data.geraArquivoCNAB.conteudoArquivo);
          setNomeArquivo(data.geraArquivoCNAB.nomeArquivo);
          toast({
            title: "Arquivo CNAB de cobranças gerado com sucesso",
            description: "Arquivo CNAB de cobranças gerado com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 2]}>
                <BancoSelectorField
                  label="Banco"
                  name="banco"
                  placeholder="Preecha um banco para gerar arquivo"
                />
              </GridItem>
              <GridItem colSpan={[6, 2]}>
                <DateSelector name="data_inicial" label="Inicio" />
              </GridItem>
              <GridItem colSpan={[6, 2]}>
                <DateSelector name="data_final" label="Fim" />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
      {strArquivo.length > 10 && (
        <Link
          download={nomeArquivo || "remessa.txt"}
          href={`data:application/octet-stream;name=${
            nomeArquivo || "remessa.txt"
          };base64,${strArquivo}`}
        >
          Download Arquivo
        </Link>
      )}
    </>
  );
};

export default GerarArquivoCNAB;
