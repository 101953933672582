import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Form } from "formik";
import { parse } from "query-string";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import ClienteSelectorField from "../components/ClienteSelectorField";
import ContratoSelectorField from "../components/ContratoSelectorField";
import FormikWrapper from "../components/FormikWrapper";
import InputField from "../components/InputField";
import SelectField from "../components/SelectField";
import {
  StatusChamado,
  TipoChamado,
  useClienteQuery,
  useContratoQuery,
  useCreateChamadoMutation,
} from "../generated/graphql";
import errorsToFormik from "../utils/errorsToFormik";
import { useIsAuth } from "../utils/useIsAuth";

const NovoChamado: React.FC<{}> = () => {
  useIsAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const query = parse(location.search);
  console.log("query", query);

  const [, createChamado] = useCreateChamadoMutation();
  const [{ data: clienteData, fetching: clienteFeching }] = useClienteQuery({
    variables: {
      id: !!query.cliente ? parseInt(query.cliente as string) : null,
    },
  });
  const [{ data: contratoData, fetching: contratoFeching }] = useContratoQuery({
    variables: {
      id: !!query.contrato ? parseInt(query.contrato as string) : null,
    },
  });
  const novoChamado = {
    cliente: !!query.cliente && clienteData ? clienteData?.cliente : null,
    contrato: !!query.contrato && contratoData ? contratoData.contrato : null,
    tipo: TipoChamado.Manutencao,
    statusInicial: StatusChamado.Iniciado,
    descricao: "",
    novaPrioridade: 1,
  };

  return (
    <FormikWrapper
      isLoading={clienteFeching || contratoFeching}
      headerText="Incluir Chamado"
      updateMsg={""}
      initialData={novoChamado}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating chamado", values);
        const { error, data } = await createChamado({
          chamado: {
            cliente: values.cliente && values.cliente.id,
            contrato: values.contrato && values.contrato.id,
            tipo: values.tipo,
            statusInicial: values.statusInicial,
            descricao: values.descricao,
            novaPrioridade: values.novaPrioridade * 1,
          },
        });

        if (error) {
          setErrors({
            descricao: error.message.replace(/\[.*\]/, ""),
          });
          return;
        }

        if (data.createChamado.errors) {
          setErrors(errorsToFormik(data.createChamado.errors));
          return;
        }

        navigate(`/chamados/`);
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            {props.values.tipo !== TipoChamado.Interno && (
              <GridItem colSpan={[6, 3]}>
                <ClienteSelectorField name="cliente" label="Cliente" />
              </GridItem>
            )}
            {props.values.tipo !== TipoChamado.Interno && (
              <GridItem colSpan={[6, 3]}>
                <ContratoSelectorField
                  name="contrato"
                  label="Contrato"
                  clienteid={props.values.cliente && props.values.cliente.id}
                  placeholder="Contrato"
                />
              </GridItem>
            )}
            <GridItem colSpan={6}>
              <InputField
                label="Descrição"
                name="descricao"
                textarea
                placeholder="Descreva o problema"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                name="tipo"
                label="Tipo do Chamado"
                placeholder="Selecione o tipo"
              >
                <option value={TipoChamado.Instalacao}>Instalação</option>
                <option value={TipoChamado.Manutencao}>Manutenção</option>
                <option value={TipoChamado.Mudanca}>Mudança de endereço</option>
                <option value={TipoChamado.Retirdada}>Retirada de Kit</option>
                <option value={TipoChamado.Interno}>Interno</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                name="novaPrioridade"
                label="Prioridade"
                placeholder="Selecione a prioridade"
              >
                <option value={1}>1 (Baixa)</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={8}>8</option>
                <option value={13}>13</option>
                <option value={21}>21 (Alta)</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                name="statusInicial"
                label="Status inicial"
                placeholder="Selecione o status"
              >
                <option value={StatusChamado.Iniciado}>Iniciado</option>
                <option value={StatusChamado.Emandamento}>Em Andamento</option>
                <option value={StatusChamado.Aguardando}>
                  Aguardando cliente
                </option>
                <option value={StatusChamado.Material}>
                  Aguardando material
                </option>
                <option value={StatusChamado.Fechado}>Fechado</option>
              </SelectField>
            </GridItem>
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default NovoChamado;
