import { Heading } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  Link,
} from "@chakra-ui/react";
import {
  faBan,
  faBarcode,
  faDownload,
  faPen,
  faPrint,
  faQrcode,
  faReceipt,
  faTrash,
  faUpload,
  // faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Cobranca,
  useCancelarCobrancaMutation,
  useCobrancasByClienteQuery,
  useDeleteCobrancaMutation,
  useImprimirReciboMutation,
} from "../generated/graphql";
import { BConsultaCobrancaWS } from "../pages/cobrancas/ConsultaCobrancaWS";
import { BCriarPixImediato } from "../pages/cobrancas/CriarPixImediato";
import { BEmitirCobrancaWS } from "../pages/cobrancas/EmitirCobrancaWS";
// import { BEmitirCobrancaWS } from "../pages/cobrancas/EmitirCobrancaWS";
import formatDate from "../utils/formatDate";
import { formatNumber } from "../utils/formatNumber";
import { statusCobranca } from "../utils/statusCobranca";
import { useIsAuth } from "../utils/useIsAuth";
import ConfirmButton from "./ConfirmButton";
import Filter from "./Filter";
import LoadingWheel from "./LoadingWheel";
import PaginationButtons from "./PaginationButtons";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";

export interface CobrancasByClienteProps {
  clienteid: number;
}

const CobrancasByCliente: React.FC<CobrancasByClienteProps> = (props) => {
  useIsAuth();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [{ data, fetching, error }, query] = useCobrancasByClienteQuery({
    variables: {
      page: page,
      filter,
      cliente: props.clienteid,
    },
    requestPolicy: "cache-and-network",
  });

  const [{ fetching: deleteLoading }, deleteData] = useDeleteCobrancaMutation();
  const [{ fetching: imprimirLoading }, imprimirRecibo] =
    useImprimirReciboMutation();

  const [{ fetching: cancelarLoading }, cancelarCobranca] =
    useCancelarCobrancaMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const onDeleteConfirm = async (cobranca: Cobranca) => {
    const { error } = await deleteData({ id: cobranca.id });
    if (error) {
      setErrorMessage(error.message.replace("[GraphQL]", ""));
      return;
    }
    setDeleteSuccessMsg(`Cobrança ${cobranca.id} foi removido com sucesso!`);
    query();
  };

  const onCancelarConfirm = async (cobranca: Cobranca) => {
    const { error } = await cancelarCobranca({ id: cobranca.id });
    if (error) {
      setErrorMessage(error.message.replace("[GraphQL]", ""));
      return;
    }
    setDeleteSuccessMsg(`Cobrança ${cobranca.id} foi cancelado com sucesso!`);
    query();
  };

  const onImprimirRecibo = async (id: number) => {
    const { error } = await imprimirRecibo({ id });
    if (error) {
      setErrorMessage(error.message.replace("[GraphQL]", ""));
      return;
    }
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Cobranca>[] = [
    {
      label: "#",
      render: (cobranca) => `${cobranca.id}`,
      wrapped: true,
    },
    {
      label: "Contrato",
      render: (cobranca) =>
        cobranca.contrato
          ? `${cobranca.contrato.id} - ${cobranca.contrato.usuario}`
          : "",
      wrapped: true,
    },
    {
      label: "Banco",
      render: (cobranca) => (cobranca.banco ? cobranca.banco.nome : ""),
      wrapped: true,
    },
    {
      label: "Valor",
      render: (cobranca) => formatNumber(cobranca.valor, 2, true),
      wrapped: true,
    },
    {
      label: "Vencimento",
      render: (cobranca) => formatDate(cobranca.vencimento),
      wrapped: true,
    },
    {
      label: "Valor pago",
      render: (cobranca) => formatNumber(cobranca.valor_pago),
      wrapped: true,
    },
    {
      label: "Data Pagamento",
      render: (cobranca) => formatDate(cobranca.data_pago),
      wrapped: true,
    },
    {
      label: "Status",
      render: (cobranca) => statusCobranca(cobranca),
      wrapped: true,
    },
    {
      label: " ",
      render: (cobranca) => (
        <>
          <Link href={`/cobrancas/${cobranca.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          {cobranca.isPago && (
            <Button
              colorScheme="purple"
              type="button"
              isLoading={imprimirLoading}
              onClick={() => onImprimirRecibo(cobranca.id)}
            >
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          )}
          {!cobranca.isPago && (
            <>
              <Link href={`/baixarCobrancas/${cobranca.id}`}>
                <Button colorScheme="cyan" type="button">
                  <FontAwesomeIcon icon={faReceipt} />
                </Button>
              </Link>
              <Link href={`/impressao/boletoIndividual/${cobranca.id}`}>
                <Button colorScheme="cyan" type="button">
                  <FontAwesomeIcon icon={faBarcode} />
                </Button>
              </Link>
              {!cobranca.isRegistrado && cobranca.codClienteBol === "06775" && (
                <BEmitirCobrancaWS cobranca={cobranca} colorScheme="blackAlpha">
                  <FontAwesomeIcon icon={faUpload} />
                </BEmitirCobrancaWS>
              )}
              {cobranca.codClienteBol === "06775" && (
                <BConsultaCobrancaWS
                  cobranca={cobranca}
                  colorScheme="blackAlpha"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </BConsultaCobrancaWS>
              )}
              <BCriarPixImediato cobranca={cobranca} colorScheme="blackAlpha">
                <FontAwesomeIcon icon={faQrcode} />
              </BCriarPixImediato>
            </>
          )}
          <ConfirmButton
            isLoading={cancelarLoading}
            onClick={() => onCancelarConfirm(cobranca as Cobranca)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faBan} />
          </ConfirmButton>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(cobranca as Cobranca)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Cobranças do cliente</Heading>

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      {!!errorMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{errorMessage}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMessage("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.cobrancas.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.cobrancas.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default CobrancasByCliente;
