import React from "react";
import { Button } from "@chakra-ui/button";
import { Box, Flex, Link } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import {
  faBars,
  faBug,
  faKey,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useLogoutMutation, useMeQuery } from "../generated/graphql";
import SearchBar from "./SearchBar";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router";
import { Link as ReactLink } from "react-router-dom";

export interface INavBarProps {}

const NavBar: React.FC<INavBarProps> = (props) => {
  const [{ data, fetching }] = useMeQuery();
  const [, logout] = useLogoutMutation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const logoutFn = async () => {
    const res = await logout();
    if (res && res.data && res.data.logout) {
      navigate("/login");
    }
  };

  let body = null;
  if (fetching) {
    body = <Box>Carregando...</Box>;
  } else if (!data?.me) {
    body = (
      <ReactLink to="/login">
        <Link color="white" mr={2}>
          Login
        </Link>
      </ReactLink>
    );
  } else {
    body = (
      <>
        <SearchBar />
        <Flex flexDirection="row" minW={["100vw", "0"]} mt={["1rem", "0"]}>
          <Button
            ml="1rem"
            type="button"
            display={["block", "none"]}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
          <Box color="blue.100" ml="auto">
            ({data.me.usuario})
          </Box>
          <Tooltip label="Alterar minha senha">
            <ReactLink to="/changepwd">
              <Link color="blue.100" mx="1.5em">
                <FontAwesomeIcon icon={faKey} />
              </Link>
            </ReactLink>
          </Tooltip>
          <Tooltip label="Sair">
            <Link color="blue.100" ml="2em" mr="1rem" onClick={logoutFn}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </Link>
          </Tooltip>
        </Flex>
        {isMenuOpen && <SideMenu isInsideToggle={true} />}
      </>
    );
  }

  return (
    <Flex
      bg="blue.700"
      flexDirection={["column", "row"]}
      p={4}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        mr="2rem"
        color="violet"
        display={["none", "block", "block", "block"]}
      >
        <FontAwesomeIcon icon={faBug} />
        Bug
      </Box>
      {body}
    </Flex>
  );
};

export default NavBar;
