import React from "react";
import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Heading } from "@chakra-ui/layout";
import { Form, Formik } from "formik";
import { useState } from "react";
import InputField from "../components/InputField";
import { useResetPasswordMutation } from "../generated/graphql";
import { useIsAdmin } from "../utils/useIsAdmin";
import { useParams } from "react-router";

const ResetPwd: React.FC<{}> = (props) => {
  useIsAdmin();
  const [, resetPwd] = useResetPasswordMutation();
  const [resetSuccessMessage, setResetSuccessMessage] = useState("");
  const params = useParams();

  return (
    <>
      <Heading>Resetar senha do usuario: {params.usuario} </Heading>
      {!!resetSuccessMessage && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{resetSuccessMessage}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setResetSuccessMessage("")}
          />
        </Alert>
      )}
      <Formik
        initialValues={{ novaSenha: "", confirmacao: "" }}
        onSubmit={async (values, { setErrors }) => {
          const { error } = await resetPwd({
            usuario: params.usuario as string,
            novaSenha: values.novaSenha,
          });
          if (error) {
            setErrors({
              confirmacao: error.message.replace("[GraphQL]", ""),
            });
            return;
          }
          setResetSuccessMessage("Senha do usuario resetada com sucesso!");
        }}
      >
        {(props) => (
          <Form>
            <InputField
              name="novaSenha"
              type="password"
              label="Nova Senha"
              placeholder="Nova senha"
            />
            <InputField
              name="confirmacao"
              type="password"
              label="Confirmação"
              placeholder="Confirmação da nova senha"
            />
            <Button
              type="submit"
              mt="1em"
              colorScheme="teal"
              disabled={
                props.values.novaSenha.length < 6 ||
                props.values.novaSenha !== props.values.confirmacao
              }
              isLoading={props.isSubmitting}
            >
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResetPwd;
