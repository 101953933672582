import { Button } from "@chakra-ui/button";
import { Badge, Grid, GridItem, Link } from "@chakra-ui/layout";
import { Form } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import BancoSelectorField from "../../components/BancoSelectorField";
import NumberInput from "../../components/NumberInput";
import CitySelectorField from "../../components/CitySelectorField";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import PlanoSelectorField from "../../components/PlanoSelectorField";
import SelectField from "../../components/SelectField";
import UfSelectorField from "../../components/UfSelectorField";
import {
  ContratoInput,
  useCreateContratoMutation,
  useContratoQuery,
  useUpdateContratoMutation,
  useClienteQuery,
  Cliente,
  TipoConvenio,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import formatDate from "../../utils/formatDate";
import macMask from "../../utils/macMask";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import EstacaoSelectorField from "../../components/EstacaoSelectorField";
import { Link as ReactLink, useNavigate, useParams } from "react-router-dom";
import CheckField from "../../components/CheckField";
import { Checkbox, FormControl, FormErrorMessage } from "@chakra-ui/react";

const novoContrato: ContratoInput = {
  cliente: null,
  plano: null,
  banco: null,
  estacao: null,
  logradouro: "",
  numero: "",
  bairro: "",
  complemento: "",
  cep: "",
  cidade: "",
  uf: "",
  cmun: "",
  telefone1: "",
  telefone2: "",
  telefone3: "",
  email: "",
  usuario: "",
  mac: "",
  vlan: "",
  ip: "",
  obs: "",
  isCanceled: false,
  isBlocked: false,
  isAtivado: false,
  descontoMensalidade: 0,
  acrescimoMensalidade: 0,
  dataAtivacao: null,
  dataCancelamento: null,
  tipoAcesso: null,
  tipoConvenio: null,
  enviaEmailNF: false,
  codConvenio: "",
};

function sugerirUsuario(cliente: Cliente) {
  const splitedNoAccents = cliente.nome
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .split(" ");
  if (splitedNoAccents.length > 2) {
    const surName = splitedNoAccents[_.random(1, splitedNoAccents.length - 1)];
    return `${splitedNoAccents[0]}${surName}${_.random(0, 100)}`.replace(
      /[ ]/g,
      ""
    );
  } else {
    return `${splitedNoAccents[0]}${splitedNoAccents[1]}${_.random(
      0,
      100
    )}`.replace(/[ ]/g, "");
  }
}

const ContratoForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();

  const [{ fetching, data, error }] = useContratoQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [queryCliente] = useClienteQuery({
    variables: {
      id: parseInt(useGetQueryParam("cliente") as string),
    },
  });
  const [, createMutation] = useCreateContratoMutation();
  const [, updateMutation] = useUpdateContratoMutation();
  const params = useParams();
  const [vezesInstalacao, setVezesInstalacao] = useState(1);
  const [vezesInstalacaoError, setVezesInstalacaoError] = useState("");
  const [valorInstalacao, setValorInstalacao] = useState(300);
  const [valorInstalacaoError, setValorInstalacaoError] = useState("");
  const [temEntrada, setTemEntrada] = useState(true);
  const [temEntradaError, setTemEntradaError] = useState("");

  const [updateMsg, setUpdateMsg] = useState("");

  const nContrato: ContratoInput =
    queryCliente.data && queryCliente.data.cliente
      ? {
        ...novoContrato,
        cliente: queryCliente.data.cliente,
        logradouro: queryCliente.data.cliente.logradouro,
        numero: queryCliente.data.cliente.numero,
        bairro: queryCliente.data.cliente.bairro,
        complemento: queryCliente.data.cliente.complemento,
        cep: queryCliente.data.cliente.cep,
        cidade: queryCliente.data.cliente.cidade,
        uf: queryCliente.data.cliente.uf,
        cmun: queryCliente.data.cliente.cmun,
        telefone1: queryCliente.data.cliente.telefone1,
        telefone2: queryCliente.data.cliente.telefone2,
        telefone3: queryCliente.data.cliente.telefone3,
        email: queryCliente.data.cliente.email,
        usuario: sugerirUsuario(queryCliente.data.cliente),
      }
      : novoContrato;

  console.log("data", nContrato, error);

  return (
    <FormikWrapper
      isLoading={fetching || queryCliente.fetching}
      headerText={`${data?.contrato ? "Editar" : "Novo"} Contrato`}
      updateMsg={updateMsg}
      initialData={data?.contrato ? data.contrato : nContrato}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating contrato", values);
        delete values.coordenadas;
        if (data?.contrato) {
          /// é alteracao
          console.log("Updating contrato", values);
          const { error, data } = await updateMutation({
            contrato: stripObjectToMutation(values),
          });
          console.log("error data", error, data);

          if (error) {
            setErrors({
              descontoMensalidade: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateContrato.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateContrato.errors));
          }

          setUpdateMsg("Contrato salvo com sucesso!");
        } else {
          // é inclusão
          // values.cliente = router.query.cliente;
          const contratoValues = stripObjectToMutation(values);

          const { error, data } = await createMutation({
            contrato: contratoValues,
            vezesInstalacao: vezesInstalacao,
            valorInstalacao,
            temEntrada,
          });
          console.log("mutation res", error, data);

          if (error) {
            setErrors({
              descontoMensalidade: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createContrato.errors?.length > 0) {
            const errosParsed = errorsToFormik(data.createContrato.errors);
            setErrors(errosParsed);
            if (errosParsed.valorInstalacao) {
              setValorInstalacaoError(errosParsed.valorInstalacao);
            }
            if (errosParsed.temEntrada) {
              setTemEntradaError(errosParsed.temEntrada);
            }
            if (errosParsed.vezesInstalacao) {
              setVezesInstalacaoError(errosParsed.vezesInstalacao);
            }
            return;
          }

          navigate(`/clientes/${params.cliente}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={6}>
              <ReactLink to={`/clientes/${params.cliente}`}>
                <Link fontSize="2xl">
                  Cliente: {queryCliente.data?.cliente?.nome}{" "}
                </Link>
              </ReactLink>
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <PlanoSelectorField
                label="Plano"
                name="plano"
                placeholder="Selecione um plano"
                onSetPlano={(pln) => setValorInstalacao(pln.valorInstalacao)}
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <BancoSelectorField
                label="Banco"
                name="banco"
                placeholder="Selecione um banco"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <UfSelectorField name="uf" label="UF" placeholder="Selecione" />
            </GridItem>
            <GridItem colSpan={[6, 4]}>
              <CitySelectorField
                name="cidade"
                UF={props.values.uf}
                label="Cidade"
                onCitySelect={(city) =>
                  props.setFieldValue("cmun", `${city.IBGE7}`)
                }
                placeholder="Cidade"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField
                name="cmun"
                label="Cód IBGE Município"
                mask="9999999"
                placeholder="0000000"
              />
            </GridItem>
            <GridItem colSpan={[6, 5]}>
              <InputField
                name="logradouro"
                label="Logradouro"
                placeholder="Logradouro"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField name="numero" label="Numero" placeholder="Numero" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField name="bairro" label="Bairro" placeholder="Bairro" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="complemento"
                label="Complemento"
                placeholder="Complemento"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="cep"
                label="Cep"
                mask="99999-999"
                placeholder="Cep"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="telefone1"
                label="Telefone1"
                placeholder="Telefone1"
                mask="(99)99999-9999"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="telefone2"
                label="Telefone2"
                placeholder="Telefone2"
                mask="(99)99999-9999"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="telefone3"
                label="Telefone3"
                placeholder="Telefone3"
                mask="(99)99999-9999"
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <InputField name="email" label="Email" placeholder="Email" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <EstacaoSelectorField
                label="Estação"
                name="estacao"
                placeholder="Selecione uma estação"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                name="tipoAcesso"
                label="Tipoacesso"
                placeholder="Tipoacesso"
              >
                <option value="PPP">PPP</option>
                <option value="HOTSPOT">Hotspot</option>
                <option value="VLAN">VLAN</option>
                <option value="RADIUS">Radius</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="usuario"
                label="Usuario"
                placeholder="Usuario"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                mask={macMask}
                name="mac"
                label="Mac"
                placeholder="Mac"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField name="vlan" label="Vlan" placeholder="Vlan" />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField name="ip" label="Ip" placeholder="Ip" />
            </GridItem>
            <GridItem colSpan={6}>
              {props.values.isCanceled && (
                <Badge p="0.25em" colorScheme="red">
                  Cancelado em {formatDate(props.values.dataCancelamento)}
                </Badge>
              )}
              {props.values.isBlocked && (
                <Badge p="0.25em" colorScheme="blackAlpha">
                  Bloqueado
                </Badge>
              )}
              {props.values.isAtivado && (
                <Badge p="0.25em" colorScheme="green">
                  Ativado em {formatDate(props.values.dataAtivacao)}
                </Badge>
              )}
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                name="descontoMensalidade"
                label="Desconto Mensalidade"
                placeholder="Desconto da Mensalidade"
                decimalPlaces={2}
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                name="acrescimoMensalidade"
                label="Acréscimo Mensalidade"
                placeholder="Acréscimo da Mensalidade"
                decimalPlaces={2}
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                name="tipoConvenio"
                label="Tipo Convenio"
                placeholder="Selecione um convenio se aplicavel"
              >
                <option value={TipoConvenio.Nenhum}>Nenhum</option>
                <option value={TipoConvenio.Moinhoglobo}>Moinho Globo</option>
                <option value={TipoConvenio.N1Alimentos}>N1 Alimentos</option>
                <option value={TipoConvenio.Trocaservicos}>
                  Troca de Serviços
                </option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputField
                name="codConvenio"
                label="Cód. Convenio"
                placeholder="Cód. Convenio"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <CheckField name="enviaEmailNF" label="Enviar NF via Email" />
            </GridItem>
            {!data?.contrato && (
              <>
                <GridItem colSpan={[6, 3]}>
                  <NumberInput
                    value={valorInstalacao}
                    onChange={(valorInstalacao) =>
                      setValorInstalacao(valorInstalacao)
                    }
                    name="valorInstalacao"
                    label="Valor da Instalação"
                    placeholder="Valor Instalação"
                    decimalPlaces={2}
                    error={valorInstalacaoError}
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <NumberInput
                    value={vezesInstalacao}
                    onChange={(vezesInstalacao) =>
                      setVezesInstalacao(vezesInstalacao)
                    }
                    name="vezesInstalacao"
                    label="Vezes a parcelar a Instalação"
                    placeholder="Vezes Instalação"
                    decimalPlaces={0}
                    error={vezesInstalacaoError}
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <FormControl isInvalid={!!error} mt="0.5em">
                    <Checkbox
                      id="temEntrada"
                      name="temEntrada"
                      onChange={(e) => setTemEntrada(e.target.checked)}
                      isChecked={temEntrada}
                    >
                      Tem Entrada
                    </Checkbox>
                    {temEntradaError && (
                      <FormErrorMessage>{temEntradaError}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </>
            )}
          </Grid>

          <Button
            type="submit"
            mt="1em"
            mb="1rem"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default ContratoForm;
