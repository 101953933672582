import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { Grid, GridItem } from "@chakra-ui/react";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import DateSelector from "../../components/DateComponents/DateSelector";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  DateRangeInput,
  Pagar,
  useDeletePagarMutation,
  usePagarsQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { useIsAuth } from "../../utils/useIsAuth";
import PagarBaixaButton from "./PagarBaixaButton";

const Pagars: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [vencimento, setVencimento] = React.useState({
    inicio: startOfMonth(new Date()),
    fim: new Date(),
  } as DateRangeInput);
  const [isPago, setIsPago] = React.useState(false);

  const [{ data, fetching }, query] = usePagarsQuery({
    variables: { page: page, filter, vencimento, isPago },
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeletePagarMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (pagar: Pagar) => {
    await deleteData({ id: pagar.id });
    setDeleteSuccessMsg(`Pagar ${pagar.numero} foi removido com sucesso!`);
    query();
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Pagar>[] = [
    {
      label: "Numero",
      wrapped: true,
      render: (pagar) => pagar.numero,
    },
    {
      label: "Descricao",
      wrapped: true,
      render: (pagar) =>
        `${pagar.descricao} ${pagar.isRecorrente ? "(Recorrente)" : ""}`,
    },
    {
      label: "Vencimento",
      wrapped: true,
      render: (pagar) => formatDate(pagar.vencimento),
    },
    {
      label: "Valor",
      wrapped: true,
      render: (pagar) => formatNumber(pagar.valor, 2, true),
    },
    {
      label: "Fornecedor",
      wrapped: true,
      render: (pagar) => pagar.fornecedor,
    },
    {
      label: "Valor Pago",
      wrapped: true,
      render: (pagar) => formatNumber(pagar.valor_pago, 2, true),
    },
    {
      label: "Data Pagamento",
      wrapped: true,
      render: (pagar) => (pagar.data_pago ? formatDate(pagar.data_pago) : ""),
    },
    {
      label: "",
      wrapped: true,
      render: (pagar) =>
        !pagar.isPago && (
          <>
            <Link to={`/pagar/${pagar.id}`}>
              <Button colorScheme="orange" type="button">
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </Link>
            <PagarBaixaButton
              pagar={pagar}
              onSuccess={() => {
                console.log("Quering again");
                query();
              }}
            />
            <ConfirmButton
              isLoading={deleteLoading}
              onClick={() => onDeleteConfirm(pagar as Pagar)}
              colorScheme="red"
            >
              <FontAwesomeIcon icon={faTrash} />
            </ConfirmButton>
          </>
        ),
    },
  ];

  return (
    <>
      <Heading mb="1em">Contas a Pagar</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/pagar/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo contas a pagar
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Formik
        initialValues={vencimento}
        onSubmit={(values) => {
          setVencimento(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              py="1rem"
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Vencimento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Vencimento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Confirmar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setVencimento({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      <Flex direction={["column", "row"]} my="1rem">
        <Button disabled={isPago === false} onClick={() => setIsPago(false)}>
          Somente Não Pagos
        </Button>
        <Button disabled={isPago === true} onClick={() => setIsPago(true)}>
          Somente Pagos
        </Button>
        <Button
          disabled={isPago === undefined}
          onClick={() => setIsPago(undefined)}
        >
          Todos
        </Button>
      </Flex>

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.pagars.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.pagars.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Pagars;
