import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Select } from "@chakra-ui/select";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { useSeriesQuery } from "../generated/graphql";

export type SerieSelectorFieldProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
};

const SerieSelectorField: React.FC<SerieSelectorFieldProps> = ({
  label,
  size: _,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  const [{ fetching, data }] = useSeriesQuery({
    variables: {
      page: 1,
    },
  });
  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        name={field.name}
        value={field.value && field.value.id}
        onBlur={field.onBlur}
        onChange={(e) => {
          setValue(
            data.series.data.find(
              (serie) => serie.id === parseInt(e.target.value)
            ),
            false
          );
        }}
        {...props}
        id={field.name}
        placeholder={props.placeholder}
      >
        {!fetching &&
          !!data &&
          data.series.data.map((serie) => (
            <option key={serie.id} value={serie.id}>
              {serie.codigo} - {serie.descricao}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};

export default SerieSelectorField;
