import { Flex, Heading } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Checkbox,
  Button,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import DateSelector from "../../components/DateComponents/DateSelector";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Cobranca,
  DateRangeInput,
  useCobrancasToPrintQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { statusCobranca } from "../../utils/statusCobranca";

export interface BoletosAimprimirProps {}

const BoletosAimprimir: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [data_emissao, setDataEmissao] = React.useState({
    inicio: startOfMonth(new Date()),
    fim: new Date(),
  } as DateRangeInput);

  const [{ data, error, fetching }] = useCobrancasToPrintQuery({
    variables: {
      page,
      isPrinted: false,
      orderBy: [
        { orderField: "cliente.nome" },
        { orderField: "contrato.id" },
        { orderField: "cobranca.vencimento" },
      ],
      filter,
      data_emissao,
    },
    requestPolicy: "network-only",
  });
  if (fetching) {
    return <LoadingWheel />;
  }

  const toggleCobranca = (cobranca: Cobranca) => {
    const indCobId = selectedIds.indexOf(cobranca.id);
    if (indCobId === -1) {
      setSelectedIds([...selectedIds, cobranca.id]);
    } else {
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(indCobId, 1);
      setSelectedIds(newSelectedIds);
    }
  };

  const headers: TableHeaders<Cobranca>[] = [
    {
      label: (
        <>
          <Checkbox
            onChange={() => {
              if (selectedIds.length === data?.cobrancas.data.length) {
                setSelectedIds([]);
              } else {
                setSelectedIds(data?.cobrancas.data.map((cob) => cob.id));
              }
            }}
            isChecked={selectedIds.length === data?.cobrancas.data.length}
          />
        </>
      ),
      render: (cobranca) => (
        <>
          <Checkbox
            isChecked={selectedIds.indexOf(cobranca.id) !== -1}
            onChange={() => toggleCobranca(cobranca)}
            mr="1rem"
          />{" "}
          {`${cobranca.id}`}
        </>
      ),
      wrapped: true,
    },
    {
      label: "Cliente",
      render: (cobranca) => (cobranca.cliente ? cobranca.cliente.nome : ""),
      wrapped: true,
    },
    {
      label: "Contrato",
      render: (cobranca) =>
        cobranca.contrato
          ? `${cobranca.contrato.id} - ${cobranca.contrato.usuario}`
          : "",
      wrapped: true,
    },
    {
      label: "Banco",
      render: (cobranca) => (cobranca.banco ? cobranca.banco.nome : ""),
      wrapped: true,
    },
    {
      label: "Valor",
      render: (cobranca) => formatNumber(cobranca.valor, 2, true),
      wrapped: true,
    },
    {
      label: "Vencimento",
      render: (cobranca) => formatDate(cobranca.vencimento),
      wrapped: true,
    },
    {
      label: "Valor pago",
      render: (cobranca) => formatNumber(cobranca.valor_pago, 2, true),
      wrapped: true,
    },
    {
      label: "Data Pagamento",
      render: (cobranca) => formatDate(cobranca.data_pago),
      wrapped: true,
    },
    {
      label: "Status",
      render: (cobranca) => statusCobranca(cobranca),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1rem">Cobranças pendentes a impressão</Heading>

      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      <Flex flexDirection="row" justifyContent="flex-end" my="2rem">
        <Button
          colorScheme="teal"
          type="button"
          onClick={() => {
            navigate(`/impressao/boleto/${selectedIds.join(",")}`);
          }}
          disabled={selectedIds.length === 0}
        >
          Imprimir as Selecionadas
        </Button>
      </Flex>

      <Formik
        initialValues={data_emissao}
        onSubmit={(values) => {
          setDataEmissao(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Emissão" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Emissão" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Confirmar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setDataEmissao({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>
      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable headers={headers} data={data?.cobrancas.data} />

      <PaginationButtons
        totalPages={data?.cobrancas.totalPages}
        page={page}
        onPageChange={setPage}
      />
    </>
  );
};

export default BoletosAimprimir;
