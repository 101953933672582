import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { Contrato, useAlteraAcessoMutation } from "../../generated/graphql";
import EstacaoSelectorField from "../../components/EstacaoSelectorField";
import SelectField from "../../components/SelectField";
import InputField from "../../components/InputField";
import macMask from "../../utils/macMask";

export type AlterarAcessoModalProps = {
  contrato: Contrato;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const AlterarAcessoModal: React.FC<AlterarAcessoModalProps> = ({
  contrato,
  ...props
}) => {
  const toast = useToast();

  const [, testePlano] = useAlteraAcessoMutation();

  const initialValues = {
    ...contrato
  };

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            console.log("valores", values);
            try {
              const estacao = { ...values.estacao }
              delete estacao.__typename
              const { error } = await testePlano({
                id: contrato.id,
                alteracao: {
                  mac: values.mac,
                  vlan: values.vlan,
                  tipoAcesso: values.tipoAcesso,
                  estacao,
                }
              });

              if (error) {
                toast({
                  title: "Erro ao mudar acesso!",
                  description: `Erro ao mudar acesso do usuario ${error.message}`,
                  duration: 9000,
                  status: "error",
                });
                return;
              }
              toast({
                title: `Alteração efetuada com sucesso!`,
                description: `Alteração efetuada com sucesso!`,
                duration: 9000,
                status: "success",
              });
            } catch (error) {
              console.log("Erro ao alterar acesso", error);
              toast({
                title: "Erro ao alterar acesso!",
                description: `Erro ao alterar acesso: ${error}`,
                duration: 9000,
                status: "error",
              });
            }
          }}
        >
          {() => (
            <Form>
              <ModalHeader>Alterar acesso do usuario {contrato.usuario}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Grid gap={3} templateColumns="repeat(3, 1fr)">
                  <GridItem colSpan={[3, 3]}>
                    <EstacaoSelectorField
                      label="Estação"
                      name="estacao"
                      placeholder="Selecione uma estação"
                    />
                  </GridItem>
                  <GridItem colSpan={[3, 1]}>
                    <SelectField
                      name="tipoAcesso"
                      label="Tipoacesso"
                      placeholder="Tipoacesso"
                    >
                      <option value="PPP">PPP</option>
                      <option value="HOTSPOT">Hotspot</option>
                      <option value="VLAN">VLAN</option>
                      <option value="RADIUS">Radius</option>
                    </SelectField>
                  </GridItem>
                  <GridItem colSpan={[3, 2]}>
                    <InputField
                      mask={macMask}
                      name="mac"
                      label="Mac"
                      placeholder="Mac"
                    />
                  </GridItem>
                  <GridItem colSpan={[3, 1]}>
                    <InputField name="vlan" label="Vlan" placeholder="Vlan" />
                  </GridItem>
                </Grid>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Concluir
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AlterarAcessoModal;

