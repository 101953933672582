import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Form } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CheckField from "../../components/CheckField";
import EstacaoSelectorField from "../../components/EstacaoSelectorField";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import {
  EstacaoInput,
  TipoEstacao,
  useCreateEstacaoMutation,
  useEstacaoQuery,
  useUpdateEstacaoMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novoEstacao: EstacaoInput & { senha: string } = {
  nome: "",
  ip: "",
  usuario: "",
  senha: "",
  tipo: TipoEstacao.Mikrotik,
  isHotspot: false,
  isPPP: false,
  isVLAN: false,
};

const EstacaoForm: React.FC<{}> = () => {
  useIsAuth();
  const id = parseInt(useGetQueryParam("id") as string);
  const navigate = useNavigate();
  const [{ fetching, data }] = useEstacaoQuery({
    variables: { id },
  });
  const [, createMutation] = useCreateEstacaoMutation();
  const [, updateMutation] = useUpdateEstacaoMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.estacao ? "Editar" : "Nova"} Estação`}
      updateMsg={updateMsg}
      initialData={data?.estacao ? data.estacao : novoEstacao}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating estacao", values);
        if (data?.estacao) {
          /// é alteracao
          const { error, data } = await updateMutation({
            estacao: {
              id: values.id,
              nome: values.nome,
              ip: values.ip,
              usuario: values.usuario,
              tipo: values.tipo,
              isHotspot: values.isHotspot,
              isPPP: values.isPPP,
              isVLAN: values.isVLAN,
              estacaoMestre: stripObjectToMutation(values.estacaoMestre),
            },
            senha: values.senha,
          });

          if (error) {
            setErrors({
              isVLAN: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateEstacao.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateEstacao.errors));
          }

          setUpdateMsg("Estação salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({
            estacao: {
              id: values.id,
              nome: values.nome,
              ip: values.ip,
              usuario: values.usuario,
              tipo: values.tipo,
              isHotspot: values.isHotspot,
              isPPP: values.isPPP,
              isVLAN: values.isVLAN,
              estacaoMestre: values.estacaoMestre,
            },
            senha: values.senha,
          });

          if (error) {
            setErrors({
              isVLAN: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createEstacao.errors?.length > 0) {
            setErrors(errorsToFormik(data.createEstacao.errors));
            return;
          }

          navigate(`/estacoes/${data.createEstacao.estacao.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={[6, 3]}>
              <InputField name="nome" label="Nome" placeholder="Nome" />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <InputField name="ip" label="IP" placeholder="IP" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="usuario"
                label="Usuario"
                placeholder="Usuario"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField
                name="senha"
                label="Senha"
                type="password"
                placeholder="Senha"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <SelectField
                name="tipo"
                label="Tipo"
                placeholder="Selecione o Tipo"
              >
                <option value={TipoEstacao.Mikrotik}>Mikrotik</option>
                <option value={TipoEstacao.Ubiquiti}>Ubiquiti</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <CheckField name="isHotspot" label="Tem Hotspot" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <CheckField name="isPPP" label="Tem PPP" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <CheckField name="isVLAN" label="Tem VLAN" />
            </GridItem>
            {props.values.tipo === TipoEstacao.Ubiquiti && (
              <GridItem colSpan={6}>
                <EstacaoSelectorField
                  name="estacaoMestre"
                  label="Estação Mestre"
                  placeholder="Selecione a estação mestre"
                />
              </GridItem>
            )}
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
          <Link to={`/contratosEstacoes/${id}`}>
            <Button colorScheme="orange" type="button">
              Contratos na Estacao
            </Button>
          </Link>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default EstacaoForm;
