import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Olt,
  useDeleteOltMutation,
  useOltsQuery,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const Olts: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching }, query] = useOltsQuery({
    variables: { page: page, filter },
    requestPolicy: "cache-and-network",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteOltMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (olt: Olt) => {
    await deleteData({ id: olt.id });
    setDeleteSuccessMsg(`Olt ${olt.nome} foi removida com sucesso!`);
    query();
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Olt>[] = [
    {
      label: "Olt",
      wrapped: true,
      render: (olt) => olt.nome,
    },
    {
      label: "IP",
      wrapped: true,
      render: (olt) => olt.ip,
    },
    {
      label: " ",
      wrapped: true,
      render: (olt) => (
        <>
          <Link to={`/olts/${olt.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(olt)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Heading mb="1em">OLTs</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/olts/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Nova OLT
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.olts.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.olts.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Olts;
