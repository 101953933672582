// import "./Calendar.css";
import { Button, ButtonProps } from "@chakra-ui/button";
import { Flex } from "@chakra-ui/layout";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addDays,
  addMonths,
  addYears,
  getDay,
  getMonth,
  getYear,
  parseISO,
  startOfMonth,
  subMonths,
  subYears,
} from "date-fns";
import React, { useEffect, useState } from "react";
import formatDate from "../../utils/formatDate";

type DayProps = ButtonProps & {
  selected: string | boolean;
  day: string;
  ind: number;
};

const Day: React.FC<DayProps> = ({ selected, ind, day, ...props }) => (
  <Button
    isActive={selected === day}
    h="min-content"
    px={0}
    py="2px"
    m={0}
    colorScheme={ind === 0 || ind === 6 ? "cyan" : "telegram"}
    {...props}
  >
    {day}
  </Button>
);

export interface CalendarProps {
  value: Date;
  onChange: Function;
}

const Calendar: React.FC<CalendarProps> = (props) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  useEffect(() => {
    if (props.value instanceof Date) {
      setSelectedMonth(props.value);
    } else if (typeof props.value === "string") {
      setSelectedMonth(parseISO(props.value));
    }
  }, [props.value]);

  const prevMonth = (e) => {
    e.preventDefault();
    setSelectedMonth(subMonths(selectedMonth, 1));
  };

  const nextMonth = (e) => {
    e.preventDefault();
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const prevYear = (e) => {
    e.preventDefault();
    setSelectedMonth(subYears(selectedMonth, 1));
  };

  const nextYear = (e) => {
    e.preventDefault();
    setSelectedMonth(addYears(selectedMonth, 1));
  };

  const changeDay = (day: number) => {
    const newDate = addDays(startOfMonth(selectedMonth), day - 1);
    typeof props.onChange === "function" && props.onChange(newDate);
  };

  const selectedDay = () => {
    let val = props.value;
    if (val instanceof Date) {
    } else if (typeof props.value === "string") {
      val = parseISO(props.value);
    } else {
      return false;
    }
    if (
      getMonth(val) === getMonth(selectedMonth) &&
      getYear(val) === getYear(selectedMonth)
    ) {
      return formatDate(val, "dd");
    }
    return false;
  };

  const days = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  const weeks = [];
  const stMonth = getDay(startOfMonth(selectedMonth));
  days.forEach((d) => {
    if (weeks.length === 0) {
      const initalWeek = [];
      for (let i = 0; i < stMonth; i++) {
        initalWeek.push("");
      }
      initalWeek.push(d);
      weeks.push(initalWeek);
    } else {
      if (weeks[weeks.length - 1].length < 7) {
        weeks[weeks.length - 1].push(d);
      } else {
        weeks.push([d]);
      }
    }
  });
  const selDay = selectedDay();
  const weeksTable = weeks.map((week, i) => (
    <tr key={i}>
      {week.map((d, k) => (
        <td key={k}>
          {d && (
            <Day
              selected={selDay}
              onClick={(e) => {
                e.preventDefault();
                changeDay(d);
              }}
              ind={k}
              day={d}
            />
          )}
        </td>
      ))}
    </tr>
  ));
  return (
    <Flex flexShrink={3} direction="column">
      <Flex alignItems="center" justifyContent="center">
        <Button onClick={prevYear}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button onClick={prevMonth}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        {formatDate(selectedMonth, "MMMM/yyyy")}
        <Button onClick={nextMonth}>
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
        <Button onClick={nextYear}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>
      </Flex>
      <table style={{ fontSize: "smaller", minWidth: "min-content" }}>
        <thead>
          <tr>
            <th>D</th>
            <th>S</th>
            <th>T</th>
            <th>Q</th>
            <th>Q</th>
            <th>S</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>{weeksTable}</tbody>
      </table>
    </Flex>
  );
};

export default Calendar;
