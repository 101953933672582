import { ChakraProvider, Flex, theme } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { createClient, Provider } from "urql";
import NavBar from "./components/NavBar";
import SideMenu from "./components/SideMenu";
import AdminOnly from "./pages/admin-only";
import BancoForm from "./pages/bancos/BancoForm";
import Bancos from "./pages/bancos/Bancos";
import CaixaForm from "./pages/caixas/CaixaForm";
import Caixas from "./pages/caixas/Caixas";
import ChamadoForm from "./pages/chamados/ChamadoForm";
import Chamados from "./pages/chamados/Chamados";
import ChangePwd from "./pages/ChangePwd";
import ClienteForm from "./pages/clientes/ClienteForm";
import Clientes from "./pages/clientes/Clientes";
import CobrancaForm from "./pages/cobrancas/CobrancaForm";
import Cobrancas from "./pages/cobrancas/Cobrancas";
import ContratoForm from "./pages/contratos/ContratoForm";
import EstacaoForm from "./pages/estacoes/EstacaoForm";
import Estacaos from "./pages/estacoes/Estacoes";
import ImpressaoBoletos from "./pages/impressao/Boleto";
import BoletosAimprimir from "./pages/impressao/BoletosAimprimir";
import ImpressaoContrato from "./pages/impressao/Contrato";
import ConvenioMoinhoGlobo from "./pages/impressao/ConvenioMoinhoGlobo";
import LancamentosBanco from "./pages/lancamentosBanco/LancamentoBanco";
import LancamentosCaixa from "./pages/lancamentosCaixa/LancamentoCaixa";
import Login from "./pages/Login";
import MudarBancoCobrancas from "./pages/cobrancas/MudarBancoCobrancas";
import NovoChamado from "./pages/NovoChamado";
import PlanoForm from "./pages/planos/PlanoForm";
import Planos from "./pages/planos/Planos";
import ResetPwd from "./pages/ResetPwd";
import UsuarioForm from "./pages/usuarios/UsuarioForm";
import Usuarios from "./pages/usuarios/Usuarios";
import VelocidadeForm from "./pages/velocidades/VelocidadeForm";
import Velocidades from "./pages/velocidades/Velocidades";
import { createUrqlClient } from "./utils/createUrqlClient";
import GerarArquivoCNAB from "./pages/cobrancas/GerarAquivoCNAB";
import CarneContrato from "./pages/impressao/CarneContrato";
import LeArquivoCNAB from "./pages/cobrancas/LeArquivoCNAB";
import Series from "./pages/series/Series";
import SerieForm from "./pages/series/SerieForm";
import Produtos from "./pages/produtos/Produtos";
import ProdutoForm from "./pages/produtos/ProdutoForm";
import Notas from "./pages/notas/Notas";
import NotaForm from "./pages/notas/NotaForm";
import ImpressaoNota from "./pages/notas/ImpressaoNota";
import GeraArquivoNF21 from "./pages/notas/GeraArquivoNF21";
import Pagars from "./pages/pagars/Pagars";
import PagarForm from "./pages/pagars/PagarForm";
import EnviarNFMensal from "./pages/notas/EnviarNFMensal";
import BaixarCobrancas from "./pages/cobrancas/BaixarCobrancas";
import ImpressaoBoletosIndividual from "./pages/impressao/BoletoIndividual";
import GeraCgnatScript from "./pages/utils/geracgnatscript";
import ConvenioTrocaServico from "./pages/notas/ConvenioTrocaServico";
import VerificarBaixasWS from "./pages/cobrancas/VerificarBaixasWS";
import GerarNotas from "./pages/notas/GerarNotas";
import { MostraPix } from "./pages/MostraPix";
import RelatorioCobrancas from "./pages/cobrancas/RelatorioCobrancas";
import RelatorioPagar from "./pages/pagars/RelatorioPagar";
import { MapaAtivos } from "./pages/clientes/MapaAtivos";
import Dre from "./pages/pagars/Dre";
import { EstatisticaClientes } from "./pages/clientes/EstatisticasClientes";
import { OltPlanner } from "./pages/olt/OltPlanner";
import Olts from "./pages/olt/Olts";
import { useIsAuth } from "./utils/useIsAuth";
import ContratosByPlano from "./pages/planos/ContratosByPlano";
import ContratosByEstacao from "./pages/estacoes/ContratosByEstacao";

const client = createClient(createUrqlClient());

export const App = () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Provider value={client}>
        <Flex flexDirection="column" minW="100vw" minH="100vh">
          <NavBar />

          <Flex grow={2} flexDirection="row" minH="100%">
            <SideMenu />
            <Flex
              flexDirection="column"
              minH="100%"
              px="2rem"
              pt="1rem"
              grow={3}
            >
              <Routes>
                <Route element={<MostraPix />} path="/pix/:id" />
                <Route element={<Login />} path="/login" />
                <Route element={<ResetPwd />} path="/resetPwd/:usuario" />
                <Route element={<ChangePwd />} path="/changepwd" />
                <Route element={<AdminOnly />} path="/admin-only" />
                <Route
                  element={<MudarBancoCobrancas />}
                  path="/mudarBancoCobrancas"
                />
                <Route element={<NovoChamado />} path="/NovoChamado" />
                <Route element={<Clientes />} path="/clientes" />
                <Route element={<ClienteForm />} path="/clientes/:id" />
                <Route
                  element={<ContratoForm />}
                  path="/contratos/:cliente/:id"
                />
                <Route element={<Planos />} path="/planos" />
                <Route element={<PlanoForm />} path="/planos/:id" />
                <Route
                  element={<ContratosByPlano />}
                  path="/contratosPlanos/:id"
                />
                <Route element={<Bancos />} path="/bancos" />
                <Route element={<BancoForm />} path="/bancos/:id" />
                <Route element={<Cobrancas />} path="/cobrancas" />
                <Route
                  element={<RelatorioCobrancas />}
                  path="/relatorio_cobrancas"
                />
                <Route element={<CobrancaForm />} path="/cobrancas/:id" />
                <Route
                  element={<BaixarCobrancas />}
                  path="/baixarCobrancas/:ids"
                />
                <Route element={<GerarArquivoCNAB />} path="/geraArquivo" />
                <Route element={<LeArquivoCNAB />} path="/leArquivo" />
                <Route
                  element={<CarneContrato />}
                  path="/carneContrato/:contrato"
                />

                <Route element={<Caixas />} path="/caixas" />
                <Route element={<CaixaForm />} path="/caixas/:id" />
                <Route
                  element={<LancamentosCaixa />}
                  path="/lancamentosCaixa/:caixa"
                />
                <Route
                  element={<LancamentosBanco />}
                  path="/lancamentosBanco/:banco"
                />
                <Route
                  element={<BoletosAimprimir />}
                  path="/impressao/boletosAimprimir"
                />
                <Route
                  element={<ImpressaoBoletos />}
                  path="/impressao/boleto/:ids"
                />
                <Route
                  element={<ImpressaoBoletosIndividual />}
                  path="/impressao/boletoIndividual/:ids"
                />
                <Route
                  element={<ImpressaoContrato />}
                  path="/impressao/contrato/:id"
                />
                <Route element={<Chamados />} path="/chamados" />
                <Route element={<ChamadoForm />} path="/chamados/:id" />
                <Route element={<Estacaos />} path="/estacoes" />
                <Route element={<EstacaoForm />} path="/estacoes/:id" />
                <Route
                  element={<ContratosByEstacao />}
                  path="/contratosEstacoes/:id"
                />
                <Route element={<Velocidades />} path="/velocidades" />
                <Route element={<VelocidadeForm />} path="/velocidades/:id" />
                <Route
                  element={<ConvenioMoinhoGlobo />}
                  path="/impressao/convenioMoinhoGlobo"
                />
                <Route element={<Usuarios />} path="/usuarios" />
                <Route element={<UsuarioForm />} path="/usuarios/:id" />

                <Route element={<Series />} path="/series" />
                <Route element={<SerieForm />} path="/series/:id" />

                <Route element={<Produtos />} path="/produtos" />
                <Route element={<ProdutoForm />} path="/produtos/:id" />

                <Route element={<Notas />} path="/notas" />
                <Route element={<NotaForm />} path="/notas/:id" />
                <Route element={<ImpressaoNota />} path="/impressaoNota/:id" />
                <Route element={<GeraArquivoNF21 />} path="/geraArquivoNF21" />
                <Route element={<EnviarNFMensal />} path="/enviarNFMensal" />

                <Route element={<Pagars />} path="/pagar" />
                <Route element={<PagarForm />} path="/pagar/:id" />
                <Route element={<RelatorioPagar />} path="/relatorio_pagar" />

                <Route element={<GeraCgnatScript />} path="/geraCgnat" />
                <Route
                  element={<ConvenioTrocaServico />}
                  path="/convenioTrocaServicos"
                />
                <Route
                  element={<VerificarBaixasWS />}
                  path="/verificaBaixaWS"
                />

                <Route element={<GerarNotas />} path="/gerarNotas" />
                <Route element={<MapaAtivos />} path="/mapa_clientes" />
                <Route
                  element={<EstatisticaClientes />}
                  path="/estatistica_clientes"
                />
                <Route element={<Dre />} path="/dre" />
                <Route element={<OltPlanner />} path="/olts/:id" />
                <Route element={<Olts />} path="/olts" />

                <Route element={<Index />} path="/" />
                <Route element={<NotFound />} path="*" />
              </Routes>
            </Flex>
          </Flex>
        </Flex>
      </Provider>
    </BrowserRouter>
  </ChakraProvider>
);

const NotFound: React.FC<{}> = () => <Flex>Pagina não encontrada!</Flex>;

const Index: React.FC<{}> = () => {
  useIsAuth();
  return <Flex>Bem-Vindo!</Flex>;
};
