export function formataNumero(
  numero: number | string,
  digitos: number,
  decimais: number = 0,
  rl: "R" | "L" = "L"
) {
  let numeroFormatado = `${numero}`;
  if (typeof numero === "number") {
    numeroFormatado = `${numero.toFixed(decimais)}`;
  }
  if (rl === "R") {
    return numeroFormatado
      .replace(/[^0-9]/g, "")
      .padStart(digitos, "0")
      .substr(0, digitos);
  }
  return numeroFormatado
    .replace(/[^0-9]/g, "")
    .padStart(digitos, "0")
    .substr(-1 * digitos);
}
