import { GridItem, Heading } from "@chakra-ui/layout";
import { Button, Grid, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import BancoSelectorField from "../../components/BancoSelectorField";
import FileField from "../../components/FileField";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  ResultadoLeitura,
  useLeArquivoCnabMutation,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const LeArquivoCNAB: React.FC<{}> = () => {
  useIsAuth();
  const [, leArquivo] = useLeArquivoCnabMutation();
  const toast = useToast();
  const [resultadoLeitura, setResultadoLeitura] = useState(
    [] as ResultadoLeitura[]
  );

  const valoresIniciais = {
    arquivo: null,
    banco: null,
  };

  const headersResultado: TableHeaders<ResultadoLeitura>[] = [
    {
      label: "#",
      wrapped: true,
      render: (resultado) => resultado.numeroDocumento || "S/N",
    },
    {
      label: "Nosso Numero",
      wrapped: true,
      render: (resultado) => resultado.nossoNumero || "S/N",
    },
    {
      label: "Cobrança",
      wrapped: true,
      render: (resultado) =>
        resultado.cobranca ? resultado.cobranca.id : "Não localizada",
    },
    {
      label: "Cliente",
      wrapped: true,
      render: (resultado) => resultado.nomeCliente,
    },
    {
      label: "Movimento",
      wrapped: true,
      render: (resultado) => resultado.movimento,
    },
    {
      label: "Linha",
      wrapped: true,
      render: (resultado) => resultado.linha,
    },
  ];

  return (
    <>
      <Heading>Arquivo a ser lido</Heading>

      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          if (!values.banco) {
            setErrors({
              banco: "Banco deve ser preenchido",
            });
            return;
          }
          if (!values.arquivo) {
            setErrors({
              arquivo: "Arquivo deve ser preenchido",
            });
            return;
          }
          const { data, error } = await leArquivo({
            bancoid: values.banco.id,
            arquivob64: values.arquivo,
          });

          if (error) {
            console.log("erro ao chamar mutation", error, values);
            toast({
              title: "Erro ao ler arquivo CNAB de cobranças",
              description: `Erro: ${error.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          console.log("resultado da leitura", data.leArquivoCNAB);
          setResultadoLeitura(
            data.leArquivoCNAB.resultadoLeitura as ResultadoLeitura[]
          );
          toast({
            title: "Arquivo CNAB de cobranças lido com sucesso",
            description: `Arquivo CNAB de cobranças lido com sucesso, ${data.leArquivoCNAB.resultadoLeitura.length} resultados processados.`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 2]}>
                <BancoSelectorField
                  label="Banco"
                  name="banco"
                  placeholder="Preecha um banco para gerar arquivo"
                />
              </GridItem>
              <GridItem colSpan={[6, 2]}>
                <FileField label="Arquivo CNAB" name="arquivo" />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
      {resultadoLeitura.length > 0 && (
        <>
          <Heading>Resultado da leitura</Heading>
          <ResponsiveTable headers={headersResultado} data={resultadoLeitura} />
        </>
      )}
    </>
  );
};

export default LeArquivoCNAB;
