import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parse } from "date-fns";
import parseISO from "date-fns/parseISO";
import { useField } from "formik";
import React, { InputHTMLAttributes, useEffect, useState } from "react";
// import "./DateSelector.css";
import ReactInputMask from "react-input-mask";
import formatDate from "../../utils/formatDate";
import Calendar from "./Calendar";

type DateSelectorProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  monthOnly?: boolean;
};

const DateSelector: React.FC<DateSelectorProps> = ({
  label,
  monthOnly,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField<Date>(props);
  const [text, setText] = useState("");

  const handleTextChange = (e) => {
    const value = e.target.value;
    if (`${value}`.replace(/[^0-9]/g, "").length >= 8) {
      try {
        const dateParse = parse(
          value,
          "dd/MM/yyyy",
          getDateValue() || new Date()
        );
        if (isNaN(dateParse.getTime())) {
          throw new Error("Invalid date");
        }
        setValue(dateParse, false);
        setText(value);
      } catch (error) {
        console.log("error on parse date", error);
        setText(
          formatDate(getDateValue(), monthOnly ? "MM/yyyy" : "dd/MM/yyyy")
        );
      }
      return;
    }
    setText(value);
  };

  const getDateValue = () => {
    if (field.value instanceof Date) {
      return field.value;
    } else if (typeof field.value === "string") {
      return parseISO(field.value);
    } else if (field.value === undefined) {
      return field.value;
    }
    return new Date();
  };

  const handleTextBlur = () => {
    if (getDateValue() === undefined) {
      return;
    }
    if (monthOnly && `${text}`.replace(/[^0-9]/g, "").length >= 6) {
      try {
        const dateParse = parse(
          "01/" + text,
          "dd/MM/yyyy",
          getDateValue() || new Date()
        );
        if (isNaN(dateParse.getTime())) {
          throw new Error("Invalid date");
        }
        setValue(dateParse, false);
        setText(text);
      } catch (error) {
        setText(formatDate(getDateValue()));
      }
      return;
    }
    if (`${text}`.replace(/[^0-9]/g, "").length >= 8) {
      try {
        const dateParse = parse(
          text,
          "dd/MM/yyyy",
          getDateValue() || new Date()
        );
        if (isNaN(dateParse.getTime())) {
          throw new Error("Invalid date");
        }
        setValue(dateParse, false);
        setText(text);
      } catch (error) {
        setText(formatDate(getDateValue()));
      }
    } else {
      setText(formatDate(getDateValue()));
    }
  };

  useEffect(() => {
    const dateFormat = monthOnly ? "MM/yyyy" : "dd/MM/yyyy";

    if (field.value instanceof Date) {
      setText(formatDate(field.value, dateFormat));
    } else if (typeof field.value === "string") {
      setText(formatDate(parseISO(field.value), dateFormat));
    } else if (field.value === undefined) {
      setText("");
    } else {
      setText(formatDate(new Date(), dateFormat));
    }
  }, [field.value, monthOnly]);

  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Popover placement="bottom-start" isLazy>
        <InputGroup>
          <ReactInputMask
            name={field.name}
            id={field.name}
            value={text}
            maskPlaceholder=" "
            mask={monthOnly ? "99/9999" : "99/99/9999"}
            onBlur={handleTextBlur}
            onChange={handleTextChange}
            {...props}
          >
            <Input />
          </ReactInputMask>
          {/* <Input name={field.name} id={field.name} value={text} disabled /> */}

          <InputRightElement>
            <PopoverTrigger>
              <div>
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </PopoverTrigger>
          </InputRightElement>
        </InputGroup>
        <PopoverContent bgColor="white">
          <PopoverBody flexDirection="column">
            <Calendar value={field.value} onChange={(date) => setValue(date)} />
            <Button
              colorScheme="teal"
              mt="0.25rem"
              onClick={(e) => {
                e.preventDefault();
                setValue(new Date());
              }}
            >
              Hoje
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </FormControl>
  );
};

export default DateSelector;
