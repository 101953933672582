import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  faPen,
  faPlus,
  faTrash,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useEffect } from "react";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Cliente,
  useClientesQuery,
  useDeleteClienteMutation,
} from "../../generated/graphql";
import { useInitialFilter } from "../../utils/useInitialFilter";
import { useIsAuth } from "../../utils/useIsAuth";
import _ from "lodash";
import { Link } from "react-router-dom";

const Clientes: React.FC<{}> = () => {
  useIsAuth();
  const initialFilter = useInitialFilter();

  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState(initialFilter);
  const [{ data, fetching }, query] = useClientesQuery({
    variables: { page, filter },
    requestPolicy: "cache-and-network",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteClienteMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (cliente: Cliente) => {
    await deleteData({ id: cliente.id });
    setDeleteSuccessMsg(`Cliente ${cliente.nome} foi removido com sucesso!`);
    query();
  };

  useEffect(() => {
    console.log("setting initial filter");
    setFilter(initialFilter);
  }, [initialFilter]);
  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Cliente>[] = [
    {
      label: "Nome",
      render: (item) => item.nome,
    },
    {
      label: "CPF/CNPJ",
      render: (item) => item.isncNacional,
    },
    {
      label: "RG/IE",
      render: (item) => item.isncEstadual,
    },
    {
      label: "Email",
      render: (item) => item.email,
    },
    {
      label: "Telefone",
      render: (item) => item.telefone1,
    },
    {
      label: "Endereço",
      render: (item) => `${item.logradouro}, ${item.numero}`,
    },
    {
      label: "Cidade",
      render: (item) => `${item.cidade}-${item.uf}`,
    },
    {
      label: "Status",
      render: (item) =>
        `${item.isActive ? "Ativo" : "Desativado"}/${
          item.contratos.length > 0
            ? item.contratos.reduce(
                (p, c) => p && c.isAtivado && !c.isCanceled,
                true
              )
              ? "Contratos Ativos"
              : "Contratos cancelados"
            : "Nenhum contrato"
        }`,
    },
    {
      label: " ",
      wrapped: true,
      render: (item) => (
        <>
          <Link to={`/clientes/${item.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(item as Cliente)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Heading mb="1em">Clientes</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/clientes/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo cliente
          </Button>
        </Link>
        <Link to="/mapa_clientes">
          <Button colorScheme="gray" type="button">
            <FontAwesomeIcon icon={faMapMarked} /> Ver clientes no mapa
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter
        value={filter}
        onChange={(filter) => {
          setFilter(filter);
          setPage(1);
        }}
      />

      <ResponsiveTable
        data={_.orderBy(data?.clientes.data, (a) =>
          a.contratos.reduce((p, c) => p && c.isAtivado && !c.isCanceled, true)
            ? 1
            : 2
        )}
        headers={headers}
      />
      <PaginationButtons
        totalPages={data?.clientes.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Clientes;
