import { Heading } from "@chakra-ui/layout";
import { Button, Checkbox, Flex, Text } from "@chakra-ui/react";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import {
  Chamado,
  StatusChamado,
  useChamadosByClienteQuery,
} from "../generated/graphql";
import formatDate from "../utils/formatDate";
import { formatStatusChamado } from "../utils/formatStatusChamado";
import { formatTipoChamado } from "../utils/formatTipoChamado";
import Filter from "./Filter";
import PaginationButtons from "./PaginationButtons";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";

export interface ChamadosProps {
  clienteid: number;
}

const ChamadosByCliente: React.FC<ChamadosProps> = ({ clienteid }) => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [statusExibidos, setStatusExibidos] = useState([
    StatusChamado.Iniciado,
    StatusChamado.Aguardando,
    StatusChamado.Emandamento,
    StatusChamado.Material,
  ]);

  const [{ data }] = useChamadosByClienteQuery({
    variables: {
      filter,
      page,
      status: statusExibidos,
      clienteid,
    },
    requestPolicy: "network-only",
  });

  const toggleStatus = (status: StatusChamado) => {
    const indStatus = statusExibidos.indexOf(status);
    if (indStatus === -1) {
      setStatusExibidos([...statusExibidos, status]);
    } else {
      const statusNovo = [...statusExibidos];
      statusNovo.splice(indStatus);
      setStatusExibidos(statusNovo);
    }
  };

  const headers: TableHeaders<Chamado>[] = [
    {
      label: "#",
      render: (item) => `${item.id}`,
      wrapped: true,
    },
    {
      label: "Inicio",
      render: (item) => formatDate(item.inicio),
      wrapped: true,
    },
    {
      label: "Descrição",
      render: (item) =>
        item.iteracoes &&
        item.iteracoes.length > 0 &&
        item.iteracoes[0].descricao,
    },
    {
      label: "Tipo",
      render: (item) => formatTipoChamado(item.tipo),
      wrapped: true,
    },
    {
      label: "Status",
      render: (item) => formatStatusChamado(item.status),
      wrapped: true,
    },
    {
      label: " ",
      render: (item) => (
        <Link to={`/chamados/${item.id}`}>
          <Button colorScheme="green">
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </Link>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mt="2rem" mb="1rem">
        Chamados
      </Heading>

      <Flex direction={["column", "row"]} mb="1rem">
        <Link to={`/NovoChamado?cliente=${clienteid}`}>
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo chamado
          </Button>
        </Link>
        <Text ml="auto" mr="2rem">
          Status Exibidos:
        </Text>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Iniciado) !== -1}
          onChange={() => toggleStatus(StatusChamado.Iniciado)}
        >
          Iniciado
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Aguardando) !== -1}
          onChange={() => toggleStatus(StatusChamado.Aguardando)}
        >
          Em Andamento
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Emandamento) !== -1}
          onChange={() => toggleStatus(StatusChamado.Emandamento)}
        >
          Aguardando cliente
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Material) !== -1}
          onChange={() => toggleStatus(StatusChamado.Material)}
        >
          Aguardando material
        </Checkbox>
        <Checkbox
          mr="2rem"
          isChecked={statusExibidos.indexOf(StatusChamado.Fechado) !== -1}
          onChange={() => toggleStatus(StatusChamado.Fechado)}
        >
          Fechado
        </Checkbox>
      </Flex>

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.chamados.data} headers={headers} />

      {/* <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Inicio</Th>
            <Th>Descrição</Th>
            <Th>Tipo</Th>
            <Th>Status</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.chamados.data.map((chamado) => (
            <Tr key={chamado.id}>
              <Td whiteSpace="nowrap" width="1%">
                {chamado.id}
              </Td>
              <Td whiteSpace="nowrap" width="1%">
                {formatDate(chamado.inicio)}
              </Td>
              <Td>
                <Text fontSize="0.75rem">
                  {chamado.iteracoes &&
                    chamado.iteracoes.length > 0 &&
                    chamado.iteracoes[0].descricao}
                </Text>
              </Td>
              <Td whiteSpace="nowrap" width="1%">
                {formatTipoChamado(chamado.tipo)}
              </Td>
              <Td whiteSpace="nowrap" width="1%">
                {formatStatusChamado(chamado.status)}
              </Td>
              <Td whiteSpace="nowrap" width="1%">
                <Link href={`/chamados/${chamado.id}`}>
                  <Button colorScheme="green">
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table> */}

      <PaginationButtons
        totalPages={data?.chamados.totalPages}
        page={page}
        onPageChange={setPage}
      />
    </>
  );
};

export default ChamadosByCliente;
