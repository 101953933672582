import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { Grid, GridItem, Text } from "@chakra-ui/react";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Cobranca,
  DateRangeInput,
  useRelatorioCobrancasMutation,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { statusCobranca } from "../../utils/statusCobranca";
import { useIsAuth } from "../../utils/useIsAuth";

const RelatorioCobrancas: React.FC<{}> = () => {
  useIsAuth();

  const [filter, setFilter] = React.useState("");
  const [vencimento, setVencimento] = React.useState({
    inicio: startOfMonth(new Date()),
    fim: new Date(),
  } as DateRangeInput);
  const [pagamento, setPagamento] = React.useState({} as DateRangeInput);
  const [pagoFilter, setPagoFilter] = React.useState(false);
  const [{ data, fetching, error }, query] = useRelatorioCobrancasMutation();
  const [errorMessage, setErrorMessage] = React.useState("");

  const onQuery = async () => {
    await query({
      isPago: pagoFilter,
      pagamento: pagamento.inicio && pagamento.fim && pagamento,
      vencimento: vencimento.inicio && vencimento.fim && vencimento,
    });
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const totalValor =
    data?.relatorio_cobrancas?.reduce((a, p) => a + p.valor, 0) || 0;
  const totalValorPago =
    data?.relatorio_cobrancas?.reduce((a, p) => a + p.valor_pago, 0) || 0;
  const qtdPago =
    data?.relatorio_cobrancas?.filter((cob) => cob.isPago).length || 0;
  const _totalPagoPorUsuario = _.groupBy(
    data?.relatorio_cobrancas,
    (cobranca) => cobranca.usuarioPago || "nao_pago"
  );
  const totalPagoPorUsuario: Record<string, number> = {};
  for (const totalUsuario in _totalPagoPorUsuario) {
    const total =
      _totalPagoPorUsuario[totalUsuario].reduce(
        (a, p) => a + p.valor_pago,
        0
      ) || 0;
    totalPagoPorUsuario[totalUsuario] = total;
  }

  const headers: TableHeaders<Cobranca>[] = [
    {
      label: "#",
      render: (cobranca) => `${cobranca.cobrancaImportada || cobranca.id}`,
      wrapped: true,
    },
    {
      label: "Cliente",
      render: (cobranca) => (cobranca.cliente ? cobranca.cliente.nome : ""),
      wrapped: true,
    },
    {
      label: "Contrato",
      render: (cobranca) =>
        cobranca.contrato
          ? `${cobranca.contrato.id} - ${cobranca.contrato.usuario}`
          : "",
      wrapped: true,
    },
    {
      label: "Banco/Carteira",
      render: (cobranca) =>
        `${cobranca.banco ? cobranca.banco.nome : ""}/${
          cobranca.codClienteBol || cobranca.banco?.codCliente
        }`,
      wrapped: true,
    },
    {
      label: "Valor",
      render: (cobranca) => formatNumber(cobranca.valor, 2, true),
      wrapped: true,
    },
    {
      label: "Vencimento",
      render: (cobranca) => formatDate(cobranca.vencimento),
      wrapped: true,
    },
    {
      label: "Valor pago",
      render: (cobranca) => formatNumber(cobranca.valor_pago, 2, true),
      wrapped: true,
    },
    {
      label: "Data Pagamento",
      render: (cobranca) => formatDate(cobranca.data_pago),
      wrapped: true,
    },
    {
      label: "Status",
      render: (cobranca) => statusCobranca(cobranca),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Relatório de Cobranças</Heading>

      {!!errorMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{errorMessage}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMessage("")}
          />
        </Alert>
      )}

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      <Flex direction={["column", "row"]}>
        <Button
          disabled={pagoFilter === false}
          onClick={() => setPagoFilter(false)}
        >
          Somente Não Pagos
        </Button>
        <Button
          disabled={pagoFilter === true}
          onClick={() => setPagoFilter(true)}
        >
          Somente Pagos
        </Button>
        <Button
          disabled={pagoFilter === undefined}
          onClick={() => setPagoFilter(undefined)}
        >
          Todos
        </Button>
      </Flex>

      <Formik
        initialValues={vencimento}
        onSubmit={(values) => {
          setVencimento(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Vencimento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Vencimento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Aplicar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setVencimento({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={pagamento}
        onSubmit={(values) => {
          setPagamento(values);
        }}
      >
        {() => (
          <Form>
            <Grid
              gap={[2, 6]}
              mb="1rem"
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="inicio" label="Inicio Pagamento" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="fim" label="Fim Pagamento" />
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Aplicar
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setPagamento({} as DateRangeInput)}
                    colorScheme="orange"
                    mx="0.5rem"
                  >
                    Zerar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>

      <Button
        type="button"
        onClick={() => onQuery()}
        colorScheme="green"
        mx="0.5rem"
      >
        Concluir
      </Button>
      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.relatorio_cobrancas} headers={headers} />

      <Text fontSize="2xl" fontWeight="bold" my="1rem">
        Total de cobranças ({data?.relatorio_cobrancas?.length}):{" "}
        {formatNumber(totalValor, 2, true)}
      </Text>
      <Text fontSize="2xl" fontWeight="bold" my="1rem">
        Total pago ({qtdPago}): {formatNumber(totalValorPago, 2, true)}
      </Text>
      <Text fontSize="2xl" fontWeight="bold" my="1rem">
        Total a receber ({data?.relatorio_cobrancas?.length - qtdPago}):{" "}
        {formatNumber(totalValor - totalValorPago, 2, true)}
      </Text>
      {_.map(totalPagoPorUsuario, (valor, usuario) => (
        <Text key={`${usuario}`} fontSize="2xl" fontWeight="bold" my="1rem">
          Total recebido por {usuario} {formatNumber(valor, 2)}
        </Text>
      ))}
    </>
  );
};

export default RelatorioCobrancas;
