import { Font, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import * as React from "react";
import { Contrato } from "../generated/graphql";
import { formatNumber } from "../utils/formatNumber";

export interface ContratoProps {
  contrato: Contrato;
}

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

const PrintContrato: React.FC<ContratoProps> = ({ contrato }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        marginTop: "1cm",
        marginLeft: "1cm",
        marginRight: "1cm",
        width: "19cm",
        fontSize: "10pt",
        fontFamily: "Arial",
        fontWeight: "normal",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          marginBottom: "1rem",
        }}
      >
        <Text>Numero: {contrato.id}</Text>
        <Text
          style={{
            fontSize: "16pt",
            flexGrow: 7,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Termo de Adesão - Acesso a Rede SCM
        </Text>
      </View>
      <Text>
        A aceitação deste termo importa em adesão as clausulas e condições
        previstas no Contrato registrado no Cartório de Títulos e Documentos de
        Setanópolis-PR sob num. 8789 por N G B PIRES CIA LTDA CNPJ
        10.487.701/0001-50.
      </Text>
      <Text>
        Disponível no endereço: http://www.gabrimar.com.br/contratoassinante.pdf
      </Text>
      <Text>
        Fone Comercial e Suporte Técnico (43) 3232-1627 suporte@gabrimar.com.br
      </Text>
      <View
        style={{
          borderStyle: "solid",
          borderColor: "#000",
          borderWidth: 1,
          marginBottom: "2mm",
          marginTop: "2mm",
          padding: "1mm",
        }}
      >
        <Text>Importante:</Text>
        <Text>
          Sujeito a Viabilidade Técnica. O prazo de instalação previsto é de até
          30 dias da assinatura do contrato. Em edifícios não atendidos, o prazo
          será contado a partir da apresentação da autorização para instalações
          e cabeamento no condomínio. Nas instalações que dependem de terceiros,
          como ADSL ou Wireless, confirme prazos com os respectivos prestadores.
          O login de acesso junto ao provedor é liberado no ato do
          cadastramento. O cancelamento deve ser solicitado eletronicamente em
          protocolo ou pessoalmente. Nunca por telefone ou e-mail, SMS ou outras
          formas de mensagens eletrônicas.
        </Text>
      </View>

      <Text>
        Plano: {contrato.plano.nome}, Mensalidade: R${" "}
        {formatNumber(contrato.plano.mensalidade, 2, true)}, Tx. de Ativação: R${" "}
        {formatNumber(contrato.plano.valorInstalacao + 150, 2, true)} com R$
        150,00 de desconto
      </Text>
      <Text>
        Vencimento: Todo dia {contrato.plano.diaVencimento} de cada mês{" "}
      </Text>
      <Text>Usuario: {contrato.usuario}</Text>
      <Text>Senha Inicial: 123456 </Text>
      <Text>
        Os equipamentos do Objeto deste contrato são fornecidos sobre o regime
        de comodato, ou seja, deverão ser devolvidos a Contratada quaisquer
        equipamentos que forem instalados no cliente, na ocorrencia da recisão
        do contrato por qualquer das partes.
      </Text>

      <Text style={{ fontWeight: "bold", marginTop: "2mm" }}>Opcionais:</Text>
      <Text>Suporte Telefonico: Incluso</Text>
      <Text>Taxa de visita: R$ 20,00</Text>
      <Text>Taxa de Recuperação de Conector: R$ 30,00</Text>

      <Text style={{ marginTop: "1cm" }}>
        {contrato.cliente.tipo === "FISICO" ? "Nome Completo" : "Razão Social"}:{" "}
        {contrato.cliente.nome}
      </Text>
      <Text>
        {contrato.cliente.tipo === "FISICO" ? "CPF" : "CNPJ"}:{" "}
        {contrato.cliente.isncNacional}
      </Text>
      <Text>
        Endereço: {contrato.logradouro}, {contrato.numero}
      </Text>
      <Text>Bairro: {contrato.bairro}</Text>
      <Text>
        Cidade: {contrato.cidade} - {contrato.uf}
      </Text>
      <Text>CEP: {contrato.cep}</Text>
      <Text>Fone: {contrato.telefone1}</Text>
      <Text>Modo de Pagamento: Carnê</Text>
      <Text style={{ marginTop: "1cm", marginBottom: "1cm" }}>
        Sertanópolis,{" "}
        {format(new Date(), "dd 'de' MMMM 'de' yyyy", {
          locale: ptBR,
        })}
      </Text>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text>Assinatura:______________________________</Text>
        <Text
          style={{
            borderStyle: "solid",
            borderColor: "#000",
            borderTopWidth: 1,
            paddingHorizontal: "5mm",
          }}
        >
          N G B Pires CIA LTDA - ME
        </Text>
      </View>

      <Text style={{ fontSize: "6pt", fontWeight: "bold" }}>
        Pela assinatura acima o cliente confirma que esta ciente de todos os
        termos e condições do serviço e concorda totalmente com o mesmo.
      </Text>
    </View>
  );
};

export default PrintContrato;
