import { Button } from "@chakra-ui/button";
import { Badge, Flex, Grid, GridItem, Heading } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Td, Tr } from "@chakra-ui/table";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays, subDays } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import ConfirmButton from "../../components/ConfirmButton";
import CreateLancamentoBanco from "../../components/CreateLancamentoBanco";
import DateSelector from "../../components/DateComponents/DateSelector";
import FecharBancoModal from "../../components/FecharBancoModal";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Lancamentobanco,
  useBancoQuery,
  useDeleteLancamentoBancoMutation,
  useLancamentosBancoQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { useGetQueryParam } from "../../utils/useGetQueryParam";

const LancamentosBanco: React.FC<{}> = (props) => {
  const [tpFiltro, setTpFiltro] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [data_inicial, setDataInicial] = useState(new Date());
  const [data_final, setDataFinal] = useState(new Date());

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const bancoParam = parseInt(useGetQueryParam("banco") as string);

  const [{ data, fetching, error }, refreshQuery] = useLancamentosBancoQuery({
    variables: {
      bancoid: bancoParam,
      data_inicial,
      data_final,
      page,
    },
    requestPolicy: "cache-and-network",
  });
  const [{ data: bancoData, fetching: bancoFetching }] = useBancoQuery({
    variables: { id: bancoParam },
  });

  let saldoFinal = 0;
  if (data) {
    saldoFinal += data.lancamentobancos.saldoAnterior;
    for (let lancamento of data.lancamentobancos.data) {
      if (lancamento.isFechamento) {
        saldoFinal = lancamento.valor;
      } else {
        saldoFinal += lancamento.valor;
      }
    }
  }

  const [, deleteRequest] = useDeleteLancamentoBancoMutation();

  if (fetching || bancoFetching) {
    return <LoadingWheel />;
  }

  const onDeleteConfirm = async (id) => {
    setDeleteLoading(true);
    const { error } = await deleteRequest({ id });
    if (error) {
      setErrorMessage(error.message.replace("[GraphQL]", ""));
    } else {
      setSuccessMessage(`Lançamento ${id} removido com sucesso!`);
      refreshQuery();
    }
    setDeleteLoading(false);
  };

  const handleTpFiltroChange = (filtro: number): void => {
    setTpFiltro(filtro);

    switch (filtro) {
      case -1:
        setDataFinal(addDays(new Date(), 45));
        setDataInicial(new Date());
        break;
      case 3:
        setDataFinal(new Date());
        setDataInicial(subDays(new Date(), 3));
        break;
      case 7:
        setDataFinal(new Date());
        setDataInicial(subDays(new Date(), 7));
        break;
      case 15:
        setDataFinal(new Date());
        setDataInicial(subDays(new Date(), 15));
        break;
      case 30:
        setDataFinal(new Date());
        setDataInicial(subDays(new Date(), 30));
        break;
      case 99:
        break;
      default:
        setDataInicial(new Date());
        setDataFinal(new Date());
    }
  };

  const headers: TableHeaders<Lancamentobanco>[] = [
    // <Th>#</Th>
    // <Th>Data</Th>
    // <Th>Descrição</Th>
    // <Th>Valor</Th>
    // <Th>&nbsp;</Th>
    {
      label: "#",
      render: (lancamento) => lancamento.id,
      wrapped: true,
    },
    {
      label: "Data",
      render: (lancamento) => formatDate(lancamento.data),
      wrapped: true,
    },
    {
      label: "Descrição",
      render: (lancamento) => lancamento.descricao,
    },
    {
      label: "Valor",
      render: (lancamento) => formatNumber(lancamento.valor, 2, true),
      wrapped: true,
    },
    {
      label: " ",
      render: (lancamento) => (
        <>
          <ConfirmButton
            isLoading={deleteLoading}
            size="xs"
            onClick={() => onDeleteConfirm(lancamento.id)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
    },
  ];
  return (
    <>
      <Heading mb="1em">
        Banco{" "}
        {bancoData &&
          bancoData.banco &&
          bancoData.banco.data &&
          bancoData.banco.data.nome}
      </Heading>

      <FecharBancoModal
        banco={bancoParam}
        isOpen={isOpen}
        onSuccess={() => refreshQuery()}
        onClose={onClose}
      />

      <Heading>Filtros</Heading>
      <Flex
        direction={["column", "row"]}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          disabled={-1 === tpFiltro}
          onClick={() => handleTpFiltroChange(-1)}
          colorScheme="blue"
          mx="0.5rem"
          my={["0.5rem", "1rem"]}
          type="button"
        >
          Futuro
        </Button>
        <Button
          disabled={0 === tpFiltro}
          onClick={() => handleTpFiltroChange(0)}
          colorScheme="blue"
          mx="0.5rem"
          my={["0.5rem", "1rem"]}
          type="button"
        >
          Hoje
        </Button>
        <Button
          disabled={3 === tpFiltro}
          onClick={() => handleTpFiltroChange(3)}
          colorScheme="blue"
          mx="0.5rem"
          my={["0.5rem", "1rem"]}
          type="button"
        >
          3 dias
        </Button>
        <Button
          disabled={7 === tpFiltro}
          onClick={() => handleTpFiltroChange(7)}
          colorScheme="blue"
          mx="0.5rem"
          my={["0.5rem", "1rem"]}
          type="button"
        >
          7 dias
        </Button>
        <Button
          disabled={15 === tpFiltro}
          onClick={() => handleTpFiltroChange(15)}
          colorScheme="blue"
          mx="0.5rem"
          my={["0.5rem", "1rem"]}
          type="button"
        >
          15 dias
        </Button>
        <Button
          disabled={30 === tpFiltro}
          onClick={() => handleTpFiltroChange(30)}
          colorScheme="blue"
          mx="0.5rem"
          my={["0.5rem", "1rem"]}
          type="button"
        >
          30 dias
        </Button>
        <Button
          disabled={99 === tpFiltro}
          onClick={() => handleTpFiltroChange(99)}
          colorScheme="blue"
          mx="0.5rem"
          my={["0.5rem", "1rem"]}
          type="button"
        >
          Selecionar Período
        </Button>
      </Flex>

      {tpFiltro === 99 && (
        <Formik
          initialValues={{
            data_inicial,
            data_final,
          }}
          onSubmit={(values) => {
            setDataInicial(values.data_inicial);
            setDataFinal(values.data_final);
          }}
        >
          {() => (
            <Form>
              <Grid
                gap={[2, 6]}
                templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
              >
                <GridItem colSpan={2}>
                  <DateSelector name="data_inicial" label="Inicio" />
                </GridItem>
                <GridItem colSpan={2}>
                  <DateSelector name="data_final" label="Fim" />
                </GridItem>
                <GridItem>
                  <Flex
                    minH="100%"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Button type="submit" colorScheme="blue" mx="0.5rem">
                      Confirmar
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      {!!successMessage && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{successMessage}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setSuccessMessage("")}
          />
        </Alert>
      )}

      {!!errorMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{errorMessage}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMessage("")}
          />
        </Alert>
      )}

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {error.message.replace(/\[.+\]/, "")}
          </AlertDescription>
        </Alert>
      )}

      <CreateLancamentoBanco
        banco={bancoParam}
        onAddSuccess={() => refreshQuery()}
      />

      <ResponsiveTable
        data={data?.lancamentobancos.data}
        headers={headers}
        isSmall={true}
        thead={
          <Tr>
            <Td display={["none", "table-cell"]} colSpan={3}>
              {" "}
              Saldo anterior{" "}
            </Td>
            <Td display={["block", "table-cell"]} colSpan={2}>
              <Badge display={["inline", "none"]} colorScheme="teal">
                Saldo anterior
              </Badge>
              {formatNumber(data?.lancamentobancos.saldoAnterior, 2, true)}
            </Td>
          </Tr>
        }
        tfoot={
          <Tr>
            <Td display={["none", "table-cell"]} colSpan={3}>
              {" "}
              Saldo Final{" "}
            </Td>
            <Td display={["block", "table-cell"]} colSpan={2}>
              <Badge display={["inline", "none"]} colorScheme="teal">
                Saldo Final
              </Badge>
              {formatNumber(saldoFinal, 2, true)}
            </Td>
          </Tr>
        }
      />

      <PaginationButtons
        totalPages={data?.lancamentobancos.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />

      <Flex
        m="0.75rem"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button onClick={onOpen} float="right" colorScheme="teal">
          Fechar banco
        </Button>
      </Flex>
    </>
  );
};

export default LancamentosBanco;
