import React from "react";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import citiesA from "../utils/cities.json";
import Autocomplete from "./Autocomplete";

type City = {
  "ConcatUF+Mun": string;
  IBGE: number;
  IBGE7: number;
  UF: string;
  Município: string;
  Região: string;
  "População 2010": number;
  Porte: string;
  Capital: string;
  FIELD10: string;
};
const cities = citiesA as City[];

type CitySelectorProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  UF?: string;
  onCitySelect?: (City) => void;
};

const CitySelectorField: React.FC<CitySelectorProps> = ({
  label,
  UF,
  size: _,
  onCitySelect,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  const filter = (val, opt) => {
    if (!!UF && UF !== opt.UF) {
      return false;
    }
    return opt["Município"]
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .startsWith(val.toLowerCase());
  };

  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Autocomplete
        items={cities}
        filter={filter}
        itemLabel={(item) => `${item["Município"]}`}
        renderItem={(item) => `${item["Município"]} - ${item.UF}`}
        onSelectItem={(item) => {
          setValue(`${item["Município"]}`, false);
          typeof onCitySelect === "function" && onCitySelect(item);
        }}
        value={field.value}
        placeholder="Selecione uma cidade..."
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default CitySelectorField;
