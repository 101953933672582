import { View, Text, StyleSheet } from "@react-pdf/renderer";
import _ from "lodash";
import * as React from "react";
import { Pagar } from "../generated/graphql";
import formatDate from "../utils/formatDate";
import { formatNumber } from "../utils/formatNumber";

export interface PdfPagarProps {
  pagars: Pagar[];
}
const styles = StyleSheet.create({
  table: {
    // @ts-ignore
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableHead: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
  },
});

const PdfPagar: React.FC<PdfPagarProps> = ({ pagars }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        marginTop: "1cm",
        marginLeft: "1cm",
        marginRight: "1cm",
        width: "19cm",
      }}
    >
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableCol, width: "10%" }}>
            <Text style={styles.tableHead}>#</Text>
          </View>
          <View style={{ ...styles.tableCol, width: "40%" }}>
            <Text style={styles.tableHead}>Descrição</Text>
          </View>
          <View style={{ ...styles.tableCol, width: "10%" }}>
            <Text style={styles.tableHead}>Valor</Text>
          </View>
          <View style={{ ...styles.tableCol, width: "10%" }}>
            <Text style={styles.tableHead}>Vencimento</Text>
          </View>
          <View style={{ ...styles.tableCol, width: "10%" }}>
            <Text style={styles.tableHead}>Valor pago</Text>
          </View>
          <View style={{ ...styles.tableCol, width: "10%" }}>
            <Text style={styles.tableHead}>Data pagamento</Text>
          </View>
          <View style={{ ...styles.tableCol, width: "10%" }}>
            <Text style={styles.tableHead}>Status</Text>
          </View>
        </View>
        {pagars.map((pagar, i) => (
          <View style={styles.tableRow} key={`${i}`}>
            <View style={{ ...styles.tableCol, width: "10%" }}>
              <Text style={styles.tableCell}>{`${pagar.id}`.trim()}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "40%" }}>
              <Text style={styles.tableCell}>{pagar.descricao}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "10%" }}>
              <Text style={styles.tableCell}>
                {formatNumber(pagar.valor, 2, true)}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "10%" }}>
              <Text style={styles.tableCell}>
                {formatDate(pagar.vencimento)}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "10%" }}>
              <Text style={styles.tableCell}>
                {formatNumber(pagar.valor_pago, 2, true)}{" "}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "10%" }}>
              <Text style={styles.tableCell}>
                {formatDate(pagar.data_pago)}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "10%" }}>
              <Text style={styles.tableCell}>
                {pagar.isPago ? "Pago" : "Devedor"}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default PdfPagar;
