import { Tooltip, Button } from "@chakra-ui/react";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Contrato } from "../../generated/graphql";
import CancelaContratoModal from "./CancelaContratoModal";

export interface CancelarContratoButtonProps {
  contrato: Contrato;
  onSuccess?: () => void;
}

const CancelarContratoButton: React.FC<CancelarContratoButtonProps> = ({
  contrato,
  onSuccess,
}) => {
  const [isCancelarModalOpen, setIsCancelarModalOpen] = useState(false);
  return (
    <>
      <CancelaContratoModal
        contrato={contrato}
        isOpen={isCancelarModalOpen}
        onClose={() => setIsCancelarModalOpen(false)}
        onSuccess={onSuccess}
      />
      <Tooltip label="Cancelar contrato">
        <Button
          onClick={() => setIsCancelarModalOpen(true)}
          colorScheme="red"
          type="button"
        >
          <FontAwesomeIcon icon={faBan} />
        </Button>
      </Tooltip>
    </>
  );
};

export default CancelarContratoButton;
