import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import { useField } from "formik";
import { useState } from "react";

type FileFieldProps = InputProps & {
  name: string;
  label: string;
};

const FileField: React.FC<FileFieldProps> = ({ label, size: _, ...props }) => {
  const [field, { error }, { setValue }] = useField(props.name);
  const [fileName, setFileName] = useState("");
  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={props.name}>
        {label} ({fileName || "Nenhum arquivo selecionado"})
      </FormLabel>
      <Input
        type="file"
        id={field.name}
        onChange={(e) => {
          const reader = new FileReader();
          const fName = e.currentTarget.files[0].name;

          reader.onloadend = () => {
            setValue(reader.result);
            setFileName(fName);
          };
          reader.readAsDataURL(e.currentTarget.files[0]);
        }}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default FileField;
