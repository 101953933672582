import { Heading } from "@chakra-ui/layout";
import { Document, Page, PDFViewer } from "@react-pdf/renderer";
import * as React from "react";
import LoadingWheel from "../../components/LoadingWheel";
import {
  Contrato,
  useBoletoInstalacaoContratoQuery,
  useContratoQuery,
} from "../../generated/graphql";
import { Boleto } from "../../pdfComponents/Boleto";
import PrintContrato from "../../pdfComponents/Contrato";
import TermoCancelamento from "../../pdfComponents/TermoCancelamento";
import getEnv from "../../utils/getEnv";
import { useGetQueryParam } from "../../utils/useGetQueryParam";

export const nomeEmpresa =
  getEnv("REACT_APP_NOME_EMPRESA") || "Sem nome empresa";
export const razaoEmpresa =
  getEnv("REACT_APP_RAZAO_EMPRESA") || "Sem razão empresa";

const ImpressaoContrato: React.FC<{}> = (props) => {
  const id = parseInt(useGetQueryParam("id") as string);
  const [{ data, fetching }] = useContratoQuery({
    variables: {
      id,
    },
  });
  const [{ data: cobrancaData, fetching: cobrancaFeching }] =
    useBoletoInstalacaoContratoQuery({
      variables: {
        id,
      },
    });
  if (fetching || cobrancaFeching) {
    return <LoadingWheel />;
  }

  if (data && data.contrato && data.contrato.isCanceled) {
    return (
      <>
        <Heading> Impressão de termo de cancelamento </Heading>
        <PDFViewer
          style={{ minHeight: "90vh", minWidth: "100%" }}
          height="90vh"
          width="100%"
        >
          <Document title="cancelamento" author="Gabrimar Telecom">
            <Page
              size="A4"
              style={{ flexDirection: "column", fontFamily: "Arial" }}
            >
              <TermoCancelamento contrato={data.contrato as Contrato} />
            </Page>
          </Document>
        </PDFViewer>
      </>
    );
  }

  return (
    <>
      <Heading> Impressão de contrato </Heading>

      {data && data.contrato && (
        <PDFViewer
          style={{ minHeight: "90vh", minWidth: "100%" }}
          height="90vh"
          width="100%"
        >
          <Document title="contrato" author="Gabrimar Telecom">
            <Page
              size="A4"
              style={{
                flexDirection: "column",
                fontFamily: "Source Sans Pro",
              }}
            >
              <PrintContrato contrato={data.contrato as Contrato} />
              {cobrancaData && cobrancaData.boletoInstalacaoContrato && (
                <Boleto
                  nomeEmpresa={nomeEmpresa}
                  razaoEmpresa={razaoEmpresa}
                  codigoBanco={
                    cobrancaData.boletoInstalacaoContrato.banco.codigo
                  }
                  agenciaBanco={
                    cobrancaData.boletoInstalacaoContrato.banco.agencia
                  }
                  carteiraBanco={
                    cobrancaData.boletoInstalacaoContrato.banco.carteira
                  }
                  dvAgenciaBanco={
                    cobrancaData.boletoInstalacaoContrato.banco.agenciaDv
                  }
                  codigoClienteBanco={
                    cobrancaData.boletoInstalacaoContrato.banco.codCliente
                  }
                  dvCodigoClienteBanco={
                    cobrancaData.boletoInstalacaoContrato.banco.codClienteDv
                  }
                  numeroDocumento={`${cobrancaData.boletoInstalacaoContrato.id}`}
                  valorDocumento={cobrancaData.boletoInstalacaoContrato.valor}
                  vencimento={cobrancaData.boletoInstalacaoContrato.vencimento}
                  dataProcessamento={
                    cobrancaData.boletoInstalacaoContrato.data_emissao
                  }
                  codigoModalidade={""} // Criar campo no banco
                  nomePagador={
                    cobrancaData.boletoInstalacaoContrato.cliente.nome
                  }
                  cpfcnpjPagador={
                    cobrancaData.boletoInstalacaoContrato.cliente.isncNacional
                  }
                  tipoPagador={
                    cobrancaData.boletoInstalacaoContrato.cliente.tipo
                  }
                  borderTop={true}
                  enderecoPagador={`${cobrancaData.boletoInstalacaoContrato.cliente.logradouro}, ${cobrancaData.boletoInstalacaoContrato.cliente.numero}`}
                  cidadeUfPagador={`${cobrancaData.boletoInstalacaoContrato.cliente.cidade}-${cobrancaData.boletoInstalacaoContrato.cliente.uf}`}
                  cepPagador={`${cobrancaData.boletoInstalacaoContrato.cliente.cep}`}
                  referente={cobrancaData.boletoInstalacaoContrato.referencia}
                  instrucoes1={cobrancaData.boletoInstalacaoContrato.instrucoes}
                />
              )}
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default ImpressaoContrato;
