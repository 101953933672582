import React from "react";
import { Button } from "@chakra-ui/button";
import { Link } from "react-router-dom";

export interface SidebarLinkProps {
  to: string;
  children?: React.ReactNode;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ to, children }) => {
  return (
    <Link to={to}>
      <Button justifyContent="start" minW="100%">
        {children}
      </Button>
    </Link>
  );
};

export default SidebarLink;
