import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Form } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import CheckField from "../../components/CheckField";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import SelectField from "../../components/SelectField";
import {
  VelocidadeInput,
  useCreateVelocidadeMutation,
  useVelocidadeQuery,
  useUpdateVelocidadeMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novoVelocidade: VelocidadeInput = {
  nome: "",
  download: 0,
  upload: 0,
  prioDownload: 0,
  prioUpload: 0,
  burst: false,
  burstDownload: 0,
  burstUpload: 0,
  avgDownload: 0,
  avgUpload: 0,
  timeDownload: 0,
  timeUpload: 0,
};

const VelocidadeForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const [{ fetching, data }] = useVelocidadeQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [, createMutation] = useCreateVelocidadeMutation();
  const [, updateMutation] = useUpdateVelocidadeMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.velocidade ? "Editar" : "Novo"} Velocidade`}
      updateMsg={updateMsg}
      initialData={data?.velocidade ? data.velocidade : novoVelocidade}
      onSubmit={async (values, { setErrors }) => {
        values.prioDownload = parseInt(values.prioDownload);
        values.prioUpload = parseInt(values.prioUpload);
        console.log(
          "creating velocidade",
          values,
          stripObjectToMutation(values)
        );
        if (data?.velocidade) {
          /// é alteracao
          const { error, data } = await updateMutation({
            velocidade: stripObjectToMutation(values),
          });

          if (error) {
            setErrors({
              nome: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateVelocidade.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateVelocidade.errors));
          }

          setUpdateMsg("Velocidade salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({
            velocidade: stripObjectToMutation(values),
          });

          if (error) {
            setErrors({
              nome: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createVelocidade.errors?.length > 0) {
            setErrors(errorsToFormik(data.createVelocidade.errors));
            return;
          }

          navigate(`/velocidades/${data.createVelocidade.velocidade.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={6}>
              <InputField name="nome" label="Nome" placeholder="Nome" />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                name="download"
                label="Download (Kbps)"
                decimalPlaces={0}
                placeholder="Download"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                name="upload"
                label="Upload (Kbps)"
                decimalPlaces={0}
                placeholder="Upload"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                name="prioDownload"
                label="Prioridade download"
                placeholder="Selecione"
              >
                <option value={1}>Alta</option>
                <option value={3}>Média</option>
                <option value={5}>Baixa</option>
                <option value={8}>Muito Baixa</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                name="prioUpload"
                label="Prioridade upload"
                placeholder="Selecione"
              >
                <option value={1}>Alta</option>
                <option value={3}>Média</option>
                <option value={5}>Baixa</option>
                <option value={8}>Muito Baixa</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={6}>
              <CheckField name="burst" label="Burst" placeholder="Burst" />
            </GridItem>
            {props.values.burst && (
              <>
                <GridItem colSpan={[6, 1]}>
                  <InputNumber
                    name="burstDownload"
                    label="Burst download (kbps)"
                    decimalPlaces={0}
                    placeholder="Burst download"
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <InputNumber
                    name="burstUpload"
                    label="Burst upload (kbps)"
                    decimalPlaces={0}
                    placeholder="Burst upload"
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <InputNumber
                    name="avgDownload"
                    label="Avgdownload"
                    decimalPlaces={0}
                    placeholder="Avgdownload"
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <InputNumber
                    name="avgUpload"
                    label="Média Upload (kbps)"
                    decimalPlaces={0}
                    placeholder="Média Upload"
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <InputNumber
                    name="timeDownload"
                    label="Tempo Download (s)"
                    decimalPlaces={0}
                    placeholder="Tempo Download"
                  />
                </GridItem>
                <GridItem colSpan={[6, 1]}>
                  <InputNumber
                    name="timeUpload"
                    label="Tempo Upload (s)"
                    decimalPlaces={0}
                    placeholder="Tempo Upload"
                  />
                </GridItem>
              </>
            )}
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default VelocidadeForm;
