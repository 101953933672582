import { Font, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR/index";
import * as React from "react";
import { Contrato } from "../generated/graphql";

export interface ContratoProps {
  contrato: Contrato;
}

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

const TermoCancelamento: React.FC<ContratoProps> = ({ contrato }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        marginTop: "1cm",
        marginLeft: "1cm",
        marginRight: "1cm",
        width: "19cm",
        fontSize: "10pt",
        fontFamily: "Arial",
        fontWeight: "normal",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          marginBottom: "1rem",
        }}
      >
        <Text
          style={{
            fontSize: "16pt",
            flexGrow: 7,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Termo de Cancelamento
        </Text>
      </View>
      <Text>
        Eu {contrato.cliente.nome}, portador do{" "}
        {contrato.cliente.tipo === "FISICO" ? "CPF" : "CNPJ"}{" "}
        {contrato.cliente.isncNacional}, venho por meio desta solicitar o
        cancelamento da internet instalada no meu estabelescimento localizada no
        endereço {contrato.logradouro}, {contrato.numero} - {contrato.cidade}/
        {contrato.uf}
      </Text>

      <Text
        style={{
          marginTop: "3cm",
          borderStyle: "solid",
          borderColor: "#000",
          borderTopWidth: 1,
          textAlign: "center",
        }}
      >
        {contrato.cliente.nome}
      </Text>
      <Text style={{ textAlign: "center" }}>
        {contrato.cliente.tipo === "FISICO" ? "CPF" : "CNPJ"}:{" "}
        {contrato.cliente.isncNacional}
      </Text>
      <Text style={{ marginTop: "1cm", marginBottom: "1cm" }}>
        Sertanópolis,{" "}
        {format(new Date(), "dd 'de' MMMM 'de' yyyy", {
          locale: ptBR,
        })}
      </Text>
    </View>
  );
};

export default TermoCancelamento;
