import React from "react";
import { Button } from "@chakra-ui/button";
import { Flex, Grid, GridItem } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { useCreateLancamentoCaixaMutation } from "../generated/graphql";
import errorsToFormik from "../utils/errorsToFormik";
import InputField from "./InputField";
import InputNumber from "./InputNumber";
import SelectField from "./SelectField";

export interface CreateLancamentoCaixaProps {
  caixa: number;
  onAddSuccess?: () => void;
}

const novoLancamento = (caixa) => ({
  descricao: "",
  valor: 0,
  tipo: 1,
  data: new Date(),
  caixa,
});

const CreateLancamentoCaixa: React.FC<CreateLancamentoCaixaProps> = ({
  caixa,
  onAddSuccess,
}) => {
  const [, createLancamento] = useCreateLancamentoCaixaMutation();
  const toast = useToast();

  return (
    <Formik
      initialValues={novoLancamento(caixa)}
      onSubmit={async (values, { setErrors, resetForm }) => {
        const { error, data } = await createLancamento({
          lancamentoCaixa: {
            descricao: values.descricao,
            valor:
              values.tipo * 1 === 1 || values.tipo * 1 === -1
                ? values.valor * values.tipo
                : values.valor * -1,
            data: new Date(),
            caixa: values.caixa,
            isDeposito: values.tipo * 1 === 2,
          },
        });

        if (error) {
          setErrors({
            descricao: error.message.replace("[GraphQL]", ""),
          });
          return;
        }
        console.log("returned", error, data);

        if (data.createLancamento_caixa.errors?.length > 0) {
          setErrors(errorsToFormik(data.createLancamento_caixa.errors));
          return;
        }

        typeof onAddSuccess === "function" && onAddSuccess();

        toast({
          title: "Lançamento Feito",
          description: "Lançamento criado com sucesso!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        resetForm();
      }}
    >
      {() => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={[6, 3]}>
              <InputField
                label="Descrição"
                name="descricao"
                autoFocus
                placeholder="Descrição"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <SelectField
                label="Tipo"
                placeholder="Selecione o Tipo"
                name="tipo"
              >
                <option value={1}>Entrada</option>
                <option value={-1}>Saída</option>
                <option value={2}>Retirada do caixa</option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                label="Valor"
                name="valor"
                placeholder="Valor"
                decimalPlaces={2}
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <Flex
                minH="100%"
                alignItems="flex-end"
                justifyContent="flex-start"
              >
                <Button colorScheme="teal" type="submit">
                  Incluir
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateLancamentoCaixa;
