import { TipoChamado } from "../generated/graphql";

export function formatTipoChamado(tipo: TipoChamado): string {
  switch (tipo) {
    case TipoChamado.Instalacao:
      return "Instalação";
    case TipoChamado.Manutencao:
      return "Manutenção";
    case TipoChamado.Mudanca:
      return "Mudança de endereço";
    case TipoChamado.Retirdada:
      return "Retirada de kit";
    case TipoChamado.Interno:
      return "Interno";
    default:
      return "";
  }
}
