import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Tooltip } from "@chakra-ui/tooltip";
import {
  faBarcode,
  faBolt,
  faLifeRing,
  faPen,
  faPlus,
  faPrint,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as React from "react";
import {
  Contrato,
  useAtivarContratoMutation,
  useContratosQuery,
  useDeleteContratoMutation,
  useSyncContratoMutation,
} from "../generated/graphql";
import CancelarContratoButton from "../pages/contratos/CancelarContratoButton";
import CheckContratoButton from "../pages/contratos/CheckContratoButton";
import TestarPlanoButton from "../pages/contratos/TestarPlanoButton";
import formatDate from "../utils/formatDate";
import formatError from "../utils/formatError";
import { useIsAuth } from "../utils/useIsAuth";
import ConfirmButton from "./ConfirmButton";
import Filter from "./Filter";
import LoadingWheel from "./LoadingWheel";
import PaginationButtons from "./PaginationButtons";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";

interface ContratoProps {
  clienteid: number;
}

const Contratos: React.FC<ContratoProps> = (props) => {
  useIsAuth();
  const toast = useToast();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching, error }, query] = useContratosQuery({
    variables: { page: page, filter, clienteid: props.clienteid },
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteContratoMutation();
  const [, ativarContrato] = useAtivarContratoMutation();
  const [{ fetching: syncLoading }, syncContratoMutation] =
    useSyncContratoMutation();

  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");
  const [ativarSuccessMsg, setAtivarSuccessMsg] = React.useState("");
  const [cancelarSuccessMsg, setCancelarSuccessMsg] = React.useState("");
  const [deleteErrorMsg, setDeleteErrorMsg] = React.useState("");
  const [ativarErrorMsg, setAtivarErrorMsg] = React.useState("");
  const [cancelarErrorMsg, setCancelarErrorMsg] = React.useState("");

  const onDeleteConfirm = async (contrato: Contrato) => {
    const { error } = await deleteData({ id: contrato.id });
    if (error) {
      setDeleteErrorMsg(formatError(error.message));
      return;
    }
    setDeleteSuccessMsg(
      `Contrato ${contrato.usuario} foi removido com sucesso!`
    );
    query();
  };

  const onAtivarContrato = async (contrato: Contrato) => {
    const { error } = await ativarContrato({ id: contrato.id });
    if (error) {
      setAtivarErrorMsg(formatError(error.message));
      return;
    }
    setAtivarSuccessMsg(
      `Contrato ${contrato.usuario} foi ativado com sucesso!`
    );
    query();
  };

  const onSyncContrato = async (contrato: Contrato) => {
    const { error } = await syncContratoMutation({
      id: contrato.id,
    });
    if (error) {
      toast({
        status: "error",
        duration: 9000,
        title: `Erro ao sincronizar contrato!`,
        description: `Detalhes: ${formatError(error.message)}`,
      });
      return;
    }
    toast({
      status: "success",
      duration: 9000,
      title: `Sincronizado contrato com sucesso!`,
      description: `Sincronizado contrato com a estação com sucesso!`,
    });
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Contrato>[] = [
    {
      label: "Plano",
      render: (contrato) => (contrato.plano ? contrato.plano.nome : ""),
      wrapped: true,
    },
    {
      label: "Banco",
      render: (contrato) => (contrato.banco ? contrato.banco.nome : ""),
      wrapped: true,
    },
    {
      label: "Endereço",
      render: (contrato) => `${contrato.logradouro}, ${contrato.numero}`,
      wrapped: true,
    },
    {
      label: "Cidade",
      render: (contrato) => `${contrato.cidade}-${contrato.uf}`,
      wrapped: true,
    },
    {
      label: "Telefone",
      render: (contrato) => contrato.telefone1,
      wrapped: true,
    },
    {
      label: "Usuario",
      render: (contrato) =>
        `${contrato.usuario}${contrato.estacao && ` - ${contrato.estacao.nome}`}`,
      wrapped: true,
    },
    {
      label: "Data ativação",
      render: (contrato) =>
        contrato.dataAtivacao && `${formatDate(contrato.dataAtivacao)}`,
      wrapped: true,
    },
    {
      label: "Status",
      render: (contrato) =>
        contrato.isCanceled
          ? "Cancelado"
          : contrato.isBlocked
            ? "Bloqueado"
            : contrato.isAtivado
              ? "Ativado"
              : "Disponivel para ativação",
      wrapped: true,
    },
    {
      label: " ",
      render: (contrato) => (
        <>
          <Link to={`/contratos/${props.clienteid}/${contrato.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>

          <Link to={`/impressao/contrato/${contrato.id}`}>
            <Button colorScheme="blue" type="button">
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          </Link>

          {!contrato.isAtivado && !contrato.isCanceled && (
            <Tooltip label="Ativar contrato">
              <Button
                onClick={() => onAtivarContrato(contrato as Contrato)}
                colorScheme="green"
                type="button"
              >
                <FontAwesomeIcon icon={faBolt} />
              </Button>
            </Tooltip>
          )}

          {contrato.isAtivado && !contrato.isCanceled && (
            <>
              <Tooltip label="Imprimir Carne">
                <Link to={`/carneContrato/${contrato.id}`}>
                  <Button colorScheme="blue" type="button">
                    <FontAwesomeIcon icon={faBarcode} />
                  </Button>
                </Link>
              </Tooltip>
              <Tooltip label="Novo chamado">
                <Link
                  to={`/NovoChamado/?cliente=${props.clienteid}&contrato=${contrato.id}`}
                >
                  <Button colorScheme="green" type="button">
                    <FontAwesomeIcon icon={faLifeRing} />
                  </Button>
                </Link>
              </Tooltip>
              <CancelarContratoButton
                contrato={contrato}
                onSuccess={() => query()}
              />
              <CheckContratoButton contrato={contrato} />
              <Tooltip label="Sincronizar contrato">
                <Button
                  isLoading={syncLoading}
                  onClick={() => onSyncContrato(contrato as Contrato)}
                  colorScheme="teal"
                  type="button"
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </Tooltip>
              <TestarPlanoButton contrato={contrato} />
            </>
          )}

          {!contrato.isAtivado && !contrato.isCanceled && (
            <ConfirmButton
              isLoading={deleteLoading}
              onClick={() => onDeleteConfirm(contrato as Contrato)}
              colorScheme="red"
            >
              <FontAwesomeIcon icon={faTrash} />
            </ConfirmButton>
          )}
        </>
      ),
      wrapped: true,
    },
  ];

  return (
    <>
      <Heading mb="1em">Contratos</Heading>

      <Flex direction="row" mb="1rem">
        <Link to={`/contratos/${props.clienteid}/novo`}>
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo contrato
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      {!!ativarSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{ativarSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setAtivarSuccessMsg("")}
          />
        </Alert>
      )}

      {!!cancelarSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{cancelarSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setCancelarSuccessMsg("")}
          />
        </Alert>
      )}

      {!!deleteErrorMsg && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{deleteErrorMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteErrorMsg("")}
          />
        </Alert>
      )}

      {!!ativarErrorMsg && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{ativarErrorMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setAtivarErrorMsg("")}
          />
        </Alert>
      )}

      {!!cancelarErrorMsg && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{cancelarErrorMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setCancelarErrorMsg("")}
          />
        </Alert>
      )}

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {error.message.replace(/\[.*\]/, "")}
          </AlertDescription>
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.contratos.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.contratos.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Contratos;
