import React from "react";
import { Image } from "@react-pdf/renderer";

export const LogoGabrimar = () => (
  <Image
    src="/assets/logogabrimar.png"
    cache
    style={{ width: "100px", height: "58px" }}
  />
);
