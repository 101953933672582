import { SearchIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import { Kbd } from "@chakra-ui/layout";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useBancosQuery, useCaixasQuery } from "../generated/graphql";
import Autocomplete from "./Autocomplete";

export interface SearchBarProps { }

const CustomInputSearchBar = React.forwardRef<HTMLInputElement>(
  ({ ...props }, ref) => {
    const isMac = navigator.platform.toLowerCase().startsWith("mac");

    return (
      <InputGroup mx="0.75rem">
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          {...props}
          colorScheme="telegram"
          textColor="white"
          autoComplete="no"
          ref={ref}
          onFocus={(e) => e.target.select()}
        />
        <InputRightElement width="4.5rem">
          <Kbd>{isMac ? "⌘" : "CTRL"}</Kbd>
          <Kbd>K</Kbd>
        </InputRightElement>
      </InputGroup>
    );
  }
);

const SearchBar: React.FC<SearchBarProps> = () => {
  const navigate = useNavigate();

  const menuItens = [
    {
      label: "Novo Cliente",
      link: "/clientes/novo",
    },
    {
      label: "Clientes",
      link: "/clientes",
    },
    {
      label: "Pesquisar Cliente xxx",
      link: "/clientes?initialFilter=",
      includeText: true,
    },
    {
      label: "Novo Banco",
      link: "/bancos/novo",
    },
    {
      label: "Bancos",
      link: "/bancos",
    },
    {
      label: "Novo Caixa",
      link: "/caixas/novo",
    },
    {
      label: "Caixas",
      link: "/caixas",
    },
    {
      label: "Novo Plano",
      link: "/planos/novo",
    },
    {
      label: "Planos",
      link: "/planos",
    },
    {
      label: "Contas a Pagar",
      link: "/pagar",
    },
    {
      label: "Novo Contas a Pagar",
      link: "/pagar/novo",
    },
    {
      label: "Chamados",
      link: "/chamados",
    },
    {
      label: "Usuarios",
      link: "/usuarios",
    },
    {
      label: "Baixar cobranças xxx",
      link: "/baixarCobrancas/",
      includeText: true,
    },
  ];
  const [{ data }] = useCaixasQuery({
    variables: {
      page: 1,
    },
  });
  const [{ data: dataBancos }] = useBancosQuery({
    variables: {
      page: 1,
    },
  });

  if (data && data.caixas && data.caixas.data.length > 0) {
    for (const caixa of data.caixas.data) {
      menuItens.push({
        label: `Caixa ${caixa.nome}`,
        link: `/lancamentosCaixa/${caixa.id}`,
      });
    }
  }

  if (dataBancos && dataBancos.bancos && dataBancos.bancos.data.length > 0) {
    for (const banco of dataBancos.bancos.data) {
      menuItens.push({
        label: `Lançamentos do banco ${banco.nome}`,
        link: `/lancamentosBanco/${banco.id}`,
      });
    }
  }

  const [textSubst, setTextSubst] = useState("");
  const [autoCompleteValue, setValue] = useState("");
  const refAutocomplete = useRef(null);

  const filterItems = (text: string, item: any) => {
    if (!item.includeText) {
      return item.label.toLowerCase().includes(`${text}`.trim().toLowerCase());
    }
    setTextSubst(text);
    if (
      item.link === "/baixarCobrancas/" &&
      /^([0-9]+[ ]*,[ ]*)*[0-9]+$/.test(text)
    ) {
      return true;
    }
    if (item.link === "/clientes?initialFilter=" && /^[a-zA-Z].+$/.test(text)) {
      return true;
    }
    return false;
  };

  const openLink = (item: any) => {
    if (item.includeText) {
      navigate(item.link + textSubst);
      return;
    }
    navigate(item.link);
    setValue("");
  };
  const keyDownFocus = (e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 75) {
      console.log("onKeyDown Window", refAutocomplete, e);
      /// Comand + K | CTRL + K
      if (!!refAutocomplete) {
        e.preventDefault()
        // @ts-ignore
        refAutocomplete.current.focus();
        // @ts-ignore
        // refAutocomplete.current.select();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownFocus);

    return () => {
      window.removeEventListener("keydown", keyDownFocus);
    };
  });

  return (
    <Autocomplete
      items={menuItens}
      renderItem={(item) => `${item.label}`.replace("xxx", textSubst)}
      itemLabel={(item) => `${item.label}`.replace("xxx", textSubst)}
      filter={filterItems}
      onSelectItem={openLink}
      value={autoCompleteValue}
      placeholder="Pesquisar..."
      ref={refAutocomplete}
      CustomInput={CustomInputSearchBar}
    />
  );
};

export default SearchBar;
