import { Button, Flex, Heading } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { parse } from "query-string";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import InputField from "../components/InputField";
import { useLoginMutation } from "../generated/graphql";

const Login: React.FC<{}> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = parse(location.search);

  const [, login] = useLoginMutation();
  return (
    <Flex direction="column" maxW="50ch" mx="auto">
      <Heading mb="1em">Bug::Login</Heading>
      <Formik
        initialValues={{
          usuario: "",
          senha: "",
        }}
        onSubmit={async (values, { setErrors }) => {
          console.log("Login submit", values);
          const res = await login(values);
          if (res.error) {
            setErrors({ senha: res.error.message.replace("[GraphQL]", "") });
            return;
          }
          const redirUrl = (query.next as string) || "/";
          if (/.*login.*/.test(redirUrl)) {
            navigate("/");
            return;
          }
          navigate(redirUrl);
        }}
      >
        {(props) => (
          <Form>
            <InputField
              autoFocus
              name="usuario"
              placeholder="Usuario"
              label="Usuario"
            />
            <InputField
              name="senha"
              placeholder="Senha"
              label="Senha"
              type="password"
            />
            <Button
              mt={4}
              ml="auto"
              colorScheme="teal"
              isLoading={props.isSubmitting}
              type="submit"
            >
              Entrar
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default Login;
