import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import React from "react";
import { CheckUsuarioResponse, Contrato } from "../../generated/graphql";

export type CheckContratoModalProps = {
  contrato: Contrato;
  checkRes: CheckUsuarioResponse;
  isOpen: boolean;
  onClose: () => void;
};

const CheckContratoModal: React.FC<CheckContratoModalProps> = ({
  contrato,
  checkRes,
  ...props
}) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent px="1rem" py="1rem" minW="90vw">
        <ModalHeader>Status do Contrato</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading>Status do contrato do usuario {contrato.usuario} </Heading>
          {!!checkRes && (
            <StatGroup>
              <Stat>
                <StatLabel>Esta conectado?</StatLabel>
                <StatNumber>{checkRes.isConnected ? "Sim" : "Não"}</StatNumber>
              </Stat>
              {!!checkRes.isConnected && (
                <>
                  <Stat>
                    <StatLabel>Tempo de conexão</StatLabel>
                    <StatNumber>
                      {transformSeconds(checkRes.upTimeSeconds)}
                    </StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel>Endereço IP</StatLabel>
                    <StatNumber>{checkRes.enderecoIp}</StatNumber>
                  </Stat>
                </>
              )}
            </StatGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={props.onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

function transformSeconds(seconds: number) {
  const sPart = seconds % 60;
  const mPart = seconds > 60 ? Math.floor(seconds / 60) % 60 : 0;
  const hPart = seconds > 3600 ? Math.floor(seconds / 3600) % 24 : 0;
  const dPart = seconds < 3600 * 24 ? Math.floor(seconds / (3600 * 24)) : 0;
  return (
    (dPart > 0 ? `${dPart} dias ` : "") +
    `${hPart}`.padStart(2, "0") +
    ":" +
    `${mPart}`.padStart(2, "0") +
    ":" +
    `${sPart}`.padStart(2, "0")
  );
}

export default CheckContratoModal;
