import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/alert";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  Badge,
  Button,
  CloseButton,
  Stat,
  StatLabel,
  StatNumber,
  Th,
  Tr,
} from "@chakra-ui/react";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { differenceInBusinessDays, parseISO } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import BancoSelectorField from "../../components/BancoSelectorField";
import CaixaSelectorField from "../../components/CaixaSelectorField";
import CheckField from "../../components/CheckField";
import InputNumber from "../../components/InputNumber";
import LoadingWheel from "../../components/LoadingWheel";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  BaixarInput,
  Cobranca,
  useBaixarCobrancasMutation,
  useCobrancasQuery,
} from "../../generated/graphql";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import IsVencido from "../../utils/IsVencido";
import { useGetIds } from "../../utils/useGetIds";
import { useIsAuth } from "../../utils/useIsAuth";
import { BCriarPixImediato } from "./CriarPixImediato";

const BaixarCobrancas: React.FC<{}> = (props) => {
  useIsAuth();
  const [{ data, fetching, error }, refreshQuery] = useCobrancasQuery({
    variables: {
      page: 1,
      ids: useGetIds(),
      isPago: false,
    },
    requestPolicy: "network-only",
  });

  const [, baixarCobrancasMutation] = useBaixarCobrancasMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [avisos, setAvisos] = useState([]);

  const baixarInitialValues = {
    dinheiro: 0,
    cartao_credito: 0,
    cartao_debito: 0,
    pix: 0,
    caixa: null,
    banco: null,
    imprimirRecibo: false,
  };
  if (data) {
    data.cobrancas.data.forEach((cobranca) => {
      // const diasVencido = differenceInBusinessDays(
      //   new Date(),
      //   parseISO(cobranca.vencimento)
      // );
      baixarInitialValues[`valor_pago_${cobranca.id}`] =
        cobranca.valor +
        (IsVencido(cobranca.vencimento) ? 0 : cobranca.desconto * -1);
    });
  }

  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Heading mb="1em">Baixar Cobranças</Heading>

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}
      {!!avisos &&
        avisos.map((aviso, i) => (
          <Alert key={`${i}`} status="warning">
            <AlertIcon />
            <AlertDescription>{aviso}</AlertDescription>
          </Alert>
        ))}

      <Formik
        initialValues={baixarInitialValues}
        onSubmit={async (values) => {
          console.log("values to baixar", values);
          const baixas: BaixarInput[] = data.cobrancas.data.map((cob) => {
            return {
              id: cob.id,
              valor_pago: values[`valor_pago_${cob.id}`],
            };
          });

          const { error, data: dataRes } = await baixarCobrancasMutation({
            baixas,
            dinheiro: values.dinheiro,
            caixaid: values.caixa && values.caixa.id,
            bancoid: values.banco && values.banco.id,
            cartaoCredito: values.cartao_credito,
            cartaoDebito: values.cartao_debito,
            pix: values.pix,
            imprimirRecibo: values.imprimirRecibo,
          });

          if (error) {
            setErrorMessage(error.message.replace("[GraphQL]", ""));
            return;
          }

          if (dataRes && dataRes.baixarCobrancas.avisos) {
            setAvisos(dataRes.baixarCobrancas.avisos);
          }

          setSuccessMessage("Cobranças baixadas com sucesso!");
          await refreshQuery();
        }}
      >
        {(props) => {
          const somaPagar =
            data &&
            data.cobrancas &&
            data.cobrancas.data.length > 0 &&
            data.cobrancas.data
              .map((cob) => props.values[`valor_pago_${cob.id}`])
              .reduce((a, p) => a + p);

          const restante =
            Math.round(
              100 *
                (somaPagar -
                  props.values.dinheiro -
                  props.values.cartao_credito -
                  props.values.cartao_debito -
                  props.values.pix)
            ) / 100;

          const isValid =
            data &&
            data.cobrancas &&
            data.cobrancas.data.length > 0 &&
            [
              restante <= 0,
              ...data.cobrancas.data.map(
                (cob) =>
                  props.values[`valor_pago_${cob.id}`] >=
                  baixarInitialValues[`valor_pago_${cob.id}`]
              ),
              (props.values.cartao_credito > 0 && !!props.values.banco) ||
                props.values.cartao_credito <= 0,
              (props.values.cartao_debito > 0 && !!props.values.banco) ||
                props.values.cartao_debito <= 0,
              (props.values.pix > 0 && !!props.values.banco) ||
                props.values.pix <= 0,
              (props.values.dinheiro > 0 && !!props.values.caixa) ||
                props.values.dinheiro <= 0,
            ].reduce((a, p) => a && p);

          const headers: TableHeaders<Cobranca>[] = [
            {
              label: "#",
              render: (cobranca) => cobranca.id,
              wrapped: true,
            },
            {
              label: "Cliente",
              render: (cobranca) =>
                cobranca.cliente ? cobranca.cliente.nome : "",
              wrapped: true,
            },
            {
              label: "Contrato",
              render: (cobranca) =>
                cobranca.contrato
                  ? `${cobranca.contrato.id} - ${cobranca.contrato.usuario}`
                  : "",
              wrapped: true,
            },
            {
              label: "Banco",
              render: (cobranca) => (cobranca.banco ? cobranca.banco.nome : ""),
              wrapped: true,
            },
            {
              label: "Valor",
              render: (cobranca) => formatNumber(cobranca.valor, 2, true),
              wrapped: true,
            },
            {
              label: "Juros",
              render: (cobranca) => formatNumber(cobranca.juros, 2, true),
              wrapped: true,
            },
            {
              label: "Desconto",
              render: (cobranca) => formatNumber(cobranca.desconto, 2, true),
              wrapped: true,
            },
            {
              label: "Vencimento",
              render: (cobranca) => formatDate(cobranca.vencimento),
              wrapped: true,
            },
            {
              label: " ",
              render: (cobranca) =>
                !cobranca.isPago && (
                  <BCriarPixImediato
                    cobranca={cobranca}
                    colorScheme="blackAlpha"
                  >
                    <FontAwesomeIcon icon={faQrcode} />
                  </BCriarPixImediato>
                ),
              wrapped: true,
            },
            {
              label: "Valor a pagar",
              render: (cobranca) => (
                <InputNumber
                  name={`valor_pago_${cobranca.id}`}
                  decimalPlaces={2}
                />
              ),
              wrapped: true,
            },
          ];

          return (
            <Form>
              {errorMessage && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Erro ao baixar: </AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                  <CloseButton
                    pos="absolute"
                    right="8px"
                    top="8px"
                    onClick={() => setErrorMessage("")}
                  />
                </Alert>
              )}

              {successMessage && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertDescription>{successMessage}</AlertDescription>
                  <CloseButton
                    pos="absolute"
                    right="8px"
                    top="8px"
                    onClick={() => setSuccessMessage("")}
                  />
                </Alert>
              )}

              <ResponsiveTable
                data={data?.cobrancas.data}
                headers={headers}
                tfoot={
                  <Tr>
                    <Th display={["none", "table-cell"]} colSpan={4}>
                      Total:
                    </Th>
                    <Th display={["block", "table-cell"]}>
                      <Badge display={["inline", "none"]} colorScheme="teal">
                        Total:
                      </Badge>
                      {formatNumber(
                        data?.cobrancas.data.reduce((a, p) => a + p.valor, 0),
                        2,
                        true
                      )}{" "}
                    </Th>
                    <Th display={["none", "table-cell"]} colSpan={3}></Th>
                    <Th display={["block", "table-cell"]}>
                      <Badge display={["inline", "none"]} colorScheme="teal">
                        Total a pagar:
                      </Badge>
                      {formatNumber(somaPagar, 2, true)}{" "}
                    </Th>
                  </Tr>
                }
              />

              <Flex
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Flex
                  alignItems="flex-end"
                  direction="column"
                  maxW="30ch"
                  right="0.5rem"
                >
                  <CaixaSelectorField
                    name="caixa"
                    label="Caixa"
                    placeholder="Selecione um caixa"
                  />
                  <InputNumber
                    name="dinheiro"
                    decimalPlaces={2}
                    label="Dinheiro ($)"
                  />
                  <BancoSelectorField
                    name="banco"
                    label="Banco"
                    placeholder="Selecione um banco"
                  />
                  <InputNumber
                    name="cartao_credito"
                    decimalPlaces={2}
                    label="Cartão Crédito ($)"
                  />
                  <InputNumber
                    name="cartao_debito"
                    decimalPlaces={2}
                    label="Cartão Débito ($)"
                  />
                  <InputNumber name="pix" decimalPlaces={2} label="PIX ($)" />

                  <Stat my="0.5rem">
                    <StatLabel>
                      {restante < 0 ? "Troco" : "Valor faltando"}
                    </StatLabel>
                    <StatNumber>
                      ${formatNumber(Math.abs(restante), 2, true)}
                    </StatNumber>
                  </Stat>
                  <CheckField name="imprimirRecibo" label="Imprimir Recibo" />

                  <Button
                    my="0.5rem"
                    disabled={!isValid}
                    type="submit"
                    colorScheme="teal"
                  >
                    Confirmar e Baixar
                  </Button>
                </Flex>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default BaixarCobrancas;
