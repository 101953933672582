import { useUsuarioQuery } from "../generated/graphql";
import { useGetQueryParam } from "./useGetQueryParam";

export const useGetUsuario = () => {
  const id = useGetQueryParam("id") as string;
  return useUsuarioQuery({
    variables: {
      usuario: id,
    },
  });
};
