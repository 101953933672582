import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { Form } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CheckField from "../../components/CheckField";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import VelocidadeSelectorField from "../../components/VelocidadeSelectorField";
import {
  PlanoInput,
  useCreatePlanoMutation,
  usePlanoQuery,
  useUpdatePlanoMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novoPlano: PlanoInput = {
  nome: "",
  velocidadePlano: null,
  velocidade: "",
  valorInstalacao: 0,
  vezesInstalacao: 0,
  mensalidade: 0,
  descontoMensalidade: 0,
  multaMensalidade: 0,
  jurosMensalidade: 0,
  instrucoesMensalidade: "",
  diaVencimento: 10,
  diaUso: 1,
  geraAuto: false,
  qtdAuto: 1,
  status: true,
};

const PlanoForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const id = useGetQueryParam("id") as string;
  const [{ fetching, data, error }] = usePlanoQuery({
    variables: { id: parseInt(id) },
  });
  const [, createMutation] = useCreatePlanoMutation();
  const [, updateMutation] = useUpdatePlanoMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.plano ? "Editar" : "Novo"} Plano`}
      updateMsg={updateMsg}
      initialData={data?.plano ? data.plano : novoPlano}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating plano", values);
        if (data?.plano) {
          /// é alteracao
          const { error, data } = await updateMutation({
            plano: stripObjectToMutation(values),
          });

          if (error) {
            setErrors({
              status: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updatePlano.errors?.length > 0) {
            setErrors(errorsToFormik(data.updatePlano.errors));
          }

          setUpdateMsg("Plano salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({ plano: stripObjectToMutation(values) });

          if (error) {
            setErrors({
              status: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createPlano.errors?.length > 0) {
            setErrors(errorsToFormik(data.createPlano.errors));
            return;
          }

          navigate(`/planos/${data.createPlano.plano.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          {!!error && id !== "novo" && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{error.message}</AlertDescription>
            </Alert>
          )}

          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={6}>
              <InputField
                name="nome"
                label="Nome"
                placeholder="Nome do Plano"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputField name="velocidade" label="Velocidade no contrato" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber name="valorInstalacao" label="Valor de Instalação" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber
                name="vezesInstalacao"
                label="Maximo de parcelas instalação"
                decimalPlaces={0}
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber name="mensalidade" label="Mensalidade" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber
                name="descontoMensalidade"
                label="Desconto Mensalidade"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber name="multaMensalidade" label="Multa Mensalidade" />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber name="jurosMensalidade" label="Juros Mensalidade" />
            </GridItem>
            <GridItem colSpan={6}>
              <InputField
                name="instrucoesMensalidade"
                label="Instruções Mensalidade"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                name="diaVencimento"
                label="Dia vencimento"
                decimalPlaces={0}
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber name="diaUso" label="Dia Uso" decimalPlaces={0} />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <CheckField
                name="geraAuto"
                label="Gera cobrança automaticamente"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber
                name="qtdAuto"
                label="Quantidade de Cobranças automaticas"
                decimalPlaces={0}
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <VelocidadeSelectorField
                name="velocidadePlano"
                label="Velocidade"
              />
            </GridItem>
            <GridItem colSpan={6}>
              <CheckField name="status" label="Ativo" />
            </GridItem>
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>

          <Link to={`/contratosPlanos/${id}`}>
            <Button colorScheme="orange" type="button">
              Contratos no plano
            </Button>
          </Link>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default PlanoForm;
