import { useIsAuth } from "../../utils/useIsAuth";
import * as React from "react";
import { Box } from "@chakra-ui/react";
import { MapaClientes } from "../../components/MapaClientes";

export const MapaAtivos: React.FC<{}> = () => {
  useIsAuth();

  return (
    <>
      <Box>Mapa dos clientes ativos</Box>
      <MapaClientes />
    </>
  );
};
