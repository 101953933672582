import { GridItem, Heading } from "@chakra-ui/layout";
import { Button, Grid, Link, useToast } from "@chakra-ui/react";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import SerieSelectorField from "../../components/SerieSelectorField";
import { useGeraArquivoNf21Mutation, useReviewBlockedMutation } from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const GeraArquivoNF21: React.FC<{}> = () => {
  useIsAuth();
  const [, geraArquivo] = useGeraArquivoNf21Mutation();
  const toast = useToast();
  const [strArquivoMestre, setStrArquivoMestre] = useState("");
  const [nomeArquivoMestre, setNomeArquivoMestre] = useState("");
  const [strArquivoDados, setStrArquivoDados] = useState("");
  const [nomeArquivoDados, setNomeArquivoDados] = useState("");
  const [strArquivoItem, setStrArquivoItem] = useState("");
  const [nomeArquivoItem, setNomeArquivoItem] = useState("");
  const [strArquivoDICI, setStrArquivoDICI] = useState("");
  const [nomeArquivoDICI, setNomeArquivoDICI] = useState("");
  const [, reviewBlocked] = useReviewBlockedMutation();

  const valoresIniciais = {
    mes: startOfMonth(new Date()),
    serie: null,
  };
  return (
    <>
      <Heading>Parametros para gerar arquivo de NF21</Heading>

      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          console.log("alterar", values);
          if (!values.serie) {
            setErrors({
              serie: "Séria deve ser preenchida",
            });
            return;
          }
          if (!values.mes || !(values.mes instanceof Date)) {
            setErrors({
              mes: "Mes deve ser preenchido",
            });
            return;
          }
          const { data, error } = await geraArquivo({
            serieId: values.serie.id,
            mes: values.mes,
          });

          if (error) {
            toast({
              title: "Erro ao gerar arquivos de NF21 de notas",
              description: `Erro: ${error.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          console.log("res", data.geraArquivosNF21);
          setStrArquivoMestre(data.geraArquivosNF21.conteudoArquivoMestre);
          setNomeArquivoMestre(data.geraArquivosNF21.nomeArquivoMestre);
          setStrArquivoDados(data.geraArquivosNF21.conteudoArquivoDados);
          setNomeArquivoDados(data.geraArquivosNF21.nomeArquivoDados);
          setStrArquivoItem(data.geraArquivosNF21.conteudoArquivoItem);
          setNomeArquivoItem(data.geraArquivosNF21.nomeArquivoItem);
          setNomeArquivoDICI(data.geraArquivosNF21.nomeArquivoDICI);
          setStrArquivoDICI(data.geraArquivosNF21.conteudoArquivoDICI);
          toast({
            title: "Arquivo gerado com sucesso",
            description: "Arquivo gerado com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 2]}>
                <SerieSelectorField
                  label="Série"
                  name="serie"
                  placeholder="Preecha uma serie para gerar arquivo"
                />
              </GridItem>
              <GridItem colSpan={[6, 2]}>
                <DateSelector name="mes" label="Mês" monthOnly={true} />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
      {strArquivoMestre.length > 10 && (
        <Link
          download={nomeArquivoMestre || "remessa.txt"}
          href={`data:application/octet-stream;name=${nomeArquivoMestre || "remessa.txt"
            };base64,${strArquivoMestre}`}
        >
          Download Arquivo Mestre
        </Link>
      )}
      {strArquivoDados.length > 10 && (
        <Link
          download={nomeArquivoDados || "remessa.txt"}
          href={`data:application/octet-stream;name=${nomeArquivoDados || "remessa.txt"
            };base64,${strArquivoDados}`}
        >
          Download Arquivo Dados
        </Link>
      )}
      {strArquivoItem.length > 10 && (
        <Link
          download={nomeArquivoItem || "remessa.txt"}
          href={`data:application/octet-stream;name=${nomeArquivoItem || "remessa.txt"
            };base64,${strArquivoItem}`}
        >
          Download Arquivo Item
        </Link>
      )}
      {strArquivoDICI.length > 10 && (
        <Link
          download={nomeArquivoDICI || "remessa.txt"}
          href={`data:application/octet-stream;name=${nomeArquivoDICI || "remessa.txt"
            };base64,${strArquivoDICI}`}
        >
          Download Arquivo DICI
        </Link>
      )}
      <Button type="button" onClick={async () => {
        try {
          await reviewBlocked();
          toast({
            title: "Revisado todos os bloqueios",
            description: `Revisado todos os bloqueios`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } catch (error) {
          console.error("Error", error)
          toast({
            title: "Erro ao revisar bloqueios",
            description: `Erro: ${JSON.stringify(error)}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });

        }
      }} mt="0.5rem" colorScheme="blue" mx="0.5rem">
        Review Bloqueios
      </Button>
    </>
  );
};

export default GeraArquivoNF21;
