import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { FormErrorMessage } from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Plano, usePlanosQuery } from "../generated/graphql";

export type PlanoSelectorFieldProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
  onSetPlano?: (plano: Plano) => void;
};

const PlanoSelectorField: React.FC<PlanoSelectorFieldProps> = ({
  label,
  size: _,
  onSetPlano,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  const [{ fetching, data }] = usePlanosQuery({
    variables: {
      page: 1,
      status: true,
    },
  });
  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        name={field.name}
        value={field.value && field.value.id}
        onBlur={field.onBlur}
        onChange={(e) => {
          if (typeof onSetPlano === "function") {
            onSetPlano(
              data.planos.data.find(
                (pln) => pln.id === parseInt(e.target.value)
              )
            );
          }
          setValue(
            data.planos.data.find((pln) => pln.id === parseInt(e.target.value)),
            false
          );
        }}
        {...props}
        id={field.name}
        placeholder={props.placeholder}
      >
        {!fetching &&
          !!data &&
          data.planos.data.map((plano) => (
            <option key={plano.id} value={plano.id}>
              {plano.nome}
            </option>
          ))}
      </Select>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default PlanoSelectorField;
