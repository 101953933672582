import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import PlanoSelectorField from "../../components/PlanoSelectorField";
import { Contrato, useTestarPlanoMutation } from "../../generated/graphql";

export type TestarPlanoModalProps = {
  contrato: Contrato;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const TestarPlanoModal: React.FC<TestarPlanoModalProps> = ({
  contrato,
  ...props
}) => {
  const toast = useToast();

  const [, testePlano] = useTestarPlanoMutation();

  const initialValues = {
    plano: null,
  };

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            console.log("valores", values);
            try {
              const { error } = await testePlano({
                id: contrato.id,
                planoId: values.plano.id,
              });

              if (error) {
                toast({
                  title: "Erro ao ativar teste!",
                  description: `Erro ao ativar teste do plano ${error.message}`,
                  duration: 9000,
                  status: "error",
                });
                return;
              }
              toast({
                title: `Teste ativado com sucesso!`,
                description: `Teste ativado com sucesso!`,
                duration: 9000,
                status: "success",
              });
            } catch (error) {
              console.log("Erro ao ativar o teste do plano", error);
              toast({
                title: "Erro ao ativar o teste!",
                description: `Erro ao ativar o teste do plano: ${error}`,
                duration: 9000,
                status: "error",
              });
            }
          }}
        >
          {() => (
            <Form>
              <ModalHeader>Cancelar Contrato</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <PlanoSelectorField label="Plano a Testar" name="plano" />
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Concluir
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default TestarPlanoModal;
