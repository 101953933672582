import { Tooltip, Button } from "@chakra-ui/react";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Contrato } from "../../generated/graphql";
import AlterarAcessoModal from "./AlteraAcessoModal";

export interface AlteraAcessoButtonProps {
  contrato: Contrato;
  onSuccess?: () => void;
}

const AlteraAcessoButton: React.FC<AlteraAcessoButtonProps> = ({
  contrato,
  onSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <AlterarAcessoModal
        contrato={contrato}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={onSuccess}
      />
      <Tooltip label="Alterar acesso">
        <Button
          onClick={() => setIsOpen(true)}
          colorScheme="blue"
          type="button"
        >
          <FontAwesomeIcon icon={faUserPen} />
        </Button>
      </Tooltip>
    </>
  );
};

export default AlteraAcessoButton;

