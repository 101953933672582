import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import CheckField from "../../components/CheckField";
import DateSelector from "../../components/DateComponents/DateSelector";
import InputNumber from "../../components/InputNumber";
import { Pagar, useBaixarPagarMutation } from "../../generated/graphql";

export type PagarBaixaModalProps = {
  pagar: Pagar;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const PagarBaixaModal: React.FC<PagarBaixaModalProps> = ({
  pagar,
  ...props
}) => {
  const initialStateForm = {
    data_pago: new Date(),
    valor_pago: pagar.valor,
    finaliza_recorrencia: false,
  };

  const toast = useToast();

  const [, baixarPagar] = useBaixarPagarMutation();

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Baixar contas a pagar</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialStateForm}
            onSubmit={async (values) => {
              console.log("baixando", values);

              const { error } = await baixarPagar({
                id: pagar.id,
                valor_pago: values.valor_pago,
                data_pago: values.data_pago,
                finaliza_recorrencia: values.finaliza_recorrencia,
              });
              console.log("erro ao baixar", error);
              if (error) {
                toast({
                  title: "Erro ao efetuar baixa",
                  description: error.message.replace("[GraphQL]", ""),
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                return;
              }

              props.onClose();
              typeof props.onSuccess === "function" && props.onSuccess();
            }}
          >
            {() => (
              <Form>
                <DateSelector label="Data de pagamento" name="data_pago" />
                <InputNumber label="Valor pago" name="valor_pago" />
                {pagar.isRecorrente && (
                  <CheckField
                    label="Finaliza Recorrencia"
                    name="finaliza_recorrencia"
                  />
                )}
                <Button colorScheme="blue" type="submit">
                  Confirmar baixa
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={props.onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PagarBaixaModal;
