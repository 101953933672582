export function formatNumber(
  val: number | string,
  decimalPlaces = 0,
  showZero = false
) {
  if (typeof val === "string") {
    val = parseFloat(val);
  }
  if (!val) {
    const zeroVal = 0;
    return showZero ? zeroVal.toFixed(decimalPlaces).replace(".", ",") : "";
  }
  return val.toFixed(decimalPlaces).replace(".", ",");
}
