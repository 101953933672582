import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Select } from "@chakra-ui/select";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { useProdutosQuery } from "../generated/graphql";

export type ProdutoSelectorFieldProps =
  InputHTMLAttributes<HTMLSelectElement> & {
    name: string;
    label: string;
  };

const ProdutoSelectorField: React.FC<ProdutoSelectorFieldProps> = ({
  label,
  size: _,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  const [{ fetching, data }] = useProdutosQuery({
    variables: {
      page: 1,
      pageSize: 1000,
    },
    requestPolicy: "cache-and-network",
  });
  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        name={field.name}
        value={field.value && field.value.id}
        onBlur={field.onBlur}
        onChange={(e) => {
          setValue(
            data.produtos.data.find(
              (produto) => produto.id === parseInt(e.target.value)
            ),
            false
          );
        }}
        {...props}
        id={field.name}
        placeholder={props.placeholder}
      >
        {!fetching &&
          !!data &&
          data.produtos.data.map((produto) => (
            <option key={produto.id} value={produto.id}>
              {produto.nome}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};

export default ProdutoSelectorField;
