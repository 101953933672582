export function geraCGNATVlan(
  ipValido: string,
  faixa10Inicio: string,
  cgNatSeqStart = 1
) {
  const regras: string[] = [];
  const f10inicio = faixa10Inicio.split(".");
  const g3IpFinal =
    parseInt(f10inicio[2]) + 31 > 255
      ? parseInt(f10inicio[2]) + 31 - 255
      : parseInt(f10inicio[2]) + 31;
  const g2IpFinal =
    parseInt(f10inicio[2]) + 31 > 255
      ? parseInt(f10inicio[1]) + 1
      : parseInt(f10inicio[1]);
  const regraJumpGeral = `add action=jump chain=srcnat jump-target=cgnat src-address=${faixa10Inicio}-${f10inicio[0]}.${g2IpFinal}.${g3IpFinal}.255`;
  regras.push(regraJumpGeral);
  let portaInicial = 0;
  for (let i = 0; i < 4; i++) {
    const g3IpInicio =
      parseInt(f10inicio[2]) + i * 8 > 255
        ? parseInt(f10inicio[2]) + i * 8 - 255
        : parseInt(f10inicio[2]) + i * 8;
    const g2IpInicio =
      parseInt(f10inicio[2]) + i * 8 > 255
        ? parseInt(f10inicio[1]) + 1
        : parseInt(f10inicio[1]);
    const g3IpFim =
      parseInt(f10inicio[2]) + (i + 1) * 8 - 1 > 255
        ? parseInt(f10inicio[2]) + (i + 1) * 8 - 255 - 1
        : parseInt(f10inicio[2]) + (i + 1) * 8 - 1;
    const g2IpFim =
      parseInt(f10inicio[2]) + (i + 1) * 8 - 1 > 255
        ? parseInt(f10inicio[1]) + 1
        : parseInt(f10inicio[1]);
    const regraJumpGrupo = `add action=jump chain=cgnat jump-target=cgnat-${
      i + cgNatSeqStart
    } src-address=${f10inicio[0]}.${g2IpInicio}.${g3IpInicio}.0-${
      f10inicio[0]
    }.${g2IpFim}.${g3IpFim}.255`;
    regras.push(regraJumpGrupo);
    for (let j = 0; j < 8; j++) {
      const faixaPortas = `${portaInicial}-${portaInicial + 2000 - 1}`;
      const g3Ip = g3IpInicio + j > 255 ? g3IpInicio + j - 255 : g3IpInicio + j;
      const g2Ip = g3IpInicio + j > 255 ? g2IpInicio + 1 : g2IpInicio;
      const regraSrcNat = `add action=src-nat chain=cgnat-${
        i + cgNatSeqStart
      } src-address=${f10inicio[0]}.${g2Ip}.${g3Ip}.0-${
        f10inicio[0]
      }.${g2Ip}.${g3Ip}.255 to-addresses=${ipValido} to-ports=${faixaPortas}`;
      regras.push(regraSrcNat + " protocol=tcp");
      regras.push(regraSrcNat + " protocol=udp");
      portaInicial += 2000;
    }
  }

  return regras;
}

export function geracgnatPPP(
  ipValido: string,
  faixa10Inicio: string,
  cgNatSeqStart = 1
) {
  const regras: string[] = [];
  const f10inicio = faixa10Inicio.split(".");
  const g4IpFinal =
    parseInt(f10inicio[3]) + 31 > 255
      ? parseInt(f10inicio[3]) + 31 - 255
      : parseInt(f10inicio[3]) + 31;
  const g3IpFinal =
    parseInt(f10inicio[3]) + 31 > 255
      ? parseInt(f10inicio[2]) + 1
      : parseInt(f10inicio[2]);
  const regraJumpGeral = `add action=jump chain=srcnat jump-target=cgnat src-address=${faixa10Inicio}-${f10inicio[0]}.${f10inicio[1]}.${g3IpFinal}.${g4IpFinal}`;
  regras.push(regraJumpGeral);
  let portaInicial = 0;
  for (let i = 0; i < 4; i++) {
    const g4IpInicio =
      parseInt(f10inicio[3]) + i * 8 > 255
        ? parseInt(f10inicio[3]) + i * 8 - 255
        : parseInt(f10inicio[3]) + i * 8;
    const g3IpInicio =
      parseInt(f10inicio[3]) + i * 8 > 255
        ? parseInt(f10inicio[2]) + 1
        : parseInt(f10inicio[2]);
    const g4IpFim =
      parseInt(f10inicio[3]) + (i + 1) * 8 - 1 > 255
        ? parseInt(f10inicio[3]) + (i + 1) * 8 - 255 - 1
        : parseInt(f10inicio[3]) + (i + 1) * 8 - 1;
    const g3IpFim =
      parseInt(f10inicio[3]) + (i + 1) * 8 - 1 > 255
        ? parseInt(f10inicio[2]) + 1
        : parseInt(f10inicio[2]);
    const regraJumpGrupo = `add action=jump chain=cgnat jump-target=cgnat-${
      i + cgNatSeqStart
    } src-address=${f10inicio[0]}.${f10inicio[1]}.${g3IpInicio}.${g4IpInicio}-${
      f10inicio[0]
    }.${f10inicio[1]}.${g3IpFim}.${g4IpFim}`;
    regras.push(regraJumpGrupo);
    for (let j = 0; j < 8; j++) {
      const faixaPortas = `${portaInicial}-${portaInicial + 2000 - 1}`;
      const g4Ip = g4IpInicio + j > 255 ? g4IpInicio + j - 255 : g4IpInicio + j;
      const g3Ip = g4IpInicio + j > 255 ? g3IpInicio + 1 : g3IpInicio;
      const regraSrcNat = `add action=src-nat chain=cgnat-${
        i + cgNatSeqStart
      } src-address=${f10inicio[0]}.${f10inicio[1]}.${g3Ip}.${g4Ip}-${
        f10inicio[0]
      }.${
        f10inicio[1]
      }.${g3Ip}.${g4Ip} to-addresses=${ipValido} to-ports=${faixaPortas}`;
      regras.push(regraSrcNat + " protocol=tcp");
      regras.push(regraSrcNat + " protocol=udp");
      portaInicial += 2000;
    }
  }

  return regras;
}
