import { Button, Tooltip } from "@chakra-ui/react";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Pagar } from "../../generated/graphql";
import PagarBaixaModal from "./PagarBaixaModal";

export interface PagarBaixaButtonProps {
  pagar: Pagar;
  onSuccess?: () => void;
}

const PagarBaixaButton: React.FC<PagarBaixaButtonProps> = ({
  pagar,
  onSuccess,
}) => {
  const [isBaixarPagarOpen, setBaixarPagarOpen] = useState(false);
  return (
    <>
      <PagarBaixaModal
        pagar={pagar}
        isOpen={isBaixarPagarOpen}
        onClose={() => setBaixarPagarOpen(false)}
        onSuccess={onSuccess}
      />
      <Tooltip label="Baixar contas a pagar">
        <Button
          onClick={() => setBaixarPagarOpen(true)}
          colorScheme="green"
          type="button"
        >
          <FontAwesomeIcon icon={faBan} />
        </Button>
      </Tooltip>
    </>
  );
};

export default PagarBaixaButton;
