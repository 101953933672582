import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { InputHTMLAttributes } from "react";
import { formatNumber } from "../utils/formatNumber";

type InputNumberProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  error?: string;
  decimalPlaces?: number;
  onChange?: (value: number) => void;
};

const InputNumber: React.FC<InputNumberProps> = ({
  label,
  decimalPlaces,
  error,
  size: _,
  name,
  value,
  onChange,
  ...props
}) => {
  const decPlaces = typeof decimalPlaces === "number" ? decimalPlaces : 2;
  const handleNumberChange = (e) => {
    const lastIsMinus = e.target.value.substr(-1) === "-";
    const firstIsMinus = e.target.value.substr(0, 1) === "-";
    const value =
      "0".repeat(decPlaces + 1) + e.target.value.replace(/[^0-9]/g, "");
    const val =
      value.substring(0, value.length - decPlaces) +
      "." +
      value.substring(value.length - decPlaces);
    if ((lastIsMinus && !firstIsMinus) || (firstIsMinus && !lastIsMinus)) {
      onChange(parseFloat(val) * -1);
    } else {
      onChange(parseFloat(val));
    }
  };

  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Input
        name={name}
        value={formatNumber(value as string, decPlaces)}
        onChange={handleNumberChange}
        id={name}
        {...props}
        placeholder={props.placeholder}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputNumber;
