export function modulo11(numero: string, base: number = 9, r: boolean = false) {
  let numtotal11 = 0;
  let fator = 2;

  for (let i = numero.length - 1; i >= 0; i--) {
    numtotal11 += parseInt(numero.substr(i, 1)) * fator;
    fator = fator === base ? 2 : fator + 1;
  }
  if (r) {
    return numtotal11 % 11;
  }
  const resto = (numtotal11 * 10) % 11;
  if (resto === 10) {
    return 0;
  }
  return numero.length === 43 && (resto === 0 || resto > 9) ? 1 : resto;
}
