import { differenceInBusinessDays, parseISO } from "date-fns";

function IsVencido(data: Date) {
  if (data instanceof Date) {
    return differenceInBusinessDays(new Date(), data) > 1;
  } else if (typeof data === "string") {
    return differenceInBusinessDays(new Date(), parseISO(data)) > 1;
  }
  return true;
}

export default IsVencido;
