import {
  Button,
  Flex,
  Heading,
  Input,
  Box,
  Select,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import {
  faBox,
  faDiamond,
  faFax,
  faRoute,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerProps,
  Polyline,
  useLoadScript,
} from "@react-google-maps/api";
import { Form, FormikProps } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import {
  OltInput,
  SplitterInput,
  useCreateOltMutation,
  useOltQuery,
  useUpdateOltMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import stripObjectToMutation from "../../utils/stripObjectToMutation";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const center = {
  lat: -23.0594251,
  lng: -51.0335505,
};

const OltMarker: React.FC<MarkerProps> = (props) => (
  <Marker
    icon={{
      path: faServer.icon[4] as any,
      scale: 0.05,
      fillColor: "#59AFC6",
      strokeColor: "#59AFC6",
      strokeWeight: 3,
      labelOrigin: new google.maps.Point(100, 700),
    }}
    {...props}
  />
);

const SpliterMarker: React.FC<MarkerProps> = (props) => (
  <Marker
    icon={{
      path: faDiamond.icon[4] as any,
      scale: 0.05,
      fillColor: "#1A3CF1",
      strokeColor: "#1A3CF1",
      strokeWeight: 5,
      labelOrigin: new google.maps.Point(100, 700),
    }}
    {...props}
  />
);

type TPonto = {
  position: {
    lat: number;
    lng: number;
  };
  label: string;
  uplink?: number;
  uplinkType?: "olt" | "spliter";
};

const PontoEditor: React.FC<{
  ponto: TPonto;
  olts: TPonto[];
  splitters: TPonto[];
  disabled?: boolean;
  onChange: (novoPonto: TPonto) => void;
}> = ({ ponto, olts, splitters, onChange, disabled }) => {
  return (
    <Box py="2rem" px="1rem" my="1rem" backgroundColor="orange.100">
      <Heading>Editar ponto</Heading>
      <label>Nome do ponto</label>
      <Input
        type="text"
        bgColor="white"
        placeholder="Editar Nome da OLT/Spliter"
        disabled={disabled}
        value={ponto.label}
        onChange={(e) => {
          const nponto = { ...ponto };
          nponto.label = e.target.value;
          onChange(nponto);
        }}
      />
      <label>Uplink do ponto</label>
      <Select
        value={`${ponto.uplinkType === "olt" ? "o" : "s"}${ponto.uplink}`}
        bgColor="white"
        onChange={(e) => {
          const nponto = { ...ponto };
          nponto.uplink = parseInt(e.target.value.substring(1));
          nponto.uplinkType =
            e.target.value.substring(0, 1) == "o" ? "olt" : "spliter";
          onChange(nponto);
        }}
        placeholder="Uplink"
      >
        {olts.map((olt, i) => (
          <option value={`o${i}`} key={`s${i}`}>
            {olt.label}
          </option>
        ))}
        {splitters.map((spliter, i) => (
          <option value={`s${i}`} key={`s${i}`}>
            {spliter.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

const novaOlt: OltInput = {
  nome: "OLT",
  localizacao: { ...center },
  ip: "",
  qtd_pons: 1,
  splitters: [],
};

export const OltPlanner: React.FC<{}> = () => {
  useIsAuth();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAW6dbq5LPMeJ98D3GPARX1D7bbZnCvB90",
  });
  const navigate = useNavigate();

  const id = parseInt(useGetQueryParam("id") as string);

  const [{ fetching, data, error }] = useOltQuery({
    variables: {
      id,
    },
    requestPolicy: "cache-and-network",
  });
  console.log("dados carregados", data, id);
  const [, createMutation] = useCreateOltMutation();
  const [, updateMutation] = useUpdateOltMutation();

  const containerStyle = {
    width: "100%",
    height: "80vh",
  };

  const [editIndex, setEditIndex] = useState("");
  const [mapLoaded, setMapLoaded] = useState(false);

  const [updateMsg, setUpdateMsg] = useState("");

  const renderSplitterFilhos = (
    spliter: SplitterInput,
    onChange: (spliter: SplitterInput) => void,
    indexAnt: string
  ) => {
    if (!spliter.splitters) {
      return null;
    }
    return spliter.splitters.map((spl, i) => (
      <React.Fragment key={`${i}`}>
        <SpliterMarker
          position={spl.localizacao}
          key={`${i}`}
          onDragEnd={(e) => {
            spliter.splitters[i].localizacao.lat = e.latLng.lat();
            spliter.splitters[i].localizacao.lng = e.latLng.lng();

            onChange({
              ...spliter,
            });
          }}
          onClick={() => {
            setEditIndex(`${indexAnt}.splitters[${i}]`);
          }}
          label={spl.nome}
          draggable={true}
        />
        <Polyline
          key={`p${i}`}
          path={[{ ...spliter.localizacao }, spl.localizacao]}
        />
        {renderSplitterFilhos(
          spl,
          (split) => {
            spliter.splitters[i] = split;
            onChange({ ...spliter });
          },
          `${indexAnt}.splitters[${i}]`
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      <Heading size="xl">Cadastro de OLT</Heading>

      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message} </AlertDescription>
        </Alert>
      )}
      <FormikWrapper
        isLoading={fetching}
        headerText={`${!!data?.olt ? "Editar" : "Novo"} OLT`}
        updateMsg={updateMsg}
        initialData={data?.olt ? data.olt : novaOlt}
        onSubmit={async (values, { setErrors }) => {
          console.log("submit", values);
          if (data?.olt) {
            const { error, data } = await updateMutation({
              olt: stripObjectToMutation(values),
            });

            if (error) {
              setErrors({
                nome: error.message.replace("[GraphQL]", ""),
              });
              return;
            }

            if (data.updateOlt.errors?.length > 0) {
              setErrors(errorsToFormik(data.updateOlt.errors));
              return;
            }

            setUpdateMsg("Olt salva com sucesso!");
          } else {
            const { error, data } = await createMutation({
              olt: stripObjectToMutation(values),
            });

            if (error) {
              setErrors({
                nome: error.message.replace("[GraphQL]", ""),
              });
              return;
            }

            navigate(`/olts/${data.createOlt.olt.id}`);
          }
        }}
      >
        {(props: FormikProps<OltInput>) => {
          const extractSpliters = (splitter: SplitterInput) => {
            const res: SplitterInput[] = [];
            if (splitter.splitters?.length > 0) {
              for (const spl of splitter.splitters) {
                const ext = extractSpliters(spl);
                res.push(...ext);
              }
            } else {
              return [splitter];
            }
            return res;
          };
          const splittersExt: SplitterInput[] = [];
          for (const spl of props.values.splitters) {
            const resExt = extractSpliters(spl);
            splittersExt.push(...resExt);
          }

          const addSpliter = () => {
            const novoSpliter: SplitterInput = {
              nome: `Spliter ${Math.round(Math.random() * 1000)}`,
              localizacao: { ...center },
              saidas: 1,
            };
            props.setFieldValue("splitters", [
              ...props.values.splitters,
              novoSpliter,
            ]);
          };

          const addSubSpliter = () => {
            const novoSpliter: SplitterInput = {
              nome: `Spliter ${Math.round(Math.random() * 1000)}`,
              localizacao: { ...center },
              saidas: 1,
            };
            const nValues = eval(`props.values.${editIndex}.splitters`);
            console.log("nValues", nValues);
            if (nValues) {
              props.setFieldValue(`${editIndex}.splitters`, [
                ...nValues,
                novoSpliter,
              ]);
            } else {
              props.setFieldValue(`${editIndex}.splitters`, [novoSpliter]);
            }
          };

          return (
            <Form>
              <Grid gap={6} templateColumns="repeat(6, 1fr)">
                <GridItem colSpan={[6, 6]}>
                  <InputField name="nome" label="Nome" placeholder="Nome" />
                </GridItem>
                <GridItem colSpan={[6, 3]}>
                  <InputField name="ip" label="IP" placeholder="IP" />
                </GridItem>
                <GridItem colSpan={[6, 3]}>
                  <InputNumber
                    name="qtd_pons"
                    label="Qtd PONS"
                    placeholder="Qtd Pons"
                    decimalPlaces={0}
                  />
                </GridItem>
                <GridItem colSpan={[6]}>
                  <Button colorScheme="teal" mx="0.5rem" onClick={addSpliter}>
                    Adicionar Splitter Olt
                  </Button>
                </GridItem>

                {editIndex && (
                  <>
                    <GridItem colSpan={[6, 5]}>
                      <InputField
                        name={`${editIndex}.nome`}
                        label="Nome do Spliter"
                        placeholder="Nome do Spliter"
                      />
                    </GridItem>
                    <GridItem colSpan={[6, 1]}>
                      <Button
                        colorScheme="teal"
                        mx="0.5rem"
                        onClick={addSubSpliter}
                      >
                        Adicionar Sub-Splitter
                      </Button>
                    </GridItem>
                  </>
                )}

                {!isLoaded && <Spinner />}

                <GridItem colSpan={[6]}>
                  {isLoaded && loadError && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>
                        Mapa não pode ser carregado, tente atualizar a pagina!
                      </AlertDescription>
                    </Alert>
                  )}
                </GridItem>
                <GridItem colSpan={[6]}>
                  {isLoaded && !loadError && (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={14}
                      onLoad={() => {
                        setTimeout(() => setMapLoaded(true), 200);
                      }}
                    >
                      {mapLoaded && (
                        <>
                          <OltMarker
                            position={props.values.localizacao}
                            onDragEnd={(e) => {
                              const nolt = { ...props.values };
                              nolt.localizacao.lat = e.latLng.lat();
                              nolt.localizacao.lng = e.latLng.lng();
                              console.log(
                                "Drag change1",
                                e,
                                e.latLng.lat(),
                                e.latLng.lng()
                              );
                              props.setFieldValue(
                                "localizacao",
                                nolt.localizacao
                              );
                            }}
                            label={props.values.nome}
                            draggable={true}
                          />
                          {props.values.splitters.map((spliter, i) => (
                            <React.Fragment key={`${i}`}>
                              <SpliterMarker
                                position={spliter.localizacao}
                                key={`${i}`}
                                onDragEnd={(e) => {
                                  const nspliters = [...props.values.splitters];
                                  nspliters[i].localizacao.lat = e.latLng.lat();
                                  nspliters[i].localizacao.lng = e.latLng.lng();
                                  console.log(
                                    "Drag change1",
                                    e,
                                    e.latLng.lat(),
                                    e.latLng.lng()
                                  );
                                  props.setFieldValue("splitters", nspliters);
                                }}
                                onClick={() => {
                                  setEditIndex(`splitters[${i}]`);
                                }}
                                label={spliter.nome}
                                draggable={true}
                              />
                              <Polyline
                                key={`p${i}`}
                                path={[
                                  { ...spliter.localizacao },
                                  props.values.localizacao,
                                ]}
                              />
                              {renderSplitterFilhos(
                                spliter,
                                (spl) => {
                                  const nspliters = [...props.values.splitters];
                                  nspliters[i] = spl;
                                  props.setFieldValue("splitters", nspliters);
                                },
                                `splitters[${i}]`
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </GoogleMap>
                  )}
                </GridItem>
              </Grid>
              <Button
                type="submit"
                mt="1em"
                colorScheme="teal"
                isLoading={props.isSubmitting}
              >
                Salvar
              </Button>
            </Form>
          );
        }}
      </FormikWrapper>
    </>
  );
};
