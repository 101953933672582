import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Badge, Grid, GridItem } from "@chakra-ui/layout";
import { Form } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import BancoSelectorField from "../../components/BancoSelectorField";
import CheckField from "../../components/CheckField";
import ClienteSelectorField from "../../components/ClienteSelectorField";
import ContratoSelectorField from "../../components/ContratoSelectorField";
import DateSelector from "../../components/DateComponents/DateSelector";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import {
  CobrancaInput,
  useCreateCobrancaMutation,
  useCobrancaQuery,
  useUpdateCobrancaMutation,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";

const novoCobranca: CobrancaInput = {
  cliente: null,
  contrato: null,
  banco: null,
  valor: 0,
  desconto: 0,
  juros: 0,
  vencimento: null,
  instrucoes: "",
  referencia: "",
  isMensalidade: false,
};

const CobrancaForm: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();
  const id = useGetQueryParam("id") as string;
  const [{ fetching, data, error }] = useCobrancaQuery({
    variables: { id: parseInt(id) },
  });
  const [, createMutation] = useCreateCobrancaMutation();
  const [, updateMutation] = useUpdateCobrancaMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.cobranca ? "Editar" : "Novo"} Cobranca`}
      updateMsg={updateMsg}
      initialData={data?.cobranca ? data.cobranca : novoCobranca}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating cobranca", values);
        const valuesToSave = { ...values };
        valuesToSave.cliente = valuesToSave.cliente && valuesToSave.cliente.id;
        valuesToSave.contrato =
          valuesToSave.contrato && valuesToSave.contrato.id;
        valuesToSave.banco = valuesToSave.banco && valuesToSave.banco.id;
        delete valuesToSave.__typename;
        delete valuesToSave.data_cancelado;
        delete valuesToSave.data_pago;
        delete valuesToSave.valor_pago;
        delete valuesToSave.isPago;
        delete valuesToSave.isCanceled;
        delete valuesToSave.isPrinted;
        delete valuesToSave.cobrancaImportada;
        delete valuesToSave.data_emissao;
        delete valuesToSave.codClienteBol;
        delete valuesToSave.dvCodClienteBol;

        if (data?.cobranca) {
          /// é alteracao
          const { error, data } = await updateMutation({
            cobranca: valuesToSave,
          });

          if (error) {
            setErrors({
              referencia: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updateCobranca.errors?.length > 0) {
            setErrors(errorsToFormik(data.updateCobranca.errors));
          }

          setUpdateMsg("Cobranca salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({
            cobranca: valuesToSave,
          });

          if (error) {
            setErrors({
              referencia: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createCobranca.errors?.length > 0) {
            setErrors(errorsToFormik(data.createCobranca.errors));
            return;
          }

          navigate(`/cobrancas/${data.createCobranca.cobranca.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            {!!error && id !== "novo" && (
              <GridItem colSpan={6}>
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>{error.message}</AlertDescription>
                </Alert>
              </GridItem>
            )}
            <GridItem colSpan={[6, 3]}>
              <ClienteSelectorField name="cliente" label="Cliente" />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <ContratoSelectorField
                name="contrato"
                label="Contrato"
                clienteid={props.values.cliente && props.values.cliente.id}
                placeholder="Contrato"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <BancoSelectorField
                name="banco"
                label="Banco"
                placeholder="Banco"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                decimalPlaces={2}
                name="valor"
                label="Valor"
                placeholder="Valor"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                decimalPlaces={2}
                name="desconto"
                label="Desconto"
                placeholder="Desconto"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <InputNumber
                decimalPlaces={2}
                name="juros"
                label="Juros"
                placeholder="Juros"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <DateSelector
                name="vencimento"
                label="Vencimento"
                placeholder="Vencimento"
              />
            </GridItem>
            <GridItem colSpan={[6, 1]}>
              <CheckField name="isMensalidade" label="Mensalidade" />
            </GridItem>
            <GridItem colSpan={6}>
              <InputField
                name="instrucoes"
                label="Instrucoes"
                placeholder="Instrucoes"
                textarea
              />
            </GridItem>
            <GridItem colSpan={6}>
              <InputField
                name="referencia"
                label="Referencia"
                placeholder="Referencia"
                textarea
              />
            </GridItem>
            <GridItem colSpan={6}>
              {!props.values.isPrinted && (
                <Badge p="0.25em" colorScheme="cyan">
                  Não impresso no carne
                </Badge>
              )}
              {props.values.isCanceled && (
                <Badge p="0.25em" colorScheme="red">
                  Cancelado em {formatDate(props.values.data_cancelado)}
                </Badge>
              )}
              {props.values.isPago && (
                <Badge p="0.25em" colorScheme="green">
                  Pago R$ {formatNumber(props.values.valor_pago)} em{" "}
                  {formatDate(props.values.data_pago)}
                </Badge>
              )}
            </GridItem>
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default CobrancaForm;
