import React from "react";
import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { Flex, Heading } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Document, Page, PDFViewer } from "@react-pdf/renderer";
import { useState } from "react";
import PaginationButtons from "../../components/PaginationButtons";
import {
  useCobrancasToPrintQuery,
  useMarcarImpressoMutation,
} from "../../generated/graphql";
import { Boleto } from "../../pdfComponents/Boleto";
import { useGetIds } from "../../utils/useGetIds";
import getEnv from "../../utils/getEnv";
import LoadingWheel from "../../components/LoadingWheel";

export const nomeEmpresa =
  getEnv("REACT_APP_NOME_EMPRESA") || "Sem nome empresa";
export const razaoEmpresa =
  getEnv("REACT_APP_RAZAO_EMPRESA") || "Sem razão empresa";

const ImpressaoBoletos: React.FC<{}> = (props) => {
  const ids = useGetIds();
  const [page, setPage] = useState(1);
  const [{ data, error, fetching }] = useCobrancasToPrintQuery({
    variables: {
      page: page,
      ids,
      isPrinted: false,
      orderBy: [
        { orderField: "cliente.nome" },
        { orderField: "contrato.id" },
        { orderField: "cobranca.vencimento" },
      ],
    },
  });

  const [, marcarImpresso] = useMarcarImpressoMutation();
  const toast = useToast();

  const handleMarcarImpressoClick = async (e) => {
    e.preventDefault();
    try {
      const { error } = await marcarImpresso({ ids });
      if (error) {
        toast({
          title: "Erro ao confirmar impressão!",
          description: `Ocorreu o seguinte erro ao confirmar impressão: ${error.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Impressão confirmada com sucesso!",
          description: `Impressão confirmada!`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Erro ao confirmar impressão!",
        description: `Ocorreu o seguinte erro ao confirmar impressão: ${error.message}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Heading>Imprimir boletos</Heading>
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}
      <Flex flexDirection="row" justifyContent="flex-end">
        <Button
          colorScheme="teal"
          onClick={handleMarcarImpressoClick}
          type="button"
        >
          Confirmar a impressão
        </Button>
      </Flex>
      <PDFViewer height="100%" style={{ minHeight: "90vh", minWidth: "100%" }}>
        <Document title="boleto" author="Gabrimar Telecom">
          <Page
            size="A4"
            style={{ flexDirection: "column", fontFamily: "Source Sans Pro" }}
          >
            {data &&
              data.cobrancas &&
              data.cobrancas.data &&
              data.cobrancas.data.length > 0 &&
              data.cobrancas.data.map((cob, i) => {
                const [instrucoes1, instrucoes2, instrucoes3, instrucoes4] =
                  cob.instrucoes.split("\n");
                return (
                  <Boleto
                    key={`${i}`}
                    nomeEmpresa={nomeEmpresa}
                    razaoEmpresa={razaoEmpresa}
                    codigoBanco={cob.banco.codigo}
                    agenciaBanco={cob.banco.agencia}
                    carteiraBanco={cob.banco.carteira}
                    dvAgenciaBanco={cob.banco.agenciaDv}
                    codigoClienteBanco={
                      cob.codClienteBol || cob.banco.codCliente
                    }
                    dvCodigoClienteBanco={
                      cob.dvCodClienteBol || cob.banco.codClienteDv
                    }
                    numeroDocumento={`${
                      !!cob.cobrancaImportada ? cob.cobrancaImportada : cob.id
                    }`}
                    valorDocumento={cob.valor}
                    vencimento={cob.vencimento}
                    dataProcessamento={cob.data_emissao}
                    codigoModalidade={""} // Criar campo no banco
                    nomePagador={cob.cliente.nome}
                    cpfcnpjPagador={cob.cliente.isncNacional}
                    tipoPagador={cob.cliente.tipo}
                    enderecoPagador={`${cob.contrato.logradouro}, ${cob.contrato.numero}`}
                    cidadeUfPagador={`${cob.contrato.cidade}-${cob.contrato.uf}`}
                    cepPagador={`${cob.contrato.cep}`}
                    referente={cob.referencia}
                    instrucoes1={instrucoes1}
                    instrucoes2={instrucoes2}
                    instrucoes3={instrucoes3}
                    instrucoes4={instrucoes4}
                  />
                );
              })}
          </Page>
        </Document>
      </PDFViewer>

      <PaginationButtons
        totalPages={data?.cobrancas.totalPages}
        page={page}
        onPageChange={setPage}
      />
    </>
  );
};

export default ImpressaoBoletos;
