import React from "react";
import { Image } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";

export const SvgCodebar: React.FC<{ codigo: string }> = ({ codigo }) => {
  let imgNode = document.createElementNS("http://www.w3.org/1999/xhtml", "img");
  let width = "";
  try {
    if (codigo.length >= 44) {
      JsBarcode(imgNode, codigo.length % 2 === 0 ? codigo : "0" + codigo, {
        format: "itf",
        height: 70,
        displayValue: false,
      });
    } else {
      JsBarcode(imgNode, codigo, {
        displayValue: false,
        height: 35,
      });
      width = "100px";
    }
  } catch (error) {
    console.log("error", error);
  }
  return (
    <Image
      // @ts-ignore
      src={imgNode.src}
      style={{
        width,
      }}
    />
  );
};
