import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import {
  Serie,
  useDeleteSerieMutation,
  useSeriesQuery,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const Series: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching }, query] = useSeriesQuery({
    variables: { page: page, filter },
    requestPolicy: "cache-and-network",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeleteSerieMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (serie: Serie) => {
    await deleteData({ id: serie.id });
    setDeleteSuccessMsg(`Serie ${serie.codigo} foi removido com sucesso!`);
    query();
  };

  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Heading mb="1em">Series</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/series/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo serie
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Codigo</Th>
            <Th>Descricao</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.series.data.map((serie) => (
            <Tr key={serie.id}>
              <Td>{serie.codigo}</Td>
              <Td>{serie.descricao}</Td>
              <Td>
                <Link to={`/series/${serie.id}`}>
                  <Button colorScheme="orange" type="button">
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </Link>
                <ConfirmButton
                  isLoading={deleteLoading}
                  onClick={() => onDeleteConfirm(serie as Serie)}
                  colorScheme="red"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ConfirmButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <PaginationButtons
        totalPages={data?.series.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Series;
