import { Button } from "@chakra-ui/button";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Badge } from "@chakra-ui/react";
import { Form } from "formik";
import React, { useState } from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import FormikWrapper from "../../components/FormikWrapper";
import InputField from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import {
  PagarInput,
  useCreatePagarMutation,
  usePagarQuery,
  useUpdatePagarMutation,
  ClassificacaoDespesa,
} from "../../generated/graphql";
import errorsToFormik from "../../utils/errorsToFormik";
import formatDate from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { useGetQueryParam } from "../../utils/useGetQueryParam";
import { useIsAuth } from "../../utils/useIsAuth";
import SelectField from "../../components/SelectField";
import { useNavigate } from "react-router";
import CheckField from "../../components/CheckField";

const novoPagar: PagarInput = {
  numero: "",
  descricao: "",
  vencimento: false,
  valor: 0,
  fornecedor: "",
  classificacao_despesa: ClassificacaoDespesa.FixaAalt,
  isRecorrente: false,
};

const PagarForm: React.FC<{}> = () => {
  const navigate = useNavigate();
  useIsAuth();
  const [{ fetching, data }] = usePagarQuery({
    variables: { id: parseInt(useGetQueryParam("id") as string) },
  });
  const [, createMutation] = useCreatePagarMutation();
  const [, updateMutation] = useUpdatePagarMutation();

  const [updateMsg, setUpdateMsg] = useState("");

  return (
    <FormikWrapper
      isLoading={fetching}
      headerText={`${data?.pagar ? "Editar" : "Novo"} Pagar`}
      updateMsg={updateMsg}
      initialData={data?.pagar ? data.pagar : novoPagar}
      onSubmit={async (values, { setErrors }) => {
        console.log("creating pagar", values);
        if (data?.pagar) {
          /// é alteracao
          const { error, data } = await updateMutation({
            pagar: {
              id: values.id,
              numero: values.numero,
              vencimento: values.vencimento,
              descricao: values.descricao,
              fornecedor: values.fornecedor,
              valor: values.valor,
              classificacao_despesa: values.classificacao_despesa,
              isRecorrente: values.isRecorrente,
            },
          });

          if (error) {
            setErrors({
              valor: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.updatePagar.errors?.length > 0) {
            setErrors(errorsToFormik(data.updatePagar.errors));
            return;
          }

          setUpdateMsg("Pagar salvo com sucesso!");
        } else {
          // é inclusão
          const { error, data } = await createMutation({
            pagar: {
              numero: values.numero,
              vencimento: values.vencimento,
              descricao: values.descricao,
              fornecedor: values.fornecedor,
              valor: values.valor,
              classificacao_despesa: values.classificacao_despesa,
              isRecorrente: values.isRecorrente,
            },
          });

          if (error) {
            setErrors({
              valor: error.message.replace("[GraphQL]", ""),
            });
            return;
          }

          if (data.createPagar.errors?.length > 0) {
            setErrors(errorsToFormik(data.createPagar.errors));
            return;
          }

          navigate(`/pagar/${data.createPagar.pagar.id}`);
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid gap={6} templateColumns="repeat(6, 1fr)">
            <GridItem colSpan={[6, 1]}>
              <InputField
                name="numero"
                mask="999999999"
                label="Numero"
                placeholder="Numero"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <DateSelector
                name="vencimento"
                label="Vencimento"
                placeholder="Vencimento"
              />
            </GridItem>
            <GridItem colSpan={6}>
              <InputField
                name="descricao"
                label="Descricao"
                placeholder="Descricao"
              />
            </GridItem>
            <GridItem colSpan={6}>
              <InputField
                name="fornecedor"
                label="Fornecedor"
                placeholder="Fornecedor"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <InputNumber
                decimalPlaces={2}
                name="valor"
                label="Valor"
                placeholder="Valor"
              />
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <SelectField label="Classificação" name="classificacao_despesa">
                <option value={ClassificacaoDespesa.FixaAalt}>
                  Despesa Fixa (Agua, Luz, Telefone, Aluguel)
                </option>
                <option value={ClassificacaoDespesa.FixaLink}>
                  Despesa Fixa (Link, PTT)
                </option>
                <option value={ClassificacaoDespesa.Combustivel}>
                  Despesa com combustível
                </option>
                <option value={ClassificacaoDespesa.FolhaPagamento}>
                  Folha de Pagamento
                </option>
                <option value={ClassificacaoDespesa.Impostos}>Impostos</option>
                <option value={ClassificacaoDespesa.Infraestrutura}>
                  Compra de equipamento para infraestrutura
                </option>
                <option value={ClassificacaoDespesa.Manutencao}>
                  Despesas com manutenção de ativos
                </option>
                <option value={ClassificacaoDespesa.Suprimentos}>
                  Compra de suprimentos
                </option>
                <option value={ClassificacaoDespesa.Resultados}>
                  Saida de resultados
                </option>
              </SelectField>
            </GridItem>
            <GridItem colSpan={[6, 2]}>
              <CheckField name="isRecorrente" label="É recorrente" />
            </GridItem>
            {props.values.isPago && (
              <>
                <GridItem colSpan={[6, 2]}>
                  <Badge colorScheme="green">Baixado</Badge>
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <Badge colorScheme="green">
                    {formatNumber(props.values.valor_pago, 2, true)}
                  </Badge>
                </GridItem>
                <GridItem colSpan={[6, 2]}>
                  <Badge colorScheme="green">
                    {formatDate(props.values.data_pago)}
                  </Badge>
                </GridItem>
              </>
            )}
          </Grid>

          <Button
            type="submit"
            mt="1em"
            colorScheme="teal"
            isLoading={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </FormikWrapper>
  );
};

export default PagarForm;
