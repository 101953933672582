import { Heading } from "@chakra-ui/layout";
import { Button, Flex, Grid, GridItem, Link } from "@chakra-ui/react";
import { Document, Page, PDFViewer } from "@react-pdf/renderer";
import { endOfMonth, startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import * as React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import LoadingWheel from "../../components/LoadingWheel";
import {
  Cobranca,
  TipoConvenio,
  useBuscaConvenioQuery,
  useXlsxConvenioMutation,
} from "../../generated/graphql";
import RelacaoMoinho from "../../pdfComponents/RelacaoMoinho";
import { useState } from "react";
import SelectField from "../../components/SelectField";

const ConvenioMoinhoGlobo: React.FC<{}> = (props) => {
  const [data_inicial, setDataInicial] = React.useState(
    startOfMonth(new Date())
  );
  const [data_final, setDataFinal] = React.useState(endOfMonth(new Date()));

  const [, xlsxConvenio] = useXlsxConvenioMutation()
  const [strArquivo, setStrArquivo] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState("");
  const [tipo, setTipo] = useState(TipoConvenio.Moinhoglobo);

  const baixaXlsx = async () => {
    try {
      const { data, error } = await xlsxConvenio({
        data_inicial,
        data_final,
        tipoConvenio: tipo,
      })
      if (error) {
        console.log("Erro", error)
        return;
      }
      setStrArquivo(data.xlsxConvenio.conteudoArquivo)
      setNomeArquivo(data.xlsxConvenio.nomeArquivo)
    } catch (error) {
      console.log("Erro ao gerar arquivo", error)
    }
  }

  const [{ data, fetching }] = useBuscaConvenioQuery({
    variables: {
      tipoConvenio: tipo,
      data_inicial,
      data_final,
    },
  });
  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Heading>Relatório do convenio do Moinho Globo</Heading>

      <Formik
        initialValues={{
          data_inicial,
          data_final,
          tipo,
        }}
        onSubmit={(values) => {
          setDataInicial(values.data_inicial);
          setDataFinal(values.data_final);
          setTipo(values.tipo);
        }}
      >
        {() => (
          <Form>
            <Grid
              marginBottom="2rem"
              gap={[2, 6]}
              templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
            >
              <GridItem colSpan={2}>
                <DateSelector name="data_inicial" label="Inicio" />
              </GridItem>
              <GridItem colSpan={2}>
                <DateSelector name="data_final" label="Fim" />
              </GridItem>
              <GridItem colSpan={[6, 1]}>
                <SelectField
                  name="tipo"
                  label="Tipo Convenio"
                  placeholder="Selecione um convenio"
                >
                  <option value={TipoConvenio.Moinhoglobo}>Moinho Globo</option>
                  <option value={TipoConvenio.N1Alimentos}>N1 Alimentos</option>
                </SelectField>
              </GridItem>
              <GridItem>
                <Flex
                  minH="100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="submit" colorScheme="blue" mx="0.5rem">
                    Confirmar
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>
      {data && data.buscaConvenio && (
        <Flex direction="row" marginBottom="2rem" justifyContent="flex-end">
          <Link
            href={`/baixarCobrancas/${data.buscaConvenio
              .map((cob) => cob.id)
              .join(",")}`}
          >
            <Button type="button" colorScheme="green">
              Baixar cobranças
            </Button>
          </Link>
          <Button onClick={() => baixaXlsx()} type="button" colorScheme="green">
            Baixar Xlsx
          </Button>
        </Flex>
      )}
      {strArquivo.length > 10 && (
        <Link
          download={nomeArquivo || "remessa.txt"}
          href={`data:application/octet-stream;name=${nomeArquivo || "remessa.txt"
            };base64,${strArquivo}`}
        >
          Download Arquivo
        </Link>
      )}

      {data && data.buscaConvenio && (
        <PDFViewer height="100%">
          <Document title="relacao_convenio" author="Gabrimar Telecom">
            <Page size="A4" style={{ flexDirection: "column" }}>
              <RelacaoMoinho cobrancas={data.buscaConvenio as Cobranca[]} />
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default ConvenioMoinhoGlobo;
