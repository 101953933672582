import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as React from "react";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  useDeleteVelocidadeMutation,
  useVelocidadesQuery,
  Velocidade,
} from "../../generated/graphql";
import { useIsAuth } from "../../utils/useIsAuth";

const Velocidades: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching }, query] = useVelocidadesQuery({
    variables: { page: page, filter },
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteData] =
    useDeleteVelocidadeMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (velocidade: Velocidade) => {
    await deleteData({ id: velocidade.id });
    setDeleteSuccessMsg(
      `Velocidade ${velocidade.nome} foi removido com sucesso!`
    );
    query();
  };
  if (fetching) {
    return <LoadingWheel />;
  }

  const headers: TableHeaders<Velocidade>[] = [
    {
      label: "Nome",
      render: (velocidade) => `${velocidade.nome}`,
      wrapped: true,
    },
    {
      label: "Download",
      render: (velocidade) => `${velocidade.download / 1000}Mbps`,
      wrapped: true,
    },
    {
      label: "Upload",
      render: (velocidade) => `${velocidade.upload / 1000}Mbps`,
      wrapped: true,
    },
    {
      label: " ",
      wrapped: true,
      render: (velocidade) => (
        <>
          <Link to={`/velocidades/${velocidade.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(velocidade as Velocidade)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Heading mb="1em">Velocidades</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/velocidades/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Nova velocidade
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable headers={headers} data={data?.velocidades.data} />

      <PaginationButtons
        totalPages={data?.velocidades.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Velocidades;
