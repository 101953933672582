import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Heading } from "@chakra-ui/layout";
import { Document, Page, PDFViewer } from "@react-pdf/renderer";
import React, { useState } from "react";
import { useParams } from "react-router";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import { useCobrancasByContratoQuery } from "../../generated/graphql";
import { Boleto } from "../../pdfComponents/Boleto";
import getEnv from "../../utils/getEnv";

export const nomeEmpresa =
  getEnv("REACT_APP_NOME_EMPRESA") || "Sem nome empresa";
export const razaoEmpresa =
  getEnv("REACT_APP_RAZAO_EMPRESA") || "Sem razão empresa";

const CarneContrato: React.FC<{}> = () => {
  const params = useParams();
  const [page, setPage] = useState(1);
  const [{ data, error, fetching }] = useCobrancasByContratoQuery({
    variables: {
      page: page,
      contrato: parseInt(params.contrato),
      isPago: false,
      orderBy: [
        { orderField: "cliente.nome" },
        { orderField: "contrato.id" },
        { orderField: "cobranca.vencimento" },
      ],
    },
  });

  if (fetching) {
    return <LoadingWheel />;
  }
  console.log("data", data);

  return (
    <>
      <Heading>Imprimir boletos</Heading>
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}
      <PDFViewer height="100%" style={{ minHeight: "90vh", minWidth: "100%" }}>
        <Document title="boleto" author="Gabrimar Telecom">
          <Page
            size="A4"
            style={{ flexDirection: "column", fontFamily: "Source Sans Pro" }}
          >
            {data &&
              data.cobrancas &&
              data.cobrancas.data &&
              data.cobrancas.data.length > 0 &&
              data.cobrancas.data.map((cob, i) => {
                console.log(
                  "banco da cobranca",
                  cob.cobrancaImportada,
                  cob.banco
                );
                const [instrucoes1, instrucoes2, instrucoes3, instrucoes4] =
                  cob.instrucoes.split("\n");
                return (
                  <Boleto
                    key={`${i}`}
                    nomeEmpresa={nomeEmpresa}
                    razaoEmpresa={razaoEmpresa}
                    codigoBanco={cob.banco.codigo}
                    agenciaBanco={cob.banco.agencia}
                    carteiraBanco={cob.banco.carteira}
                    dvAgenciaBanco={cob.banco.agenciaDv}
                    codigoClienteBanco={
                      cob.codClienteBol || cob.banco.codCliente
                    }
                    dvCodigoClienteBanco={
                      cob.dvCodClienteBol || cob.banco.codClienteDv
                    }
                    numeroDocumento={`${
                      !!cob.cobrancaImportada ? cob.cobrancaImportada : cob.id
                    }`}
                    valorDocumento={cob.valor}
                    vencimento={cob.vencimento}
                    dataProcessamento={cob.data_emissao}
                    codigoModalidade={""} // Criar campo no banco
                    nomePagador={cob.cliente.nome}
                    cpfcnpjPagador={cob.cliente.isncNacional}
                    tipoPagador={cob.cliente.tipo}
                    enderecoPagador={`${cob.contrato.logradouro}, ${cob.contrato.numero}`}
                    cidadeUfPagador={`${cob.contrato.cidade}-${cob.contrato.uf}`}
                    cepPagador={`${cob.contrato.cep}`}
                    referente={cob.referencia}
                    instrucoes1={instrucoes1}
                    instrucoes2={instrucoes2}
                    instrucoes3={instrucoes3}
                    instrucoes4={instrucoes4}
                  />
                );
              })}
          </Page>
        </Document>
      </PDFViewer>

      <PaginationButtons
        totalPages={data?.cobrancas.totalPages}
        page={page}
        onPageChange={setPage}
      />
    </>
  );
};

export default CarneContrato;
