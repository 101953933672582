import { Button, Grid, GridItem, Heading, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import DateSelector from "../../components/DateComponents/DateSelector";
import InputNumber from "../../components/InputNumber";
import ProdutoSelectorField from "../../components/ProdutoSelectorField";
import { useGerarNotasMutation } from "../../generated/graphql";
import { useIsAdmin } from "../../utils/useIsAdmin";
import { useIsAuth } from "../../utils/useIsAuth";

const GerarNotas: React.FC<{}> = () => {
  useIsAuth();
  useIsAdmin();
  const toast = useToast();

  const [, gerarNotas] = useGerarNotasMutation();

  const valoresIniciais = {
    emissao: new Date(),
    produto: null,
    qtd: 0,
  };

  return (
    <>
      <Heading>Gerar Notas</Heading>
      <Formik
        initialValues={valoresIniciais}
        onSubmit={async (values, { setErrors }) => {
          console.log("values", values);

          if (!values.emissao || !(values.emissao instanceof Date)) {
            setErrors({
              emissao: `A Emissão deve ser preenchido!`,
            });
            return;
          }
          if (!values.produto) {
            setErrors({
              produto: `O Produto deve ser preenchido!`,
            });
            return;
          }
          if (values.qtd < 1) {
            setErrors({
              qtd: `A quantidade deve ser preenchida!`,
            });
            return;
          }

          const { error, data } = await gerarNotas({
            emissao: values.emissao,
            produtoId: values.produto.id,
            qtd: values.qtd,
          });

          if (error) {
            toast({
              title: "Erro ao gerar notas",
              description: `Ocorreu o seguinte erro ao gerar notas: ${error.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          toast({
            title: "Notas geradas com sucesso",
            description: `${data.gerarNotas} notas geradas com sucesso!`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }}
      >
        {() => (
          <Form>
            <Grid gap={6} templateColumns="repeat(6, 1fr)">
              <GridItem colSpan={[6, 2]}>
                <DateSelector name="emissao" label="Emissão" />
              </GridItem>
              <GridItem colSpan={[6, 2]}>
                <ProdutoSelectorField
                  placeholder="Informe o produto"
                  name="produto"
                  label="Produto"
                />
              </GridItem>
              <GridItem colSpan={[6, 2]}>
                <InputNumber
                  decimalPlaces={0}
                  name="qtd"
                  label="Quantidade"
                  placeholder="Quantidade"
                />
              </GridItem>
            </Grid>
            <Button type="submit" mt="0.5rem" colorScheme="blue" mx="0.5rem">
              Confirmar
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GerarNotas;
