import { useMeQuery } from "../generated/graphql";
import { useEffect } from "react";
import { useNavigate } from "react-router";

export const useIsAuth = () => {
  const [{ data, fetching }] = useMeQuery();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("debug login", fetching, data);
    if (!fetching && !data?.me) {
      console.log("redirecting to login");
      if (/.*login.*/.test(window.location.pathname)) {
        navigate("/login");
      } else {
        navigate("/login?next=" + window.location.pathname);
      }
    }
  }, [fetching, data, navigate]);
};
