import React from "react";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Select } from "@chakra-ui/select";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import { useEstacaosQuery } from "../generated/graphql";

export type EstacaoSelectorFieldProps =
  InputHTMLAttributes<HTMLSelectElement> & {
    name: string;
    label: string;
  };

const EstacaoSelectorField: React.FC<EstacaoSelectorFieldProps> = ({
  label,
  size: _,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props);
  const [{ fetching, data }] = useEstacaosQuery({
    variables: {
      page: 1,
    },
  });

  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        name={field.name}
        value={field.value && field.value.id}
        onBlur={field.onBlur}
        onChange={(e) => {
          setValue(
            data.estacaos.data.find(
              (estacoes) => estacoes.id === parseInt(e.target.value),
              false
            )
          );
        }}
        {...props}
        id={field.name}
        placeholder={props.placeholder}
      >
        {!fetching &&
          !!data &&
          data.estacaos.data.map((estacao) => (
            <option key={estacao.id} value={estacao.id}>
              {estacao.nome}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};

export default EstacaoSelectorField;
