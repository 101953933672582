import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import { CloseButton } from "@chakra-ui/close-button";
import { Flex, Heading } from "@chakra-ui/layout";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import ConfirmButton from "../../components/ConfirmButton";
import Filter from "../../components/Filter";
import LoadingWheel from "../../components/LoadingWheel";
import PaginationButtons from "../../components/PaginationButtons";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import {
  Plano,
  useDeletePlanoMutation,
  usePlanosQuery,
} from "../../generated/graphql";
import { formatNumber } from "../../utils/formatNumber";
import { useIsAuth } from "../../utils/useIsAuth";

const Planos: React.FC<{}> = () => {
  useIsAuth();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [{ data, fetching, error }, query] = usePlanosQuery({
    variables: { page: page, filter },
    requestPolicy: "network-only",
  });
  const [{ fetching: deleteLoading }, deleteData] = useDeletePlanoMutation();
  const [deleteSuccessMsg, setDeleteSuccessMsg] = React.useState("");

  const onDeleteConfirm = async (plano: Plano) => {
    await deleteData({ id: plano.id });
    setDeleteSuccessMsg(`Plano ${plano.nome} foi removido com sucesso!`);
    query();
  };

  const headers: TableHeaders<Plano>[] = [
    {
      label: "Nome",
      render: (plano) => plano.nome,
      wrapped: true,
    },
    {
      label: "Velocidade",
      render: (plano) => plano.velocidade,
      wrapped: true,
    },
    {
      label: "Mensalidade",
      render: (plano) => formatNumber(plano.mensalidade, 2, true),
      wrapped: true,
    },
    {
      label: "Dia Vencimento",
      render: (plano) => `${plano.diaVencimento}`,
      wrapped: true,
    },
    {
      label: "Dia Uso",
      render: (plano) => `${plano.diaUso}`,
      wrapped: true,
    },
    {
      label: "Status",
      render: (plano) => (plano.status ? "Ativo" : "Destivado"),
      wrapped: true,
    },
    {
      label: " ",
      render: (plano) => (
        <>
          <Link to={`/planos/${plano.id}`}>
            <Button colorScheme="orange" type="button">
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Link>
          <ConfirmButton
            isLoading={deleteLoading}
            onClick={() => onDeleteConfirm(plano as Plano)}
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faTrash} />
          </ConfirmButton>
        </>
      ),
      wrapped: true,
    },
  ];

  if (fetching) {
    return <LoadingWheel />;
  }

  return (
    <>
      <Heading mb="1em">Planos</Heading>

      <Flex direction="row" mb="1rem">
        <Link to="/planos/novo">
          <Button colorScheme="blue" type="button">
            <FontAwesomeIcon icon={faPlus} /> Novo plano
          </Button>
        </Link>
      </Flex>

      {!!deleteSuccessMsg && (
        <Alert status="success">
          <AlertIcon />
          <AlertDescription>{deleteSuccessMsg}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setDeleteSuccessMsg("")}
          />
        </Alert>
      )}

      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      )}

      <Filter value={filter} onChange={setFilter} />

      <ResponsiveTable data={data?.planos.data} headers={headers} />

      <PaginationButtons
        totalPages={data?.planos.totalPages}
        page={page}
        onPageChange={(pg) => setPage(pg)}
      />
    </>
  );
};

export default Planos;
