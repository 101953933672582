import { Cobranca } from "../generated/graphql";

export function statusCobranca(cobranca: Cobranca): string {
  if (cobranca.isCanceled) {
    return "Cancelado";
  }
  if (cobranca.isPago) {
    return "Pago";
  }
  return "Pendente";
}
